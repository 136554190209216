import { Outlet } from 'react-router-dom'
import Topbar from '../components/partials/topbar1/Topbar'
import useAuth from '../hooks/useAuth'

function LayoutDashboard() {

    const {Auth} = useAuth()

    return (
        <div id='dashboard-main' className='h-full'>

            {/* Topbar */}
            <Topbar/>

            <div className="container max-w-[1336px] h-full mx-auto" id='dashboard-content'>
                <Outlet/>
            </div>

        </div>
    )
}

export default LayoutDashboard
