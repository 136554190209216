import Modal from './Modal'
import CancelButton from '../buttons/outlined/ButtonSuccess'
import SaveButton from '../buttons/ButtonSuccess'
import UsernameInput from '../inputs/input2/Input'
import PhoneInput from '../inputs/input2/Input'
import { useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth'
import usePatch from '../../hooks/usePatch'
import useAlert from '../../hooks/useAlert'
import AlertSeverity from '../alerts/AlertSeverity'
const UpdateUserUsernamePhoneModal = ({
    visible,
    onHide,
}) => {
    const {Auth, login} = useAuth()
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
    const [username, setUsername] = useState(Auth().user.username)
    const [phone, setPhone] = useState(Auth().user.phone)
    const {response, error, isLoading, patch} = usePatch()
    const {message: alertMessage, visible: alertVisible, type: alertType, show: alertShow, hide: alertHide} = useAlert()

    useEffect(() =>{

        if ( username && phone ) setIsSaveButtonDisabled(false)
        else setIsSaveButtonDisabled(true)

    }, [username, phone])

    useEffect(() => {

        if ( error )
        {
            alertShow('danger', error.message)
        }

        if ( response )
        {
            // console.log(response.data)
            login(response.data)
            alertShow('success', "Your information has been updated.")
        }

    }, [response, error])

    const handleSave = () =>
    {
        patch(`/dashboard/users/${Auth().user.id}/edit`, {
            username,
            phone,
        })
    }

    return (
        <Modal visible={visible} onHide={onHide} classes={''} contentClasses={'!block'} titleClasses={'!font-bold'} title={'Username & Phone number'}>

            <div className="mt-10 w-full">

                <div className="mb-10">

                    <div className="mb-2">
                        <UsernameInput classes={'!py-2 !px-0'} hint={`This is your username, you may use it later on to let others view your profile.`} value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>

                    <div className="mb-2">
                        <PhoneInput classes={'!py-2 !px-0'} hint={`This is your phone number, you may need it for account verifications.`} value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>

                    <div className="">
                        <AlertSeverity message={alertMessage} visible={alertVisible} type={alertType} onClickHide={alertHide} />
                    </div>

                </div>

                <div className="flex justify-end">
                    <div className="">
                        <CancelButton text={'Cancel'} onClick={onHide} />
                    </div>
                    <div className="w-4"></div>
                    <div className="">
                        <SaveButton text={'Save'} onClick={handleSave} disabled={ (isLoading || isSaveButtonDisabled) } />
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default UpdateUserUsernamePhoneModal