const AlertSeverity = ({ type = "danger", message, visible, onClickHide }) => {

    if (type == 'danger') {
        return (
            <div className={`bg-[#FDEDED] rounded-[4px] py-[10px] px-4 text-[#6A2F2E] text-left shadow-sm ${visible ? 'flex' : 'hidden'}`}>
                <div className="mr-3 opacity-[.9] text-[22px] flex text-[#d32f2f]">
                    <svg className="w-[1em] h-[1em] fill-current inline-block flex-shrink-0 select-none" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ErrorOutlineIcon"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>
                </div>
                <div className="w-full">
                    <div className="text-[1rem] leading-6 tracking-[0.00938em] mb-[0.35em] font-medium">Error</div>
                    <p className='text-sm'>
                        {message}
                    </p>
                </div>
                <div className="">
                    <button type='button' onClick={onClickHide} className='inline-flex items-center justify-center p-[5px] text-[1.125rem] rounded-full hover:bg-[#f5dfdf]'>
                        <svg className="w-[1em] h-[1em] fill-current inline-block flex-shrink-0 select-none" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
                    </button>
                </div>
            </div>
        )
    }
    else if (type == 'success') {
        return (
            <div className={`bg-[#EDF7ED] rounded-[4px] py-[10px] px-4 text-[#1E4620] text-left shadow-sm ${visible ? 'flex' : 'hidden'}`}>
                <div className="mr-3 opacity-[.9] text-[22px] flex text-[#2e7d32]">
                    <svg className="w-[1em] h-[1em] fill-current inline-block flex-shrink-0 select-none" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SuccessOutlinedIcon"><path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path></svg>
                </div>
                <div className="w-full">
                    <div className="text-[1rem] leading-6 tracking-[0.00938em] mb-[0.35em] font-medium">Success</div>
                    <p className='text-sm'>
                        {message}
                    </p>
                </div>
                <div className="">
                    <button type='button' onClick={onClickHide} className='inline-flex items-center justify-center p-[5px] text-[1.125rem] rounded-full hover:bg-[#E4EDE4]'>
                        <svg className="w-[1em] h-[1em] fill-current inline-block flex-shrink-0 select-none" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
                    </button>
                </div>
            </div>
        )
    }
    else if (type == 'warning') {
        return (
            <div className={`bg-[#fff4e5] rounded-[4px] py-[10px] px-4 text-[#663c00] text-left shadow-sm ${visible ? 'flex' : 'hidden'}`}>
                <div className="mr-3 opacity-[.9] text-[22px] flex text-[#ed6c02]">
                    <svg className="w-[1em] h-[1em] fill-current inline-block flex-shrink-0 select-none" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ReportProblemOutlinedIcon"><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path></svg>
                </div>
                <div className="w-full">
                    <div className="text-[1rem] leading-6 tracking-[0.00938em] mb-[0.35em] font-medium">Warning</div>
                    <p className='text-sm'>
                        {message}
                    </p>
                </div>
                <div className="">
                    <button type='button' onClick={onClickHide} className='inline-flex items-center justify-center p-[5px] text-[1.125rem] rounded-full hover:bg-[#f7ebdb]'>
                        <svg className="w-[1em] h-[1em] fill-current inline-block flex-shrink-0 select-none" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
                    </button>
                </div>
            </div>
        )
    }
    else if (type == 'info') {
        return (
            <div className={`bg-[#e5f6fd] rounded-[4px] py-[10px] px-4 text-[#014361] text-left shadow-sm ${visible ? 'flex' : 'hidden'}`}>
                <div className="mr-3 opacity-[.9] text-[22px] flex text-[#0288d1]">
                    <svg className="w-[1em] h-[1em] fill-current inline-block flex-shrink-0 select-none" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InfoOutlinedIcon"><path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path></svg>
                </div>
                <div className="w-full">
                    <div className="text-[1rem] leading-6 tracking-[0.00938em] mb-[0.35em] font-medium">Warning</div>
                    <p className='text-sm'>
                        {message}
                    </p>
                </div>
                <div className="">
                    <button type='button' onClick={onClickHide} className='inline-flex items-center justify-center p-[5px] text-[1.125rem] rounded-full hover:bg-[#ddedf3]'>
                        <svg className="w-[1em] h-[1em] fill-current inline-block flex-shrink-0 select-none" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
                    </button>
                </div>
            </div>
        )
    }

}

export default AlertSeverity
