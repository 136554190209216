import React, { useEffect, useState } from 'react'

const StoryContent = ({ story }) => {

    // const [html, setHtml] = useState(null)

    useEffect(() => {

        if (!story) return

    }, [story])

    return (
        <div>
            {
                story && (
                    <div>

                        {
                            story?.content?.blocks.map((block, blockIndex) => {

                                if (block.type == 'header') {

                                    if (block.data.level == 2) {
                                        return (
                                            <div key={blockIndex}>
                                                <h2 className="mt-[1.72em] leading-[24px] font-bold text-primary text-[20px]">{block.data.text}</h2>
                                            </div>
                                        )
                                    }
                                    else if (block.data.level == 3) {
                                        return (
                                            <div key={blockIndex}>
                                                <h3 className="mt-[1.72em] leading-[24px] font-bold text-primary text-[18px]">{block.data.text}</h3>
                                            </div>
                                        )
                                    }
                                    else if (block.data.level == 4) {
                                        return (
                                            <div key={blockIndex}>
                                                <h4 className="mt-[1.72em] leading-[24px] font-bold text-primary text-[16px]">{block.data.text}</h4>
                                            </div>
                                        )
                                    }

                                }
                                else if (block.type == 'paragraph') {

                                    return (
                                        <div key={blockIndex}>
                                            <p className="mt-[0.94em] leading-[32px] text-primary text-[20px]">{block.data.text}</p>
                                        </div>
                                    )

                                }
                                else if (block.type == 'image') {

                                    return (
                                        <div key={blockIndex}>
                                            
                                            <figure className='mt-[56px]'>
                                                <picture>
                                                    <source media="(max-width: 700px)" srcSet={block.data.file.url} />
                                                    <img src={block.data.file.url} alt={block?.data?.caption} title={block?.data?.caption} width={700} height={361} />
                                                </picture>
                                            </figure>

                                        </div>
                                    )

                                }
                                else if (block.type == 'list') {

                                    if ( block.data.style == 'unordered' )
                                    {
                                        return (
                                            <div className="" key={blockIndex}>
                                                <ul key={blockIndex}>
                                                {
                                                    block.data.items.map((item, itemIndex) => {

                                                        return (
                                                            <li key={itemIndex} className="leading-[32px] mt-[1.14em] text-[20px] pl-0 ml-[30px] mb-[-0.46em] list-disc">
                                                                {item.content}
                                                            </li>
                                                        )
            
                                                    })
                                                }
                                            </ul>
                                            </div>
                                        )
                                    }

                                }

                            })
                        }

                    </div>
                )
            }
        </div>
    )
}

export default StoryContent
