import { createContext, useState } from "react";

const AuthContext = createContext({})
const ENCRYPTION_PASSWORD = '93623dd5d6529ada40395df9a6b94c09'

export const AuthProvider = ({children}) =>
{
    const USER_KEY = '3j7GuHY+khRuwIx8Yk+WbTZ9PbQcFz249r2MG6KGRHPIKe2+Xk2/RhgK0AcQIR8J'

    const login = (user) =>
    {
        const encryptedUser = encryptString(JSON.stringify(user))
        
        localStorage.setItem(USER_KEY, encryptedUser)
    }

    const logout = () =>
    {
        localStorage.removeItem(USER_KEY)
    }

    const Auth = () =>
    {
        if ( !isLoggedIn() ) return {}

        const user = JSON.parse(decryptString(localStorage.getItem(USER_KEY)))
        
        return {user}
    }

    const isLoggedIn = () =>
    {
        const user = localStorage.getItem(USER_KEY)

        if ( !user ) return false

        return true
    }

    return (
        <AuthContext.Provider value={{ login, logout, Auth, isLoggedIn }}>
            {children}
        </AuthContext.Provider>
    )
}

export function encryptString(str) {
    var CryptoJS = require("crypto-js")

    return CryptoJS.AES.encrypt(str, ENCRYPTION_PASSWORD).toString()
}

export function decryptString(str)
{
    var CryptoJS = require("crypto-js")

    var bytes  = CryptoJS.AES.decrypt(str, ENCRYPTION_PASSWORD);
    return bytes.toString(CryptoJS.enc.Utf8);
}


export default AuthContext