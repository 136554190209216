
import Overlay from "../overlays/overlay2/Overlay"

const Modal = ({
    children,
    visible = false,
    onHide,
    classes = '',
    contentClasses = '',
    titleClasses = '',
    title = '',
}) => {
    return (
        <>
            {
                visible && (
                    <div>

                        <Overlay classes={`z-[900] bg-[rgba(0,0,0,.25)] flex items-center justify-center ${classes}`}>

                            <div className={`max-w-[552px] w-full relative overflow-hidden pt-8 pb-[40px] pl-[40px] pr-[40px] flex bg-white rounded-md shadow-[rgba(0, 0,0,0.15)_0px_2px_10px] ${contentClasses}`}>

                                <button className="absolute top-6 right-6 text-[#0000008a] inline-flex hover:text-[#000000ad] group" onClick={onHide}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="group-hover:fill-[#000000d6]"><path d="M5 5l7 7m7 7l-7-7m0 0l7-7m-7 7l-7 7" stroke="currentColor" strokeLinecap="round"></path></svg>
                                </button>

                                <div className="">

                                    <h3 className={`text-xl mb-3 font-medium ${titleClasses}`}>{title}</h3>

                                    <div className="">
                                        {children}
                                    </div>

                                </div>

                            </div>

                        </Overlay>

                    </div>
                )
            }

        </>
    )
}

export default Modal
