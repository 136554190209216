import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { DashboardRoutes } from '../routes/RouteNames'

const RequireAuth = () => {

    const {isLoggedIn} = useAuth()

    return (
        <>
            {
                isLoggedIn() ? <Outlet/> : <Navigate to={DashboardRoutes().LOGIN.nav} />
            }
        </>
    )
}

export default RequireAuth
