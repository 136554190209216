import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { changeDocumentTitle } from "../../helpers/helpers"
import useAlert from "../../hooks/useAlert"
import useFetch from "../../hooks/useFetch"
import usePatch from "../../hooks/usePatch"
import Sidebar from "./sidebar/Sidebar"
import TagsForm from "./TagsForm"
const TagUpdate = () => {

    // change page title
    const PAGE_TITLE = changeDocumentTitle('Update tags')
    const { response, isLoading, error, patch } = usePatch()
    const {response: fetchResponse,fetch} = useFetch()
    const { type: alertType, visible: alertVisible, message: alertMessage, show: showAlert, hide: hideAlert } = useAlert()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [parentId, setParentId] = useState('')
    const {id} = useParams()
    const [tag, setTag] = useState({})

    useEffect(() => {

        if (error) {

            showAlert('danger', error.message)
        }

        if (response) {

            showAlert('success', 'Tag has been updated.')
            // resetForm()
        }

    }, [response, error])

    useEffect(() =>
    {
        
        if ( fetchResponse )
        {
            fillForm(fetchResponse.data)
        }

    }, [fetchResponse])

    useEffect(() =>
    {
        // get tag data
        fetch(`/dashboard/tags/${id}`)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()

        hideAlert()

        // check inputs
        if (!name) {
            showAlert('danger', 'Please enter a tag name.')
            return
        }

        patch(`/dashboard/tags/${id}`, {
            name,
            description,
            parent_id: parentId,
        })


    }

    const resetForm = () => {
        setName('')
        setDescription('')
    }

    const fillForm = (data) => {

        setName(data.name)
        setDescription( data.description ?? '' )
        setParentId( data.parent_id ?? '' )
    }

    return (
        <div>
            <div className="h-full">

                <div className="flex justify-evenly flex-row h-full">

                    <main className="md:min-w-[728px] md:max-w-[728px]">

                        <div className="mt-[40px]">

                            <h1 className="text-[42px] mb-11">{PAGE_TITLE}</h1>

                            <div className="">

                                <TagsForm 
                                    alertType={alertType}
                                    alertVisible={alertVisible}
                                    alertMessage={alertMessage}
                                    hideAlert={hideAlert}
                                    handleSubmit={handleSubmit}
                                    name={name}
                                    setName={setName}
                                    description={description}
                                    setDescription={setDescription}
                                    parentId={parentId}
                                    setParentId={setParentId}
                                    isLoading={isLoading}
                                />

                            </div>

                        </div>

                    </main>

                    <div className="md:block hidden border-l border-[#f2f2f2] pl-8 max-w-[368px]">
                        <div className="sticky top-0 h-[100vh] max-h-[100vh]">
                            <Sidebar />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default TagUpdate
