import React from 'react'
import { Link } from 'react-router-dom'

const Links = () => {

    return (
        <div>

            <ul className="list-none flex flex-wrap gap-x-2">
                <li className="">
                    <Link to='' className='text-[11px] text-secondary'>
                        Help
                    </Link>
                </li>
                <li className="">
                    <Link to='' className='text-[11px] text-secondary'>
                        Status
                    </Link>
                </li>
                <li className="">
                    <Link to='' className='text-[11px] text-secondary'>
                        About
                    </Link>
                </li>
                <li className="">
                    <Link to='' className='text-[11px] text-secondary'>
                        Privacy
                    </Link>
                </li>
                <li className="">
                    <Link to='' className='text-[11px] text-secondary'>
                        Terms
                    </Link>
                </li>
                <li className="">
                    <Link to='' className='text-[11px] text-secondary'>
                        Text to speech
                    </Link>
                </li>
                <li className="">
                    <Link to='' className='text-[11px] text-secondary'>
                        Teams
                    </Link>
                </li>
            </ul>

        </div>
    )
}

export default Links
