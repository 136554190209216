import React from 'react'

const Overlay = ({children, classes}) => {
    return (
        <div className={`fixed top-0 left-0 w-full h-full z-[1] ${classes}`}>
            {children}
        </div>
    )
}

export default Overlay
