import Chart from 'chart.js/auto'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import usePost from '../../../../hooks/usePost'
const VisitorsChart = () => {

    const {response, error, isLoading, post} = usePost()
    const canvasRef = useRef()
    const chartRef = useRef()
    const [filter, setFilter] = useState('all_time')
    const [chartData, setChartData] = useState({
        labels: [],
        data: []
    })

    useEffect(() => {

        allTimeChart()
        refreshChart()

        return () => {
            clearChart()
        }

    }, [])

    useEffect(() => {

        // if ( error )
        // {
        //     console.log(error)
        // }

        if ( response )
        {
            // console.log(response.data)
            const data = response.data
            
            chartData.data = data.map(item => item.total )

            if ( filter == 'today' || filter == 'current_month' || filter == 'all_time' )
            {
                chartData.labels = data.map(item => item.date )
                
            }
            else if ( filter == 'current_week' )
            {
                chartData.labels = data.map(item => item.days_of_week )
            }
            else if ( filter == 'current_year' )
            {
                chartData.labels = data.map(item => item.months_of_year )
            }
   
            refreshChart()
        }

    }, [response, error])

    const refreshChart = () => {
        if ( chartRef.current )
        {
            chartRef.current.destroy()
            chartRef.current = null
        }

        chartRef.current = new Chart(
            canvasRef.current,
            {
                type: 'line',
                options: {
                    animation: true,
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                font: {
                                    family: 'Open Sans',
                                    weight: 'normal'
                                }
                            }
                        },
                        tooltip: {
                            enabled: true
                        }
                    }
                },
                data: {
                    labels: chartData.labels,
                    datasets: [
                        {
                            borderColor: '#d2fffd',
                            backgroundColor: '#49D0CB',
                            label: 'Total visitors',
                            data: chartData.data
                        }
                    ]
                }
            }
        )
    }

    const clearChart = () => {
        if ( chartRef.current )
        {
            chartRef.current.destroy()
            chartRef.current = null
        }
    }

    const allTimeChart = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'COUNT(id) as total',
                'DATE(created_at) as date',
            ],
            groupBy: 'date',
        })
    }

    const todayChart = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'COUNT(id) as total',
                'DATE(created_at) as date',
            ],
            today: true,
            groupBy: 'date',
        })
    }

    const currentWeekChart = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'COUNT(id) as total',
                'DATE(created_at) as date',
                `DATE_FORMAT(created_at, '%W') AS days_of_week`,
            ],
            current_week: true,
            groupBy: 'date',
        })
    }

    const currentMonthChart = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'COUNT(id) as total',
                'DATE(created_at) as date',
            ],
            current_month: true,
            groupBy: 'date',
        })
    }

    const currentYearChart = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'COUNT(id) as total',
                'DATE(created_at) as date',
                `DATE_FORMAT(created_at, '%M') AS months_of_year`,
            ],
            current_year: true,
            groupBy: 'date',
        })
    }

    const handleFilterChange = (e) => {
        const value = e.target.value

        setFilter(value)

        if ( value == 'today' )
        {
            todayChart()
        }
        else if ( value == 'current_week' )
        {
            currentWeekChart()
        }
        else if ( value == 'current_month' )
        {
            currentMonthChart()
        }
        else if ( value == 'current_year' )
        {
            currentYearChart()
        }
        else if ( value == 'all_time' )
        {
            allTimeChart()
        }
    }

    return (
        <div>

            <div className="mb-5">
                <select className="outline-none border-none shadow-[1px_2px_65px_14px_rgba(235,235,235,0.30)_inset] px-3 py-1 rounded-md text-sm text-secondary" value={filter} onChange={handleFilterChange}>
                    <option value="all_time">All Time</option>
                    <option value="today">Today</option>
                    <option value="current_week">This Week</option>
                    <option value="current_month">This Month</option>
                    <option value="current_year">This Year</option>
                </select>
            </div>

            <div>
                <canvas ref={canvasRef}></canvas>
            </div>

        </div>
    )
}

export default VisitorsChart
