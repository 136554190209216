import { useState } from "react"

const useNotification = () => {

    const [type, setType] = useState('dark')
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState('')
    
    const show = (type, message) =>
    {
        setType(type)
        setMessage(message)
        setVisible(true)
    }

    const hide = () =>
    {
        setMessage('')
        setVisible(false)
    }

    return { 
        type, 
        visible, 
        message,
        show, 
        hide
    }

}

export default useNotification
