import React from 'react'

function Textarea({ type, value, onChange, placeholder, rows, cols }) {

    const classes = ''

    return (
        <div>
            <textarea
                type={type ?? 'text'}
                value={value}
                onChange={onChange}
                className='outline-none border-none placeholder:text-sm w-full px-3 py-4 text-sm'
                placeholder={placeholder}
                rows={rows}
                cols={cols}
            ></textarea>
        </div>
    )
}

export default Textarea
