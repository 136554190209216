import { useEffect, useState } from "react"
import Editor from "../../components/editorjs/editor1/Editor"
import { changeDocumentTitle } from "../../helpers/helpers"
import StoryCreateSidebar from "./sidebar/StoryCreateSidebar"
import usePost from '../../hooks/usePost'
import useNotification from '../../hooks/useNotification'
import NotificationSeverity from "../../components/notifications/notification1/NotificationSeverity"
import useAuth from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import { UserDashboardRoutes } from "../../routes/RouteNames"
import SeoTextarea from "../../components/inputs/textarea2/SeoTextarea"
const StoryCreate = () => {
    // change page title
    changeDocumentTitle('New story')

    const {Auth} = useAuth()
    const [title, setTitle] = useState('')
    const {isLoading, response, error, post} = usePost()
    const {message: notificationMessage, visible: notificationVisible, type: notificationType, show: showNotification, hide: hideNotification} = useNotification()
    const [editor, setEditor] = useState()
    const navigate = useNavigate()

    useEffect(() =>
    {

        if ( error )
        {
            showNotification('danger', error.message)
        }

        if ( response )
        {
            navigate(UserDashboardRoutes().STORIES.UPDATE.nav(response.data.uuid))
        }

    }, [response, error])

    // title change
    const handleTitleChange = (value) =>
    {
        // const target = e.target
        
        if (!value) return

        setTitle(value)

        saveDraft(value)
    }

    const saveDraft = (title) =>
    {
        if ( title == '' ) return

        if ( isLoading ) return

        post('/dashboard/stories', {
            title: title,
            user_id: Auth().user.id,
        })
    }

    const getEditor = (editor) =>
    {
        setEditor(editor)
    }

    return (
        <div className="">

            <div className="">
                <StoryCreateSidebar isLoading={true} />
            </div>

            <main>

                <section className="mt-12">

                    <div className="px-5 max-w-[740px] mx-auto">
                        
                        <div className="">
                            <NotificationSeverity type={notificationType} visible={notificationVisible} message={notificationMessage} onClickHide={hideNotification} />
                        </div>

                        <div className="mb-4">
                            <SeoTextarea autofocus={true} placeholder="Title" value={title} setValue={setTitle} onChange={(e) => setTitle(e.target.value)} getValue={handleTitleChange} rows={2} max={50} />
                        </div>

                        <div className="">
                            <Editor getEditor={getEditor} />
                        </div>

                    </div>

                </section>

            </main>

        </div>
    )
}

export default StoryCreate
