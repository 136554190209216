import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import DocumentScrollEndDetector from "../../../components/DocumentScrollEndDetector"
import NotificationSeverity from "../../../components/notifications/notification1/NotificationSeverity"
import Topbar from "../../../components/partials/topbar1/Topbar"
import Placeholder from "../../../components/placeholders/placeholder1/Placeholder"
import { abbreviateNumber, getDatetimeDifference, pushUniqueObjects, summarizeText } from "../../../helpers/helpers"
import useFetch from "../../../hooks/useFetch"
import useNotification from "../../../hooks/useNotification"
import usePost from "../../../hooks/usePost"

const ResponseShow = () => {

    const { response, error, isLoading, post } = usePost()
    const { visible: notificationVisible, message: notificationMessage, type: notificationType, hide: hideNotification, show: showNotification } = useNotification()
    const { response: commentResponse, error: commentError, isLoading: commentIsloading, fetch: commentFetch } = useFetch()
    const { uuid } = useParams()
    const [comment, setComment] = useState(null)
    const [replies, setReplies] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 5,
        last_page: 0,
    })
    const updateCurrentPageButtonRef = useRef()

    useEffect(() => {

        if (commentError) {
            // console.log(commentError)
        }

        if (commentResponse) {
            // console.log(commentResponse)
            setComment(commentResponse.data)
        }

    }, [commentResponse, commentError])

    useEffect(() => {

        commentFetch(`/dashboard/comments/${uuid}`)

    }, [])

    useEffect(() => {

        if (error) {
            showNotification('danger', error.message)
        }

        if (response) {
            const uniqueData = pushUniqueObjects(replies, response.data.data, 'id')
            console.log(response)
            setReplies(uniqueData)
            setPagination({
                ...pagination,
                last_page: response.data.last_page,
            })
        }

    }, [response, error])

    useEffect(() => {

        searchReplies()

    }, [pagination.current_page, comment])

    const searchReplies = () => {
        if (!comment) return

        post('/dashboard/replies/search', {
            searchTerm: '',
            comment_id: comment.id,
            with: ['comment'],
            orderBy: 'id',
            order: 'desc',
            pagination: pagination
        })
    }

    const handleScrollEnd = (e) => {
        if (!comment) return
        updateCurrentPageButtonRef.current.click()
    }

    const updateCurrentPage = () => {
        if (pagination.current_page == pagination.last_page) return

        if (isLoading) return

        setPagination({
            ...pagination,
            current_page: pagination.current_page + 1,
        })
    }

    return (
        <div>

            <DocumentScrollEndDetector onScrollEnd={(handleScrollEnd)} />

            <NotificationSeverity type={notificationType} message={notificationMessage} visible={notificationVisible} onClickHide={hideNotification} />

            <button className="hidden" ref={updateCurrentPageButtonRef} onClick={updateCurrentPage}>Update current page</button>

            <div className="max-w-[1192px] mx-auto px-4 md:px-16 pt-[40px]">

                <article>

                    <section>

                        <div className="max-w-[680px] mx-auto">

                            {
                                commentIsloading && (
                                    <div className="px-6 border-b border-forth">

                                        <div className="pt-[25px] pb-4">

                                            <div className="flex items-center mb-[5px]">
                                                <div className="mr-3">
                                                    <Placeholder className={'w-8 h-8 box-border !rounded-full'} />
                                                </div>
                                                <div className="">
                                                    <div className="flex items-center">
                                                        <div className="text-primary text-sm font-medium">
                                                            <Placeholder className={'w-11 h-4 !rounded-sm'} />
                                                        </div>
                                                    </div>
                                                    <div className="text-xs text-secondary leading-[20px] mt-1">
                                                        <Placeholder className={'w-8 h-2 !rounded-sm'} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="py-[5px] text-primary text-sm leading-[25px] mb-[14px]">
                                                <div className="mb-1">
                                                    <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                )
                            }

                            {
                                comment && (
                                    <>

                                        <div className="">

                                            <div className="px-6 border-b border-forth">

                                                <div className="pt-[25px] pb-4">

                                                    <div className="flex items-center mb-[5px]">
                                                        <div className="mr-3">
                                                            <img src={comment.avatar} className="w-8 h-8 box-border rounded-full bg-forth" alt="" />
                                                        </div>
                                                        <div className="">
                                                            <div className="flex items-center">
                                                                <p className="text-primary text-sm font-medium">{comment.name}</p>
                                                                <div className="bg-sixth uppercase text-[11px] text-white font-normal px-[6px] ml-2 rounded-sm">
                                                                    {
                                                                        comment.email == comment.story.author.email && 'Author'
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="text-xs text-secondary leading-[20px]">
                                                                {getDatetimeDifference(comment.created_at).auto}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="py-[5px] text-primary text-sm leading-[25px] mb-[14px]" dangerouslySetInnerHTML={{ __html: summarizeText(comment.content) }} />

                                                    <div className="text-sm">
                                                        <span className="text-secondary">Commented on: </span>
                                                        <a href={comment.story.urls.canonical} target="_blank" className="hover:underline text-fifth">
                                                            {summarizeText(comment.story.title, 50)}
                                                        </a>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </>
                                )
                            }


                            <div className="mt-[40px]">

                                {
                                    replies && replies.map((reply, replyIndex) => {
                                        return (
                                            <div className="" key={reply.id}>

                                                <div className="border-l-[3px] border-forth mb-6 ml-[12px]">

                                                    <div className="pl-6">

                                                        <div className="pt-[25px] pb-4">

                                                            <div className="flex items-center mb-[5px]">
                                                                <div className="mr-3">
                                                                    <img src={reply.avatar} className="w-8 h-8 box-border rounded-full bg-forth" alt="" />
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex items-center">
                                                                        <p className="text-primary text-sm font-medium">{reply.name}</p>
                                                                        <div className="bg-sixth uppercase text-[11px] text-white font-normal px-[6px] ml-2 rounded-sm">
                                                                            {
                                                                                reply.email == reply.comment.story.author.email && 'Author'
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-xs text-secondary leading-[20px]">
                                                                        {getDatetimeDifference(reply.created_at).auto}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="py-[5px] text-primary text-sm leading-[25px] mb-[14px]" dangerouslySetInnerHTML={{ __html: reply.content }} />

                                                            <div className="flex justify-between items-center text-sm text-primary mb-4">
                                                                <div className="flex items-center">

                                                                    <div className="mr-2">
                                                                        <div className="flex items-center hover:fill-[#757575]">
                                                                            <div className="">
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" aria-label="clap" fill="currentColor"><path fillRule="evenodd" clipRule="evenodd" d="M11.37.83L12 3.28l.63-2.45h-1.26zM13.92 3.95l1.52-2.1-1.18-.4-.34 2.5zM8.59 1.84l1.52 2.11-.34-2.5-1.18.4zM18.52 18.92a4.23 4.23 0 0 1-2.62 1.33l.41-.37c2.39-2.4 2.86-4.95 1.4-7.63l-.91-1.6-.8-1.67c-.25-.56-.19-.98.21-1.29a.7.7 0 0 1 .55-.13c.28.05.54.23.72.5l2.37 4.16c.97 1.62 1.14 4.23-1.33 6.7zm-11-.44l-4.15-4.15a.83.83 0 0 1 1.17-1.17l2.16 2.16a.37.37 0 0 0 .51-.52l-2.15-2.16L3.6 11.2a.83.83 0 0 1 1.17-1.17l3.43 3.44a.36.36 0 0 0 .52 0 .36.36 0 0 0 0-.52L5.29 9.51l-.97-.97a.83.83 0 0 1 0-1.16.84.84 0 0 1 1.17 0l.97.97 3.44 3.43a.36.36 0 0 0 .51 0 .37.37 0 0 0 0-.52L6.98 7.83a.82.82 0 0 1-.18-.9.82.82 0 0 1 .76-.51c.22 0 .43.09.58.24l5.8 5.79a.37.37 0 0 0 .58-.42L13.4 9.67c-.26-.56-.2-.98.2-1.29a.7.7 0 0 1 .55-.13c.28.05.55.23.73.5l2.2 3.86c1.3 2.38.87 4.59-1.29 6.75a4.65 4.65 0 0 1-4.19 1.37 7.73 7.73 0 0 1-4.07-2.25zm3.23-12.5l2.12 2.11c-.41.5-.47 1.17-.13 1.9l.22.46-3.52-3.53a.81.81 0 0 1-.1-.36c0-.23.09-.43.24-.59a.85.85 0 0 1 1.17 0zm7.36 1.7a1.86 1.86 0 0 0-1.23-.84 1.44 1.44 0 0 0-1.12.27c-.3.24-.5.55-.58.89-.25-.25-.57-.4-.91-.47-.28-.04-.56 0-.82.1l-2.18-2.18a1.56 1.56 0 0 0-2.2 0c-.2.2-.33.44-.4.7a1.56 1.56 0 0 0-2.63.75 1.6 1.6 0 0 0-2.23-.04 1.56 1.56 0 0 0 0 2.2c-.24.1-.5.24-.72.45a1.56 1.56 0 0 0 0 2.2l.52.52a1.56 1.56 0 0 0-.75 2.61L7 19a8.46 8.46 0 0 0 4.48 2.45 5.18 5.18 0 0 0 3.36-.5 4.89 4.89 0 0 0 4.2-1.51c2.75-2.77 2.54-5.74 1.43-7.59L18.1 7.68z"></path></svg>
                                                                            </div>
                                                                            <div className="ml-1">
                                                                                {abbreviateNumber(reply.total_claps)}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        )
                                    })
                                }

                                {
                                    isLoading && Array.from([0, 1, 2, 3, 4, 5]).map((v, k) => {
                                        return (
                                            <div className="" key={k}>

                                                <div className="px-6 border-b border-forth">

                                                    <div className="pt-[25px] pb-4">

                                                        <div className="flex items-center mb-[5px]">
                                                            <div className="mr-3">
                                                                <Placeholder className={'w-8 h-8 box-border !rounded-full'} />
                                                            </div>
                                                            <div className="">
                                                                <div className="flex items-center">
                                                                    <div className="text-primary text-sm font-medium">
                                                                        <Placeholder className={'w-11 h-4 !rounded-sm'} />
                                                                    </div>
                                                                </div>
                                                                <div className="text-xs text-secondary leading-[20px] mt-1">
                                                                    <Placeholder className={'w-8 h-2 !rounded-sm'} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="py-[5px] text-primary text-sm leading-[25px] mb-[14px]">
                                                            <div className="mb-1">
                                                                <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                            </div>
                                                            <div className="mb-1">
                                                                <Placeholder className={'w-full h-3 !rounded-sm !flex'} />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        )
                                    })
                                }

                                {
                                    !isLoading && !replies.length && (
                                        <div className="text-sm text-secondary">
                                            There are no replies for this comment.
                                        </div>
                                    )
                                }

                            </div>

                        </div>

                    </section>

                </article>

            </div>

        </div>
    )
}

export default ResponseShow
