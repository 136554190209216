import React from 'react'

const Overlay = ({children, classes = ''}) => {
    return (
        <div className={`absolute top-0 left-0 w-full h-full bg-[rgb(0,0,0,.2)] z-[1] ${classes}`}>
            {children}
        </div>
    )
}

export default Overlay
