import React from 'react'

function Input({ 
    type, 
    value, 
    onChange, 
    placeholder,
}) {

    const classes = ''

    return (
        <div className=''>
            <input
                type={type ?? 'text'}
                value={value}
                onChange={onChange}
                className='h-7 outline-none border-none placeholder:text-sm w-full px-3 py-2 text-sm'
                placeholder={placeholder}
            />
        </div>
    )
}

export default Input
