import { useEffect, useState } from "react"
import usePost from "../../../hooks/usePost"
import Placeholder from '../../../components/placeholders/placeholder1/Placeholder'
import { formatReadTime, getDatetimeDifference, summarizeText } from "../../../helpers/helpers"
import Table from '../../../components/tables/table1/Table'
import TBodyItem from "../../../components/tables/table1/TBodyItem"
import SearchBox from "../../../components/searchbox/searchbox1/SearchBox"
import ApiPagination from "../../../components/paginations/ApiPagination"
import CopyUrlToClipboard from "../../../components/content/CopyUrlToClipboard"
const VisitorsList = () => {

    const { response, error, isLoading, post } = usePost()
    const [visitors, setVisitors] = useState(null)
    const [pagination, setPagination] = useState({
        per_page: 5,
        current_page: 1,
        last_page: 0,
    })
    const [searchBoxValue, setSearchBoxValue] = useState('')

    useEffect(() => {

        if (error) {
            console.log(error)
        }

        if (response) {
            setVisitors(response.data.data)
            setPagination({
                ...pagination,
                last_page: response.data.last_page,
            })
        }

    }, [response, error])

    useEffect(() => {
        searchVisitors()
    }, [pagination.current_page])

    const searchVisitors = () => {
        post('/dashboard/visitors/search', {
            searchTerm: searchBoxValue,
            pagination: pagination,
            orderBy: 'id',
            order: 'desc',
        })
    }

    const onSearchBoxTypingEnd = (e) => {
        searchVisitors()
    }

    const handlePaginationFirst = () => {

        if (pagination.current_page == 1) return

        setPagination({
            ...pagination,
            current_page: 1
        })
    }

    const handlePaginationLast = () => {

        if (pagination.current_page == pagination.last_page) return

        setPagination({
            ...pagination,
            current_page: pagination.last_page
        })

    }

    const handlePaginationPrevious = () => {

        if (pagination.current_page == 1) return

        setPagination({
            ...pagination,
            current_page: pagination.current_page - 1
        })

    }

    const handlePaginationNext = () => {

        if (pagination.current_page == pagination.last_page) return

        setPagination({
            ...pagination,
            current_page: pagination.current_page + 1
        })

    }

    return (
        <div>

            {
                visitors && (
                    <>
                        <div className="mb-4 py-1 ">
                            <SearchBox value={searchBoxValue} setValue={setSearchBoxValue} getValue={onSearchBoxTypingEnd} />
                        </div>

                        <div className="my-4">
                            <ApiPagination onFirst={handlePaginationFirst} onLast={handlePaginationLast} onPrevious={handlePaginationPrevious} onNext={handlePaginationNext} />
                        </div>

                        <table className="w-full bg-white">
                            <thead>
                                <tr>
                                    <th className="font-light p-4 text-left border-b border-[#e0e0e0]">Device</th>
                                    <th className="font-light p-4 text-left border-b border-[#e0e0e0]">Platform</th>
                                    <th className="font-light p-4 text-left border-b border-[#e0e0e0]">Browser</th>
                                    <th className="font-light p-4 text-left border-b border-[#e0e0e0]">Ip</th>
                                    <th className="font-light p-4 text-left border-b border-[#e0e0e0]">User Agent</th>
                                    <th className="font-light p-4 text-left border-b border-[#e0e0e0]">Route</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    isLoading && Array.from([1, 2, 3, 4]).map((v, k) => {
                                        return (
                                            <tr key={k}>
                                                <TBodyItem className="">
                                                    <Placeholder className={'!block h-2'} />
                                                </TBodyItem>
                                                <TBodyItem className="">
                                                    <Placeholder className={'!block h-2'} />
                                                </TBodyItem>
                                                <TBodyItem className="">
                                                    <Placeholder className={'!block h-2'} />
                                                </TBodyItem>
                                                <TBodyItem className="">
                                                    <Placeholder className={'!block h-2'} />
                                                </TBodyItem>
                                                <TBodyItem className="">
                                                    <Placeholder className={'!block h-2'} />
                                                </TBodyItem>
                                                <TBodyItem className="text-sm text-secondary">
                                                    <div className="mb-2">
                                                        <Placeholder className={'!block h-2'} />
                                                    </div>
                                                    <div className="">
                                                        <Placeholder className={'!block h-2'} />
                                                    </div>
                                                </TBodyItem>
                                            </tr>
                                        )
                                    })
                                }

                                {
                                    !isLoading && visitors && visitors.map((visitor, visitorIndex) => {
                                        return (
                                            <tr key={visitor.id}>
                                                <TBodyItem className="">
                                                    {visitor.device}
                                                </TBodyItem>
                                                <TBodyItem className="">
                                                    {visitor.platform} {visitor.platform_version}
                                                </TBodyItem>
                                                <TBodyItem className="">
                                                    {visitor.browser} {visitor.browser_version}
                                                </TBodyItem>
                                                <TBodyItem className="">
                                                    {visitor.ip}
                                                </TBodyItem>
                                                <TBodyItem className="">
                                                    {visitor.useragent}
                                                </TBodyItem>
                                                <TBodyItem className="text-sm text-secondary">
                                                    <div className="mb-2">
                                                        <CopyUrlToClipboard url={visitor.route} />
                                                    </div>
                                                    <div className="">
                                                        {visitor.route_path}
                                                    </div>
                                                </TBodyItem>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                )
            }

            {
                !visitors && !isLoading && (
                    <div className="text-sm text-secondary">
                        Must gather more data to display.
                    </div>
                )
            }


        </div >
    )
}

export default VisitorsList
