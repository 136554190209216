import Overlay from "../overlays/overlay2/Overlay"

const ConfirmDeleteModal = ({
    title = 'Confirm Delete',
    message = 'Are you sure you want to delete?',
    isOpen = false,
    isLoading = false,
    onCancel,
    onConfirm,
    className = ''
}) => {

    if (!isOpen) return null

    return (
        <div className="">
            <Overlay classes={`bg-[rgba(255,255,255,0.94)] flex items-center justify-center z-[1200] ${className}`}>

                <div className="w-full max-w-[900px] min-h-[550px] shadow-[rgba(0,0,0,0.15)_0px_2px_10px] bg-white flex items-center justify-center relative">

                    <button className="group absolute top-[18px] right-[18px] w-[29px] h-[29px]" onClick={onCancel}>
                        <svg className="fill-[#6B6B6B] group-hover:fill-[#191919]" width="29" height="29"><path d="M20.13 8.11l-5.61 5.61-5.6-5.61-.81.8 5.61 5.61-5.61 5.61.8.8 5.61-5.6 5.61 5.6.8-.8-5.6-5.6 5.6-5.62" fillRule="evenodd"></path></svg>
                    </button>

                    <div className="py-[44px] px-[56px] text-center max-w-[680px]">

                        <div className="mb-4">
                            <h2 className="text-[32px] text-primary font-bold leading-[38px]">{title}</h2>
                        </div>

                        <div className="mb-6">
                            <p className="text-sm leading-7 text-secondary">
                                {message}
                            </p>
                        </div>

                        <div className="flex justify-center items-center mb-7">

                            <div className="">
                                <button className="border border-primary rounded-3xl bg-transparent text-primary text-sm py-2 px-4" disabled={isLoading} onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>

                            <div className="w-3"></div>

                            <div className="">
                                <button className="border border-[#b63636] rounded-3xl bg-[#b63636] text-white text-sm py-2 px-4 hover:bg-[#ac3d3d] hover:border-[#ac3d3d]" disabled={isLoading} onClick={onConfirm}>
                                    Delete
                                </button>
                            </div>

                        </div>

                        {
                            isLoading && (
                                <div className="text-center">
                                    <p className="text-fifth text-sm">Deleting item, please wait...</p>
                                </div>
                            )
                        }

                    </div>

                </div>

            </Overlay>
        </div>
    )
}

export default ConfirmDeleteModal
