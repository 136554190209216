import React from 'react'

const TBody = ({children}) => {
    return (
        <tbody>
            {children}
        </tbody>
    )
}

export default TBody
