import React, { useEffect, useState } from 'react'
import { uniqid } from '../../helpers/helpers'
import useFetch from '../../hooks/useFetch'
import SelectTagOption from './SelectTagOption'
const SelectTag = ({
    value,
    onChange,
}) => {

    const {response, error, isLoading, fetch} = useFetch()
    const [tags, setTags] = useState([])

    useEffect(() => {

        if ( response )
        {
            setTags(response.data)
        }

    }, [response, error])

    useEffect(() => {

        fetch('/dashboard/tags/list/tree')

    }, [])
    
    return (
        <div>
            <select className={`outline-none border-none placeholder:text-sm w-full px-3 py-2 text-sm`} value={value} onChange={onChange}>

                <option value="">--Parent Category--</option>

                {
                    tags.length && tags.map((tag, tagIndex) => {
                        return (
                            <SelectTagOption key={uniqid()} parentId={tag.parent_id} tag={tag} />
                        )
                    })
                }

            </select>
        </div>
    )
}

export default SelectTag
