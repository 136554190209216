import Sidebar from './sidebar/Sidebar'
import { changeDocumentTitle, pushUniqueObjects, getDatetimeDifference, summarizeText } from '../../../helpers/helpers'
import { useEffect, useRef, useState } from 'react'
import SearchBox from '../../../components/searchbox/searchbox1/SearchBox'
import DocumentScrollEndDetector from '../../../components/DocumentScrollEndDetector'
import NotificationSeverity from '../../../components/notifications/notification1/NotificationSeverity'
import useNotification from '../../../hooks/useNotification'
import usePost from '../../../hooks/usePost'
import usePatch from '../../../hooks/usePatch'
import useAuth from '../../../hooks/useAuth'
import ContextMenu from './context-menu/ContextMenu'
import Placeholder from '../../../components/placeholders/placeholder1/Placeholder'
const NotificationsIndex = () => {
    // change page title
    const PAGE_TITLE = changeDocumentTitle('Activity notifications')

    const { Auth } = useAuth()
    const { response, error, isLoading, post } = usePost()
    const { patch } = usePatch()
    const { visible: notificationVisible, message: notificationMessage, type: notificationType, hide: hideNotification, show: showNotification } = useNotification()
    const [notifications, setNotifications] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 5,
        last_page: 0,
    })
    const updateCurrentPageButtonRef = useRef()
    const [searchBoxValue, setSearchBoxValue] = useState('')

    useEffect(() => {

        if (error) {
            showNotification('danger', error.message)
        }

        if (response) {
            const uniqueData = pushUniqueObjects(notifications, response.data.data, 'id')
            setNotifications(uniqueData)
            setPagination({
                ...pagination,
                last_page: response.data.last_page,
            })
        }

    }, [response, error])

    useEffect(() => {

        searchNotifications()

    }, [pagination.current_page])

    useEffect(() => {

        patch(`/dashboard/users/${Auth().user.id}/activity/notifications/setAllRead`)

    }, [])

    const searchNotifications = () => {
        post('/dashboard/users/activity/notifications/search', {
            searchTerm: searchBoxValue,
            user_id: Auth().user.id,
            orderBy: 'created_at',
            order: 'desc',
            pagination: pagination
        })
    }

    const handleOnDelete = (notification) => {
        setNotifications(notifications.filter(v => v.id != notification.id))
    }

    const handleScrollEnd = (e) => {
        updateCurrentPageButtonRef.current.click()
    }

    const updateCurrentPage = () => {
        if (pagination.current_page == pagination.last_page) return

        if (isLoading) return

        setPagination({
            ...pagination,
            current_page: pagination.current_page + 1,
        })
    }

    const onSearchBoxTypingEnd = (e) => {

    }

    return (
        <div className="h-full">

            <DocumentScrollEndDetector onScrollEnd={(handleScrollEnd)} />

            <NotificationSeverity type={notificationType} message={notificationMessage} visible={notificationVisible} onClickHide={hideNotification} />

            <button className="hidden" ref={updateCurrentPageButtonRef} onClick={updateCurrentPage}>Update current page</button>

            <div className="flex justify-evenly flex-row h-full">

                <main className="md:min-w-[728px] md:max-w-[728px] px-2">

                    <div className="mt-[40px]">

                        <div className="">

                            <div className="">
                                <h1 className="text-[42px] mb-11">{PAGE_TITLE}</h1>
                            </div>

                        </div>

                        <div className="mb-2">
                            <SearchBox value={searchBoxValue} setValue={setSearchBoxValue} getValue={onSearchBoxTypingEnd} />
                        </div>

                        <div className="pt-6">

                            {
                                notifications && notifications.map((n, i) => {
                                    return (
                                        <div className="pb-5 pt-5 border-b border-forth" key={n.id}>

                                            <div className="flex items-center mb-5">
                                                <div className="mr-2">
                                                    <img src={n.data.by.avatar} className="w-8 h-8 rounded-full bg-forth" alt="" />
                                                </div>
                                                <div className="">
                                                    <div className="mb-1">
                                                        <h2 className="font-semibold text-base">
                                                            {n.data.by.name}
                                                        </h2>
                                                    </div>
                                                    <div className="">
                                                        <p className="text-[13px] text-secondary">
                                                            {getDatetimeDifference(n.created_at).auto}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-2" dangerouslySetInnerHTML={{ __html: n.data.content }}></div>

                                            <div className="flex items-center justify-between">

                                                <div className="">
                                                    <a href={n.data.url} target='_blank' className='text-fifth text-sm hover:underline'>
                                                        Follow link
                                                    </a>
                                                </div>

                                                <div className="">
                                                    <ContextMenu notification={n} onDelete={handleOnDelete} />
                                                </div>

                                            </div>

                                        </div>
                                    )
                                })
                            }

                            {
                                isLoading && Array.from([0, 1, 2, 3, 4, 5]).map((v, k) => {
                                    return (
                                        <div className="pb-5 pt-5 border-b border-forth" key={v}>

                                            <div className="flex items-center mb-5">
                                                <div className="mr-2">
                                                    <Placeholder className={'w-8 h-8 rounded-full'} />
                                                </div>
                                                <div className="">
                                                    <div className="mb-1">
                                                        <Placeholder className={'h-4 rounded-sm !block'} />
                                                    </div>
                                                    <div className="">
                                                        <Placeholder className={'w-14 h-2 rounded-sm'} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-2">
                                                <div className="mb-1">
                                                    <Placeholder className={'h-2 rounded-sm !block'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'h-2 rounded-sm !block'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'h-2 rounded-sm !block'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'h-2 rounded-sm !block'} />
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-between">

                                                <div className="">
                                                    <Placeholder className={'w-14 h-2 rounded-sm'} />
                                                </div>

                                            </div>

                                        </div>
                                    )
                                })
                            }

                            {
                                !isLoading && !notifications.length && (
                                    <div className="text-sm text-secondary">
                                        There are no notifications to show at the moment.
                                    </div>
                                )
                            }

                        </div>

                    </div>

                </main>

                <div className="md:block hidden border-l border-[#f2f2f2] pl-8 max-w-[368px]">
                    <div className="sticky top-0 h-[100vh] max-h-[100vh]">
                        <Sidebar />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default NotificationsIndex
