// import { useEffect, useState } from "react"

import { useEffect } from "react";

const DocumentScrollEndDetector = ({maxHeight = 300, onScrollEnd}) => {


    useEffect(() => {

        const handleScroll = () =>
        {
            const target = document.documentElement
            const scrollHeight = target.scrollHeight;
            const scrollTop = target.scrollTop;
            const clientHeight = target.clientHeight;
            const endScroll = Math.round(scrollTop + clientHeight) >= scrollHeight - maxHeight
            
            if ( endScroll )
            {
                onScrollEnd({
                    scrollHeight,
                    scrollTop,
                    clientHeight,
                    endScroll,
                })
            }
        }

        document.addEventListener('scroll', handleScroll)

        return () => {
            document.removeEventListener('scroll', handleScroll)
        }

    }, [])

    return (
        <>

        </>
    )
}

export default DocumentScrollEndDetector
