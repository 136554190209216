import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import StoryAuthorCard from "../../components/profile/StoryAuthorCard"
import useFetch from "../../hooks/useFetch"
import StoryContent from "./StoryContent"

const StoryShow = () => {

    const { response, error, isLoading, fetch } = useFetch()
    const { slug } = useParams()
    const [story, setStory] = useState(null)

    useEffect(() => {

        if (error) {
            console.log(error)
        }

        if (response) {
            setStory(response.data)
        }

    }, [response, error])

    useEffect(() => {

        fetch(`/dashboard/story/${slug}`)

    }, [])

    return (
        <div>

            <div className="max-w-[1192px] mx-auto px-4 md:px-16 pt-[40px]">

                <article>

                    <section>

                        <div className="max-w-[680px] mx-auto">

                            <div className="">
                                <StoryAuthorCard story={story} />
                            </div>

                            <div className="mt-[40px]">

                                {
                                    story && (
                                        <div className="">
                                            <StoryContent story={story} />
                                        </div>
                                    )
                                }

                            </div>

                        </div>

                    </section>

                </article>

            </div>

        </div>
    )
}

export default StoryShow
