import './Placeholder.css'
const Placeholder = ({className}) => {
    return (
        <>
            <div className={`text-placeholder placeholder ${className}`}></div>
        </>
    )
}

export default Placeholder
