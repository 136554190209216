const NotificationSeverity = ({ type = "dark", message, visible, onClickHide }) => {

    if (type == 'dark') {
        return (
            <div className={`bg-[#000000] rounded-[4px] py-[10px] px-4 text-white text-left fixed w-full max-w-[640px] transition-transform duration-500 top-3 left-[50%] translate-x-[-50%] ${visible ? 'translate-y-[0]' : 'translate-y-[-100px]'} z-[1000]`}>

                <div className="flex items-center">

                    <div className="w-full text-sm font-light">
                        {message}
                    </div>

                    <div className="">
                        <button type='button' onClick={onClickHide} className='inline-flex items-center justify-center p-[5px] text-[1.125rem] rounded-full text-white'>
                            <svg width="19" height="19" viewBox="0 0 19 19" className="ei"><path d="M13.8 4.6L9.5 8.89 5.21 4.6l-.61.61 4.29 4.3-4.29 4.28.61.62 4.3-4.3 4.28 4.3.62-.62-4.3-4.29 4.3-4.29" fillRule="evenodd" fill="currentColor"></path></svg>
                        </button>
                    </div>

                </div>

            </div>
        )
    }
    else if (type == 'danger') {
        return (
            <div className={`bg-fifth rounded-[4px] py-[10px] px-4 text-white text-left fixed w-full max-w-[640px] transition-transform duration-500 top-3 left-[50%] translate-x-[-50%] ${visible ? 'translate-y-[0]' : 'translate-y-[-100px]'} z-[1000]`}>

                <div className="flex items-center">

                    <div className="w-full text-sm font-light">
                        {message}
                    </div>

                    <div className="">
                        <button type='button' onClick={onClickHide} className='inline-flex items-center justify-center p-[5px] text-[1.125rem] rounded-full text-white'>
                            <svg width="19" height="19" viewBox="0 0 19 19" className="ei"><path d="M13.8 4.6L9.5 8.89 5.21 4.6l-.61.61 4.29 4.3-4.29 4.28.61.62 4.3-4.3 4.28 4.3.62-.62-4.3-4.29 4.3-4.29" fillRule="evenodd" fill="currentColor"></path></svg>
                        </button>
                    </div>

                </div>

            </div>
        )
    }
    else if (type == 'success') {
        return (
            <div className={`bg-third rounded-[4px] py-[10px] px-4 text-white text-left fixed w-full max-w-[640px] transition-transform duration-500 top-3 left-[50%] translate-x-[-50%] ${visible ? 'translate-y-[0]' : 'translate-y-[-100px]'} z-[1000]`}>

                <div className="flex items-center">

                    <div className="w-full text-sm font-light">
                        {message}
                    </div>

                    <div className="">
                        <button type='button' onClick={onClickHide} className='inline-flex items-center justify-center p-[5px] text-[1.125rem] rounded-full text-white'>
                            <svg width="19" height="19" viewBox="0 0 19 19" className="ei"><path d="M13.8 4.6L9.5 8.89 5.21 4.6l-.61.61 4.29 4.3-4.29 4.28.61.62 4.3-4.3 4.28 4.3.62-.62-4.3-4.29 4.3-4.29" fillRule="evenodd" fill="currentColor"></path></svg>
                        </button>
                    </div>

                </div>

            </div>
        )
    }

}

export default NotificationSeverity
