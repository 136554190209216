import { useEffect, useState } from "react"

const usePagination = () => {
    
    const [currentPage, setCurrentPage] = useState(1)
    const [resultsPerpage, setResultsPerPage] = useState(8)
    const lastIndex = currentPage * resultsPerpage
    const firstIndex = lastIndex - resultsPerpage
    const [data, setData] = useState([])
    const [currentResults, setCurrentResults] = useState([])
    const [totalPageNumbers, setTotalPageNumbers] = useState(0)

    useEffect(() => {

        calculate()

    }, [currentPage, data])

    const changePage = (e, pageNumber) =>
    {
        setCurrentPage(pageNumber)
    }

    const calculate = () => {

        if ( !data.length ) return

        const slicedData = data.slice(firstIndex, lastIndex)

        setCurrentResults( slicedData )
        setTotalPageNumbers( Math.ceil(data.length / resultsPerpage) )
    }

    return {totalPageNumbers, resultsPerpage, currentPage, currentResults, setData, changePage, setResultsPerPage}

}

export default usePagination
