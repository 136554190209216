import React from 'react'
import Editor from '../components/editorjs/editor1/Editor'

const Test = () => {
    return (
        <div>
            <Editor />
        </div>
    )
}

export default Test
