import './Checkbox.css'

const Checkbox = ({
    dataId,
    name,
    value,
    onChange,
    checked = false,
    label,
    labelClasses = '',
}) => {
    return (
        <>
            <label className="checkbox-1 !flex items-center">
                <input type="checkbox"
                    data-id={dataId}
                    name={name}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    className="checkbox__input"
                />
                <span className="checkbox__inner"></span>

                {
                    label && (
                        <>
                            <div className="w-2"></div>
                            <div className={`text-[13px] ${labelClasses}`}>
                                <span>{label}</span>
                            </div>
                        </>
                    )
                }

            </label>
        </>
    )
}

export default Checkbox
