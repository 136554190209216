import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import NotificationSeverity from "../../../components/notifications/notification1/NotificationSeverity"
import Placeholder from "../../../components/placeholders/placeholder1/Placeholder"
import DocumentScrollEndDetector from "../../../components/DocumentScrollEndDetector"
import { formatPublishedDate, formatReadTime, summarizeText } from "../../../helpers/helpers"
import useNotification from "../../../hooks/useNotification"
import usePost from "../../../hooks/usePost"
import ContextMenu from "./context-menu/ContextMenu"
import { pushUniqueObjects } from "../../../helpers/helpers"
import { UserDashboardRoutes } from "../../../routes/RouteNames"
import ShareStoryMenu from "../../../components/context-menus/ShareStoryMenu"
import SearchBox from "../../../components/searchbox/searchbox1/SearchBox"
const StoryPublishedIndex = () => {

    const { response, error, isLoading, post } = usePost()
    const {visible: notificationVisible, message: notificationMessage, type: notificationType, hide: hideNotification, show: showNotification} = useNotification()
    const [stories, setStories] = useState([])
    // const [isStoriesEmpty, setIsStoriesEmpty] = useState(true)
    const [lastPage, setLastPage] = useState(0)
    const [resultsPerPage, setResultsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const updateCurrentPageButtonRef = useRef()
    const [searchBoxValue, setSearchBoxValue] = useState('')

    useEffect(() =>
    {

        if ( error )
        {
            showNotification('danger', error.message)
        }

        if ( response )
        {
            const uniqueData = pushUniqueObjects(stories, response.data.data, 'id')
            setStories(uniqueData)
            setLastPage(response.data.last_page)
        }

    }, [response, error])

    useEffect(() => {

        searchStories()

    }, [currentPage])

    const searchStories = () =>
    {
        post('/dashboard/stories/search', {
            searchTerm: searchBoxValue,
            with: ['author'],
            orderBy: 'id',
            order: 'desc',
            status: 'publish',
            pagination: {
                current_page: currentPage,
                per_page: resultsPerPage,
            }
        })
    }

    const handleOnDelete = (story) =>
    {
        setStories( stories.filter(v => v.id != story.id) )
    }

    const handleScrollEnd = (e) =>
    {
        updateCurrentPageButtonRef.current.click()
    }

    const updateCurrentPage = () =>
    {
        if ( currentPage == lastPage ) return

        if ( isLoading ) return
        setCurrentPage(currentPage+1)
    }

    const onSearchBoxTypingEnd = (e) => {
        setStories([])
        searchStories()
    }

    return (
        <div>
            <DocumentScrollEndDetector onScrollEnd={(handleScrollEnd)} />

            <NotificationSeverity type={notificationType} message={notificationMessage} visible={notificationVisible} onClickHide={hideNotification} />
            
            <button className="hidden" ref={updateCurrentPageButtonRef} onClick={updateCurrentPage}>Update current page</button>

            <div className="mb-2">
                <SearchBox value={searchBoxValue} setValue={setSearchBoxValue} getValue={onSearchBoxTypingEnd} />
            </div>

            <div className="">

                {
                    stories && stories.map((story, storyIndex) => {
                        return (
                            <div key={story.id} className="py-5 border-b border-[#f2f2f2]">

                                <h3 className="font-bold mb-3">
                                    <a href="javascript:void(0);">
                                        { story.title }
                                    </a>
                                </h3>

                                <div className="">

                                    <div className="">

                                        <div className="">

                                            <a href="javascript:void(0);">
                                                <div className="text-secondary text-sm mb-2">
                                                    <p>
                                                        { summarizeText(story.description) }
                                                    </p>
                                                </div>
                                            </a>

                                            <div className="flex items-center">

                                                <div className="text-xs text-secondary flex">
                                                    <div className="">{ formatPublishedDate(story.created_at) }</div>
                                                    <div className="mx-1">.</div>
                                                    <div className="">{ formatReadTime(story.read_time * 60) }</div>
                                                </div>

                                                <div className="w-2"></div>

                                                <div className="">
                                                    <ShareStoryMenu story={story} />
                                                </div>

                                                <div className="w-1"></div>

                                                <div className="">
                                                    <ContextMenu story={story} onDelete={handleOnDelete} />
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        )
                    })
                }

                {
                    isLoading && (
                        <div className="py-5 border-b border-[#f2f2f2]">

                            <h3 className="font-bold mb-3">
                                <Placeholder className={'h-5 !rounded-sm'} />
                            </h3>

                            <div className="">

                                <div className="">

                                    <div className="">

                                        <div>
                                            <div className="text-secondary text-sm mb-2">
                                                <div className="mb-1">
                                                    <Placeholder className={'h-1 !rounded-sm'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'h-1 !rounded-sm'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'h-1 !rounded-sm'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'h-1 !rounded-sm'} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center">

                                            <div className="text-xs text-secondary flex">
                                                <div className="w-24"><Placeholder className={'h-3 !rounded-sm'} /></div>
                                                <div className="mx-1">.</div>
                                                <div className="w-6"><Placeholder className={'h-3 !rounded-sm'} /></div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    )
                }

                {
                    !isLoading && !stories.length && (
                        <div className="text-sm text-secondary">
                            There are no stories to show at the moment.
                        </div>
                    )
                }


            </div>

        </div>
    )
}

export default StoryPublishedIndex
