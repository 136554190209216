
import { app } from './app'

export const hideEmail = (email) => {
	// Split the email address into username and domain
	const [username, domain] = email.split('@');

	// Hide part of the username
	const hiddenUsername = username.substring(0, 2) + '•'.repeat(username.length - 2);

	// Return the masked email address
	return `${hiddenUsername}@${domain}`;
}


export const changeDocumentTitle = (title) => {
	document.title = `${title} - ${app().NAME}`

	return title
}

export const formatTimeStamp = (timestamp) => {
	const months = [
		"Jan", "Feb", "Mar", "Apr",
		"May", "Jun", "Jul", "Aug",
		"Sep", "Oct", "Nov", "Dec"
	];

	if (typeof timestamp === 'string') {
		timestamp = new Date(timestamp);
	} else if (typeof timestamp === 'number') {
		timestamp = new Date(timestamp * 1000); // Convert seconds to milliseconds
	}

	const month = months[timestamp.getMonth()];
	const day = timestamp.getDate();
	const hour = timestamp.getHours();
	const minute = timestamp.getMinutes();
	const ampm = hour >= 12 ? "PM" : "AM";
	const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

	const formattedDate = `${month} ${day} at ${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
	return formattedDate;
}

export const getDatetimeDifference = (datetime) => {
	const TIME_DIFF = {
		seconds: ":attribute secondes ago",
		minutes: ":attribute minutes ago",
		hours: ":attribute hours ago",
		days: ":attribute days ago",
		months: ":attribute months ago",
		years: ":attribute years ago"
	};

	// Parse the input datetime string to a Date object
	const inputDate = new Date(datetime);

	// Get the current date and time
	const currentDate = new Date();

	// Calculate the difference in milliseconds
	const difference = Math.abs(inputDate - currentDate);
	var diff = {
		years: 0,
		months: 0,
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
		auto: '',
	}

	// Check if the input date is in the future, past, or present
	if (difference > 0) {
		diff = getPositiveDifference(difference);
	}
	else if (difference < 0) {
		diff = getNegativeDifference(Math.abs(difference));
	}
	else {
		diff = getZeroDifference();
	}

	// Determine the largest applicable unit for the current difference
	let largestUnit = 'seconds';

	for (const unit in diff) {
		if (diff[unit] > 0) {
			largestUnit = unit;
			break;
		}
	}


	const datediff = TIME_DIFF[largestUnit].replace(':attribute', diff[largestUnit]);

	// Assign the current difference to the "auto" property
	diff.auto = datediff;

	return diff
}

export const getPositiveDifference = (difference) => {
	// Calculate the absolute values of years, months, days, hours, minutes, and seconds
	const absoluteYears = Math.floor(difference / (365 * 24 * 60 * 60 * 1000));
	const absoluteMonths = Math.floor(difference / (30 * 24 * 60 * 60 * 1000));
	const absoluteDays = Math.floor(difference / (24 * 60 * 60 * 1000));
	const absoluteHours = Math.floor((difference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
	const absoluteMinutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
	const absoluteSeconds = Math.floor((difference % (60 * 1000)) / 1000);

	// Create an object to store the results
	const differenceObj = {
		years: absoluteYears,
		months: absoluteMonths,
		days: absoluteDays,
		hours: absoluteHours,
		minutes: absoluteMinutes,
		seconds: absoluteSeconds,
	};

	return differenceObj;
}

export const getNegativeDifference = (difference) => {
	// Calculate the absolute values of years, months, days, hours, minutes, and seconds
	const absoluteYears = Math.floor(difference / (365 * 24 * 60 * 60 * 1000));
	const absoluteMonths = Math.floor(difference / (30 * 24 * 60 * 60 * 1000));
	const absoluteDays = Math.floor(difference / (24 * 60 * 60 * 1000));
	const absoluteHours = Math.floor((difference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
	const absoluteMinutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
	const absoluteSeconds = Math.floor((difference % (60 * 1000)) / 1000);

	// Create an object to store the results with negative values
	const differenceObj = {
		years: -absoluteYears,
		months: -absoluteMonths,
		days: -absoluteDays,
		hours: -absoluteHours,
		minutes: -absoluteMinutes,
		seconds: -absoluteSeconds,
	};

	return differenceObj;
}

export const getZeroDifference = () => {
	// For the current date, return an object with all values as 0
	return {
		years: 0,
		months: 0,
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	};
}

export const copyToClipboard = (text) => {
	navigator.clipboard.writeText(text)
		.then(() => {
			// console.log('Text copied to clipboard');
		})
		.catch((err) => {
			console.error('Unable to copy to clipboard', err);
		});
}

export const shareOnFacebook = (url) => {
	// Construct the Facebook share URL
	const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;

	// Open a new window for the Facebook Share Dialog
	window.open(facebookShareUrl, 'Facebook Share', 'width=600,height=400');
}

export const shareOnTwitter = (url, text) => {
	// Construct the Twitter share URL
	const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;

	// Open a new window for the Twitter Web Intent
	window.open(twitterShareUrl, 'Twitter Share', 'width=600,height=400');
}

export const shareOnLinkedIn = (url, title, summary) => {
	// Construct the LinkedIn share URL
	const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}`;

	// Open a new window for the LinkedIn Share Dialog
	window.open(linkedInShareUrl, 'LinkedIn Share', 'width=600,height=400');
}

export const disableDocumentOverflow = () => {
	document.body.classList.add('overflow-hidden')
}

export const enableDocumentOverflow = () => {
	document.body.classList.remove('overflow-hidden')
}

export const abbreviateNumber = (number) => {

	if (!number) return '0'

	if (number >= 100 && number < 1000) {
		return (number / 10).toFixed(0) + '+';
	}
	else if (number >= 1000 && number < 1000000) {
		return (number / 1000).toFixed(1) + 'k';
	} else if (number >= 1000000) {
		return (number / 1000000).toFixed(1) + 'M';
	} else {
		return number.toString();
	}
}

export const calculateReadTime = (text, wordsPerMinute = 230) => {

	if (!text) return 1
	// Assuming an average reading speed of 200 words per minute
	const words = text.split(/\s+/).length;
	const readTimeMinutes = words / wordsPerMinute;

	// Round up to the nearest minute
	const roundedReadTime = Math.ceil(readTimeMinutes);

	return roundedReadTime;
}

export const summarizeText = (text, maxLength = 135) => {
	if (!text) return ''
	if (text.length <= maxLength) {
		return text;
	} else {
		// Find the last space within the maxLength
		const truncatedText = text.substring(0, maxLength);
		const lastSpaceIndex = truncatedText.lastIndexOf(' ');

		// Return the truncated text with ellipsis if necessary
		return lastSpaceIndex !== -1 ? truncatedText.substring(0, lastSpaceIndex) + '...' : truncatedText + '...';
	}
}

export const formatPublishedDate = (timestamp, textBefore = 'Published on') => {
	const months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	];

	const date = new Date(timestamp);
	const month = months[date.getMonth()];
	const day = date.getDate();

	return `${textBefore} ${month} ${day}`;
}

export const formatReadTime = (seconds) => {
	const minutes = Math.ceil(seconds / 60);

	if (minutes < 60) {
		if (minutes === 1) {
			return '1 min read';
		} else {
			return `${minutes} min read`;
		}
	} else {
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;

		if (remainingMinutes === 0) {
			return `${hours} hour read`;
		} else {
			return `${hours} hour ${remainingMinutes} min read`;
		}
	}
}

export const pushUniqueObjects = (targetArray, newArray, uniqueProperty) => {
	const uniqueValues = new Set(targetArray.map(obj => obj[uniqueProperty]));

	newArray.forEach(item => {
		if (!uniqueValues.has(item[uniqueProperty])) {
			targetArray.push(item);
			uniqueValues.add(item[uniqueProperty]);
		}
	});

	return targetArray;
}

export const formatBytes = (bytes) => {
	if (bytes === 0) return '0 B';

	const k = 1024;
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export const uniqid = () =>
{
	const uuid = require('uuid')

	return uuid.v4()
}

export const extractPathFromUrl = (url) => {

	const parsedUrl = new URL(url)

	return parsedUrl.pathname

}

export const frontendAsset = (path = '') => {
	const url = removeExtraSlashes(`${app().FRONT_END_URL}/${path}`)

	return url
}

export const removeExtraSlashes = (url) => {
	return url.replace(/([^:]\/)\/+/g, '$1');
}

export const dispatchCustomEvent = (name, data = {}, element) =>
{
	const event = new CustomEvent(name, {
		detail: data
	})

	if ( !element ) document.dispatchEvent(event)
	else element.dispatchEvent(event)
}