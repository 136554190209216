const ButtonInfo = ({
    onClick,
    text,
    disabled = false,
    classes,
}) => {
    return (
        <div>
            <button
                className={`text-primary border border-transparent text-sm pt-2 pb-[10px] px-7 flex items-center justify-center leading-[20px] transition-colors duration-300 hover:bg-[rgba(25,118,210,0.04)] ${classes}`}
                onClick={onClick}
                disabled={disabled}
                >
                {text}
            </button>
        </div>
    )
}

export default ButtonInfo
