import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ConfirmDeleteModal from "../../../../components/modals/ConfirmDeleteModal"
import useConfirmDeleteDialog from "../../../../hooks/useConfirmDeleteDialog"
import { UserDashboardRoutes } from '../../../../routes/RouteNames'
import useDelete from '../../../../hooks/useDelete'
import NotificationSeverity from "../../../../components/notifications/notification1/NotificationSeverity"
import useNotification from "../../../../hooks/useNotification"
const ContextMenu = ({ comment, onDelete }) => {

    const [visible, setVisible] = useState(false)
    const {title: cdmTitle, message: cdmMessage, isOpen: cdmIdOpen, show: cdmShow, hide: cdmHide} = useConfirmDeleteDialog()
    const {visible: notificationVisible, message: notificationMessage, type: notificationType, hide: hideNotification, show: showNotification} = useNotification()
    const {response, error, isLoading, _delete} = useDelete()

    useEffect(() =>
    {

        if ( error )
        {
            showNotification('danger', error.message)
        }

        if ( response )
        {
            showNotification('success', 'Your comment has been deleted.')
            cdmHide()
            if ( typeof onDelete == 'function' )
            {
                onDelete(comment, response)
            }
        }

    }, [response, error])

    const toggleMenu = () => {
        setVisible(!visible)
    }

    const handleDelete = () =>
    {
        cdmShow('Delete comment', "Deletion is not reversible, and the comment will be completely deleted. If you do not want to delete click cancel.")
        toggleMenu()
    }

    const confirmDeleteDialogOnConfirm = () =>
    {
        _delete(`/dashboard/comments/${comment.id}`)
    }

    return (
        <>
            <NotificationSeverity type={notificationType} message={notificationMessage} visible={notificationVisible} onClickHide={hideNotification} />
            <ConfirmDeleteModal 
                title={cdmTitle} 
                message={cdmMessage}
                isOpen={cdmIdOpen}
                onConfirm={confirmDeleteDialogOnConfirm}
                onCancel={cdmHide}
                isLoading={isLoading}
            />
            <div className="relative">

                {
                    visible && (
                        <div className="fixed w-full h-full z-[500] top-0 left-0" onClick={toggleMenu}></div>
                    )
                }

                <div className="relative">

                    <div className="flex items-center">
                        <button className='text-secondary' onClick={toggleMenu}>
                            <svg className="jb" width="25" height="25"><path d="M5 12.5c0 .55.2 1.02.59 1.41.39.4.86.59 1.41.59.55 0 1.02-.2 1.41-.59.4-.39.59-.86.59-1.41 0-.55-.2-1.02-.59-1.41A1.93 1.93 0 0 0 7 10.5c-.55 0-1.02.2-1.41.59-.4.39-.59.86-.59 1.41zm5.62 0c0 .55.2 1.02.58 1.41.4.4.87.59 1.42.59.55 0 1.02-.2 1.41-.59.4-.39.59-.86.59-1.41 0-.55-.2-1.02-.59-1.41a1.93 1.93 0 0 0-1.41-.59c-.55 0-1.03.2-1.42.59-.39.39-.58.86-.58 1.41zm5.6 0c0 .55.2 1.02.58 1.41.4.4.87.59 1.43.59.56 0 1.03-.2 1.42-.59.39-.39.58-.86.58-1.41 0-.55-.2-1.02-.58-1.41a1.93 1.93 0 0 0-1.42-.59c-.56 0-1.04.2-1.43.59-.39.39-.58.86-.58 1.41z" fillRule="evenodd"></path></svg>
                        </button>
                    </div>

                    {
                        visible && (
                            <div className="absolute z-[501] w-[160px] top-[28px] left-[-119px] md:left-[-47px]">

                                <div className="bg-white shadow-md rounded-sm relative z-50">

                                    <ul className="list-none py-2">
                                        {/* <li className="py-2 px-5 text-secondary text-sm">
                                            <Link to={UserDashboardRoutes().STORIES.UPDATE.nav(comment.uuid)} className="flex hover:text-[#242424]">
                                                Edit comment
                                            </Link>
                                        </li> */}
                                        <li className="py-2 px-5 text-fifth text-sm">
                                            <button className="flex hover:text-[#242424]" onClick={handleDelete}>
                                                Delete comment
                                            </button>
                                        </li>
                                    </ul>

                                </div>

                                <div className="bg-white shadow-[rgba(0,0,0,0.05)_0px_0px_4px,rgba(0,0,0,0.15)_0px_2px_8px] w-3 h-3 absolute top-[-6px] left-[126px] md:left-[52px] z-40 rotate-[45deg]"></div>

                            </div>
                        )
                    }

                </div>

            </div>
        </>
    )
}

export default ContextMenu
