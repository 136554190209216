import WebRoutes from "./routes/WebRoutes";

function App() {
    return (
        <main className="App">
            <WebRoutes/>
        </main>
    );
}

export default App;
