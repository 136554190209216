import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import UserPlaceholder from '../../assets/img/male.png'
import { summarizeText } from "../../helpers/helpers"
import Placeholder from "../placeholders/placeholder1/Placeholder"
import { UserDashboardRoutes } from '../../routes/RouteNames'
import usePost from "../../hooks/usePost"
import useAuth from "../../hooks/useAuth"
const LatestNotifications = ({ total = 4 }) => {

    const { Auth } = useAuth()
    const [notifications, setNotifications] = useState([])
    const { response, error, isLoading, post } = usePost()

    useEffect(() => {

        if (response) {
            setNotifications(response.data)
        }

    }, [response, error])

    useEffect(() => {
        post('/dashboard/users/activity/notifications/search', {
            searchTerm: '',
            user_id: Auth().user.id,
            orderBy: 'created_at',
            order: 'desc',
            offset: 0,
            limit: total,
        })
    }, [])

    return (
        <div>

            {/* {
                isLoading && (
                    Array.from(['', '', '', '']).map((v, i) => {
                        return (
                            <div className="mb-6" key={i}>

                                <div className="mb-2">
                                    <div>
                                        <div className="flex items-center">
                                            <div className="">
                                                <Placeholder className={'h-5 w-5 rounded-full'} />
                                            </div>
                                            <div className="w-2"></div>
                                            <div className="flex text-xs">
                                                <div className="font-semibold">
                                                    <Placeholder className={'h-5 w-20 !rounded-sm'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <div>
                                        <h2 className="font-bold text-sm">
                                            <Placeholder className={'h-7 !rounded-sm'} />
                                        </h2>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                )
            } */}

            {
                notifications && notifications.map((n, i) => {
                    return (
                        <div className="mb-6" key={n.id}>

                            <div className="mb-2">
                                <Link to={UserDashboardRoutes().USER.ACTIVITY.NOTIFICATIONS.INDEX.nav()}>
                                    <div className="flex items-center">

                                        <div className="flex-shrink-0">
                                            <img src={n.data.by.avatar} className="w-[20px] h-[20px] rounded-full" alt="" />
                                        </div>
                                        <div className="w-2"></div>
                                        <h4 className="font-semibold text-sm">
                                            {summarizeText(n.data.message, 50)}
                                        </h4>

                                    </div>
                                </Link>
                            </div>

                        </div>
                    )
                })
            }

        </div>
    )
}

export default LatestNotifications
