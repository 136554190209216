import React from 'react'
import { uniqid } from '../../helpers/helpers'

const SelectTagOption = ({ 
    tag 
}) => {

    return (
        <>
            {
                tag && (
                    <>
                        <option value={tag.id}>{tag.prefix} {tag.name}</option>
                        {
                            tag.children && tag.children.map((child, childIndex) => {
                                return (<SelectTagOption key={uniqid()} tag={child} />)
                            })
                        }
                    </>
                )
            }
        </>
    )
}

export default SelectTagOption
