import useAuth from '../../../../hooks/useAuth'
import UserPlaceholder from '../../../../assets/img/male.png'

const ProfileImage = ({classes, url = null}) => {

    const {Auth} = useAuth()

    var src = (Auth().user.avatar_urls) ? Auth().user.avatar_urls.original : UserPlaceholder

    if ( url ) src = url

    return (
        <div className='relative'>
            <img src={ src } className={`${!url && 'w-6 h-6'} rounded-full ${classes}`} alt="" />
        </div>
    )
}

export default ProfileImage
