import React from 'react'
import { Link } from 'react-router-dom'
import NotfoundImage from '../assets/img/404-minified.webp'
import { changeDocumentTitle } from '../helpers/helpers'
import { UserDashboardRoutes } from '../routes/RouteNames'
const NotFound = () => {

    // change page title
    changeDocumentTitle(`Oops, page doesn't exist!`)

    return (
        <div className='h-[100vh] flex items-center justify-center px-4'>

            <div className="text-center">

                <div className="mb-4">
                    <img src={NotfoundImage} alt="404 not found" className='max-w-[700px] w-full' />
                </div>

                <div className="mb-5">
                    <h1 className="text-primary text-6xl font-bold">
                        There is Nothing here...
                    </h1>
                </div>

                <div className="mb-6">
                    <p className="text-primary text-xl">
                        You didn't the internet, but we can't find what you are looking for.
                    </p>
                </div>

                <div className="">
                    <Link to={UserDashboardRoutes().USER.SETTINGS.nav()} className="inline-flex items-center text-center justify-center bg-[#191919] hover:bg-black text-lg px-4 py-2 text-white rounded-3xl min-w-[230px]">
                        
                        <div className="mr-2">
                            Back to home
                        </div>

                        <div className="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                            </svg>
                        </div>

                    </Link>
                </div>

            </div>

        </div>
    )
}

export default NotFound
