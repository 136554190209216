import AlertSeverity from '../../components/alerts/AlertSeverity'
import Input from '../../components/inputs/input1/Input'
import SeoTextarea from '../../components/inputs/textarea1/SeoTextarea'
import Button from '../../components/buttons/button1/Button'
import SelectTag from '../../components/select-boxes/SelectTag'

const TagsForm = ({
    alertType, 
    alertVisible, 
    alertMessage, 
    hideAlert, 
    handleSubmit,
    name,
    setName,
    description,
    setDescription,
    parentId,
    setParentId,
    isLoading,

}) => {
    return (
        <>
            <div className="my-3">
                <AlertSeverity type={alertType} visible={alertVisible} message={alertMessage} onClickHide={hideAlert} />
            </div>

            <form onSubmit={handleSubmit}>

                <div className="mb-5">
                    <div className="mb-7 border-b border-slate-950">
                        <label htmlFor="" className='text-[13px] mb-5 block'>Tag name</label>
                        <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter tag name...."  />
                    </div>
                </div>

                <div className="mb-5">
                    <div className="mb-7 border-b border-slate-950">
                        <label htmlFor="" className='text-[13px] mb-5 block'>Tag name</label>
                        <SelectTag value={parentId} onChange={(e) => setParentId(e.target.value)} />
                    </div>
                </div>

                <div className="mb-5">
                    <div className="mb-7 border-b border-slate-950">
                        <label htmlFor="" className='text-[13px] mb-5 block'>Tag description</label>
                        <SeoTextarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Type-in some description here....' rows={5} />
                    </div>
                </div>

                <div className="mt-10">
                    <Button disabled={isLoading} isLoaderVisible={isLoading} />
                </div>

            </form>
        </>
    )
}

export default TagsForm
