import React, { useEffect, useState } from 'react'

const SearchBox = ({value, setValue, getValue}) => {

    const [isTyping, setIsTyping] = useState(false)
    const [isFirstRenderTyping, setIsFirstRenderTyping] = useState(true)

    useEffect(() =>
    {

        const typingTimer = setTimeout(() =>
        {
            setIsTyping(false)
            setIsFirstRenderTyping(false)
            if ( typeof getValue == 'function' && !isFirstRenderTyping ) getValue(value)
                
        }, 1*1000)

        return () => {
            clearTimeout(typingTimer)
        }

    }, [value])

    const handleKeyUp = (e) =>
    {
        setIsTyping(true)
        setValue(e.target.value)
    }

    return (
        <div>
            <div className="bg-[#F9F9F9] rounded-[20px] w-[240px] flex items-center">
                <div className="text-[#7D7D7D] mx-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M4.1 11.06a6.95 6.95 0 1 1 13.9 0 6.95 6.95 0 0 1-13.9 0zm6.94-8.05a8.05 8.05 0 1 0 5.13 14.26l3.75 3.75a.56.56 0 1 0 .8-.79l-3.74-3.73A8.05 8.05 0 0 0 11.04 3v.01z" fill="currentColor"></path></svg>
                </div>
                <input type="text" value={value} onChange={(e) => setValue(e.target.value)} onKeyUp={handleKeyUp} className='bg-transparent outline-none border-none text-[#242424] py-[10px] pr-5 pl-0 w-full text-sm placeholder:text-[#242424]' placeholder='Search....' />
            </div>
        </div>
    )
}

export default SearchBox
