
import { FormControlLabel, Switch } from "@mui/material"
import { useEffect, useState } from "react"
import AlertSeverity from "../../../components/alerts/AlertSeverity"
import useAlert from "../../../hooks/useAlert"
import useAuth from "../../../hooks/useAuth"
import usePatch from "../../../hooks/usePatch"
import useFetch from "../../../hooks/useFetch"
const SiteSettings = () => {

    const { Auth } = useAuth()
    const [maintenanceMode, setMaintenanceMode] = useState(false)
    const { response, error, isLoading, patch } = usePatch()
    const { response: fetchResponse, error: fetchError, fetch } = useFetch()
    const { message: alertMessage, visible: alertVisible, type: alertType, show: alertShow, hide: alertHide } = useAlert()

    useEffect(() => {

        if ( error )
        {
            alertShow('danger', error.message)
        }

        if ( response )
        {
            alertShow('success', "Settings have been updated!")
        }

    }, [response, error])

    useEffect(() => {

        if ( fetchResponse )
        {
            const data = fetchResponse.data

            if ( data.status !== 'maintenance' ) setMaintenanceMode(false)
            else setMaintenanceMode(true)
        }

    }, [fetchResponse, fetchError])

    useEffect(() => {

        fetch('/dashboard/site-settings')

    }, [])

    const handleMaintenanceModeChange = (e) => {
        const target = e.target
        const checked = target.checked

        var status = (checked) ? 'maintenance' : 'live'

        patch('/dashboard/site-settings', {
            status 
        })

        setMaintenanceMode(checked)
    }

    return (
        <div>

            <div className="">
                <AlertSeverity message={alertMessage} visible={alertVisible} type={alertType} onClickHide={alertHide} />
            </div>

            <div className="my-8">
                <FormControlLabel control={<Switch checked={maintenanceMode} onChange={handleMaintenanceModeChange} size="small" />} labelPlacement="start" label="Enable maintenance mode?" className={`text-primary ${ (isLoading) ? 'disabled' : '' }`} />
            </div>

        </div>
    )
}

export default SiteSettings
