import { useEffect, useState } from 'react'
import { abbreviateNumber } from '../../helpers/helpers'
import useAuth from '../../hooks/useAuth'
import useFetch from '../../hooks/useFetch'

const TopBarNotificationIcon = () => {

    const { Auth } = useAuth()
    const { response, error, isLoading, fetch } = useFetch()
    const [total, setTotal] = useState(0)

    useEffect(() => {

        // if ( error )
        // {
        //     console.log(error)
        // }

        if (response) {
            // console.log(response)
            setTotal(response.data)
        }
        
    }, [response, error])

    useEffect(() => {

        fetch(`/dashboard/users/${Auth().user.id}/activity/notifications/unreadCount`)

    }, [])

    return (
        <>
            <div className="fill-[#6B6B6B] text-[#6B6B6B] hover:fill-[#242424] hover:text-[#242424] text-sm relative">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label="Notifications"><path d="M15 18.5a3 3 0 1 1-6 0" stroke="currentColor" strokeLinecap="round"></path><path d="M5.5 10.53V9a6.5 6.5 0 0 1 13 0v1.53c0 1.42.56 2.78 1.57 3.79l.03.03c.26.26.4.6.4.97v2.93c0 .14-.11.25-.25.25H3.75a.25.25 0 0 1-.25-.25v-2.93c0-.37.14-.71.4-.97l.03-.03c1-1 1.57-2.37 1.57-3.79z" stroke="currentColor" strokeLinejoin="round"></path></svg>
                {
                    (total > 0) && (
                        <div className="absolute bg-fifth rounded-full top-[-5px] right-0 text-white font-medium text-[11px] w-4 h-4 flex items-center justify-center shadow-md">
                            { abbreviateNumber(total) }
                        </div>
                    )
                }

            </div>
        </>
    )
}

export default TopBarNotificationIcon
