import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { UserDashboardRoutes } from '../../../routes/RouteNames'
import './TabList.css'
const TabList = () => {

    const tabsRef = useRef()

    useEffect(() =>
    {

        if ( window.location.pathname == UserDashboardRoutes().USER.SETTINGS.path )
        {
            triggerTabChange('account')
        }
        else if ( window.location.pathname == UserDashboardRoutes().USER.WEBSITE.path )
        {
            triggerTabChange('website')
        }

    }, [window.location.pathname])

    const handleTabChange = (e) =>
    {
        const target = e.target
        const tab = target.closest('[role="tab"]')
        const tabs = tab.closest('[role="tabs"]')

        for(var i = 0; i < tabs.children.length; i++)
        {
            if ( tabs.children[i].getAttribute('role') )
            {
                const tab = tabs.children[i]
            
                tab.classList.remove('active')
            }
            
        }

        tab.classList.add('active')
    }

    const triggerTabChange = (tab) =>
    {
        const children = tabsRef.current.children

        for (var i = 0; i < children.length; i++)
        {
            const child = children[i]

            if ( !child.getAttribute('role') ) continue

            const tabName = child.dataset.name
            
            child.classList.remove('active')

            if ( tabName == tab )
            {
                child.classList.add('active')
            }
        }
    }
    
    return (
        <div>

            <div className="flex border-b border-[#f2f2f2] mb-9" role={'tabs'} ref={tabsRef}>

                <div className="pb-4 border-b border-transparent tab active" role={'tab'} data-name="account">
                    <Link to={UserDashboardRoutes().USER.SETTINGS.nav()} className='text-sm flex tab-link' onClick={handleTabChange}>
                        <div>Account</div>
                        <div className="w-1"></div>
                        <div></div>
                    </Link>
                </div>

                {/* <div className="w-8"></div> */}

                {/* <div className="pb-4 border-b border-transparent tab" role={'tab'} data-name="published">
                    <Link to={''} className='text-sm flex tab-link' onClick={handleTabChange}>
                        <div>Publishing</div>
                        <div className="w-1"></div>
                        <div></div>
                    </Link>
                </div> */}

                {/* <div className="w-8"></div>

                <div className="pb-4 border-b border-transparent tab" role={'tab'} data-name="responses">
                    <Link to="#" className='text-sm flex tab-link' onClick={handleTabChange}>
                        <div>Notifications</div>
                        <div className="w-1"></div>
                        <div></div>
                    </Link>
                </div> */}

                {/* <div className="w-8"></div>

                <div className="pb-4 border-b border-transparent tab" role={'tab'} data-name="responses">
                    <Link to="#" className='text-sm flex tab-link' onClick={handleTabChange}>
                        <div>Social apps</div>
                        <div className="w-1"></div>
                        <div></div>
                    </Link>
                </div> */}

                <div className="w-8"></div>

                <div className="pb-4 border-b border-transparent tab" role={'tab'} data-name="website">
                    <Link to={UserDashboardRoutes().USER.WEBSITE.nav()} className='text-sm flex tab-link' onClick={handleTabChange}>
                        <div>Website</div>
                        <div className="w-1"></div>
                        <div></div>
                    </Link>
                </div>

            </div>

        </div>
    )
}

export default TabList
