import React, { useEffect, useRef, useState } from 'react'
import useAuth from '../../../hooks/useAuth'
import usePost from '../../../hooks/usePost'
import useDelete from '../../../hooks/useDelete'
import ProfileImage from './image1/ProfileImage'

const UpdateUserImage = () => {

    const {Auth, login} = useAuth()
    const {response, error, isLoading, post} = usePost()
    const {response: delResponse, error: delError, isLoading: delIsloading, _delete} = useDelete()
    const fileInputRef = useRef()

    useEffect(() => {

        if ( error )
        {
            console.error(error.message)
        }

        if ( response )
        {
            login(response.data)
        }

    }, [response, error])

    useEffect(() => {

        // if ( delError )
        // {
        //     console.error(delError)
        // }

        if ( delResponse )
        {
            login(delResponse.data)
        }

    }, [delResponse, delError])

    const handleFile = (e) =>
    {
        const target = e.target

        const formdata = new FormData()

        formdata.append('id', Auth().user.id)
        formdata.append('avatar', target.files[0])

        post(`/dashboard/users/setAvatar`, formdata,
        {
            'Content-Type': 'multipart/form-data',
        })
    }

    const handleRemove = (e) =>
    {
        _delete(`/dashboard/users/${Auth().user.id}/removeAvatar`)
    }

    const triggerFileInput = () =>
    {
        fileInputRef.current.click()
    }

    return (
        <>
            <p className='text-sm mb-2 text-secondary'>Photo</p>

            <div className="flex">

                <div className="flex-shrink-0">
                    <label htmlFor="avatar_file_input" className='cursor-pointer'>
                        <ProfileImage url={ (Auth().user?.avatar_urls?.sizes) ? Auth().user?.avatar_urls?.sizes['80x80']: null } classes={`${ (isLoading) ? 'disabled' : '' }`} />
                        <input type="file" accept='image/*' id='avatar_file_input' className='hidden' ref={fileInputRef} onChange={handleFile} />
                    </label>
                </div>

                <div className="w-6"></div>

                <div className="">

                    <div className="flex mb-4">

                        <div className="">
                            <button className='text-sixth text-sm' onClick={triggerFileInput} disabled={isLoading}>
                                Update
                            </button>
                        </div>
                        <div className="w-4"></div>
                        <div className="">
                            <button className='text-fifth text-sm' disabled={isLoading} onClick={handleRemove}>
                                Remove
                            </button>
                        </div>

                    </div>

                    <div className="text-secondary text-[13px]">
                        Recommended: Square JPG, PNG, or GIF, at least 1,000 pixels per side.
                    </div>

                </div>
            </div>
        </>
    )
}

export default UpdateUserImage
