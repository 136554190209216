import React, { useEffect, useState } from 'react'

function SeoTextarea({
    type,
    name,
    value = '',
    onChange,
    placeholder,
    classes,
    showCharacterCount = false,
    max = 255,
    hint = '',
    rows = 3,
    cols = 3,
    emptyError = '',
}) {

    const [totalCharacters, setTotalCharacters] = useState(value.length)
    const [isInputDanger, setIsInputDanger] = useState(false)
    const [isInputEmpty, setIsInputEmpty] = useState(false)

    const normalClasses = 'text-[#000000d6]'
    const dangerClasses = 'text-fifth'

    const inputNormatClasses = 'text-primary border-[#e5e5e5] focus:border-[#242424]'
    const inputDangerClasses = 'text-fifth border-[#ffd9d9] focus:border-red-700'

    useEffect(() => {
        
        if (totalCharacters == 0) {
            setIsInputDanger(true)

            if (emptyError) {
                setIsInputEmpty(true)
            }
        }
        else if (totalCharacters > max) {
            setIsInputDanger(true)
        }
        else {
            setIsInputDanger(false)

            if (emptyError) {
                setIsInputEmpty(false)
            }
        }

    }, [totalCharacters])

    useEffect(() =>{
        setTotalCharacters(value.length)
    }, [])

    const handleInput = (e) => {
        const target = e.target

        setTotalCharacters(target.value.length)
    }

    return (
        <div className=''>

            <div className="relative">

                <div className="">
                    <textarea
                        type={type ?? 'text'}
                        name = {name}
                        value={value}
                        onChange={onChange}
                        onInput={handleInput}
                        className={`outline-none border-b placeholder:text-sm w-full px-3 py-4 text-sm ${(isInputDanger || isInputEmpty) ? inputDangerClasses : inputNormatClasses} ${classes}`}
                        placeholder={placeholder}
                        rows={rows}
                        cols={cols}
                    ></textarea>
                </div>
                {
                    (emptyError && isInputEmpty) ? (
                        <div className="absolute top-[50%] right-0 translate-y-[-50%]">
                            <svg width="25" height="25" viewBox="0 0 25 25"><path fill="#CC5454" d="M13.3 13.9l.14-7.08h-1.78l.15 7.08h1.48zm-.74 1.43c-.68 0-1.13.44-1.13 1.12 0 .67.45 1.12 1.13 1.12.68 0 1.13-.45 1.13-1.12 0-.68-.44-1.12-1.12-1.12"></path><path fill="#CC5454" d="M12.5 21a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17zm0 1a9.5 9.5 0 1 1 0-19 9.5 9.5 0 0 1 0 19z"></path></svg>
                        </div>
                    )

                    : ''
                }

            </div>
            <div className="flex justify-between mt-1">

                {
                    (emptyError && totalCharacters == 0) ? (
                        <div className="text-[13px] text-fifth">{emptyError}</div>
                    )
                        : ''
                }

                {

                    (hint) && (
                        <div className={`text-[13px] text-secondary ${ (emptyError && isInputDanger) ? 'hidden' : '' }`}>{hint}</div>
                    )
                }

                {
                    showCharacterCount && (
                        <div className={`flex text-xs`}>
                            <div className={`${(isInputDanger) ? dangerClasses : normalClasses} `}>{totalCharacters}</div>
                            <div className={`text-secondary ${(isInputDanger) ? dangerClasses : normalClasses}`}>/{max}</div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default SeoTextarea
