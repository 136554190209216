import { Navigate, Outlet, useNavigate } from "react-router-dom"
import useAuth from "../hooks/useAuth"
import { UserDashboardRoutes } from "../routes/RouteNames"

const CheckAuth = () => {
    const {isLoggedIn, Auth} = useAuth()

    if ( isLoggedIn() )
    {
        
        if ( Auth().user.role.code == 'USER' ) // redirect to user dashboard
        {
            return <Navigate to={UserDashboardRoutes().USER.PROFILE.nav(Auth().user.username)} replace />
        }
        // else if ( Auth().user.role.code == 'ADMIN' ) // redirect to admin dashboard
        // {
        //     navigate(UserDashboardRoutes().USER.PROFILE.go + Auth().user.username)
        // }
    }
    else return <Outlet/>

}

export default CheckAuth
