import Modal from './Modal'
import CancelButton from '../buttons/outlined/ButtonSuccess'
import SaveButton from '../buttons/ButtonSuccess'
import NameInput from '../inputs/input2/Input'
import BioInput from '../inputs/input2/Input'
import { useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth'
import usePatch from '../../hooks/usePatch'
import useAlert from '../../hooks/useAlert'
import AlertSeverity from '../alerts/AlertSeverity'
import UpdateUserImage from '../profile/images/UpdateUserImage'
const UpdateUserProfileInfoModal = ({
    visible,
    onHide,
}) => {
    const {Auth, login} = useAuth()
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
    const [name, setName] = useState(Auth().user.name ?? '')
    const [bio, setBio] = useState(Auth().user.bio ?? '')
    const {response, error, isLoading, patch} = usePatch()
    const {message: alertMessage, visible: alertVisible, type: alertType, show: alertShow, hide: alertHide} = useAlert()

    useEffect(() =>{

        if ( name && bio ) setIsSaveButtonDisabled(false)
        else setIsSaveButtonDisabled(true)

    }, [name, bio])

    useEffect(() => {

        if ( error )
        {
            alertShow('danger', error.message)
        }

        if ( response )
        {
            // console.log(response.data)
            login(response.data)
            alertShow('success', "Your information has been updated.")
        }

    }, [response, error])

    const handleSave = () =>
    {
        patch(`/dashboard/users/${Auth().user.id}/edit`, {
            name,
            bio,
        })
    }

    return (
        <Modal visible={visible} onHide={onHide} classes={''} contentClasses={'!block'} titleClasses={'!font-bold'} title={'Profile information'}>

            <div className="mt-10 w-full">

                <div className="mb-10">

                    <div className="mb-10">
                        <UpdateUserImage />
                    </div>

                    <div className="mb-10">
                        <label htmlFor="" className='text-sm block mb-2'>Name*</label>
                        <NameInput classes={'!py-2 !px-0'} hint={`Appears on your Profile page, as your byline, and in your responses.`} value={name} onChange={(e) => setName(e.target.value)} showCharacterCount max={50} />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className='text-sm block mb-2'>Bio</label>
                        <BioInput classes={'!py-2 !px-0'} hint={`Appears on your Profile and next to your stories.`} value={bio} onChange={(e) => setBio(e.target.value)} showCharacterCount max={160} />
                    </div>

                    <div className="">
                        <AlertSeverity message={alertMessage} visible={alertVisible} type={alertType} onClickHide={alertHide} />
                    </div>

                </div>

                <div className="flex justify-end">
                    <div className="">
                        <CancelButton text={'Cancel'} onClick={onHide} />
                    </div>
                    <div className="w-4"></div>
                    <div className="">
                        <SaveButton text={'Save'} onClick={handleSave} disabled={ (isLoading || isSaveButtonDisabled) } />
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default UpdateUserProfileInfoModal