import { Pagination } from '@mui/material'
import React from 'react'

const Table = ({ children, resultsCount, onPageChange }) => {
    return (
        <div className='bg-[#F3F6F9] px-7 py-7 rounded-xl border border-[#e5eaf2]'>

            {
                resultsCount > 1 && (
                    <div className="mb-5">
                        <Pagination count={resultsCount} size='large' shape='rounded' showFirstButton showLastButton onChange={onPageChange} />
                    </div>
                )
            }

            <table className='w-full bg-white'>
                {children}
            </table>
        </div>
    )
}

export default Table
