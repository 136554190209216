import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ConfirmDeleteModal from "../../../../components/modals/ConfirmDeleteModal"
import NotificationSeverity from "../../../../components/notifications/notification1/NotificationSeverity"
import useConfirmDeleteDialog from "../../../../hooks/useConfirmDeleteDialog"
import useDelete from "../../../../hooks/useDelete"
import useNotification from "../../../../hooks/useNotification"
import { UserDashboardRoutes } from "../../../../routes/RouteNames"

const ContextMenu = ({ story, onDelete }) => {

    const [visible, setVisible] = useState(false)
    const { title: cdmTitle, message: cdmMessage, isOpen: cdmIdOpen, show: cdmShow, hide: cdmHide } = useConfirmDeleteDialog()
    const { visible: notificationVisible, message: notificationMessage, type: notificationType, hide: hideNotification, show: showNotification } = useNotification()
    const { response, error, isLoading, _delete } = useDelete()

    useEffect(() => {

        if (error) {
            showNotification('danger', error.message)
        }

        if (response) {
            showNotification('success', 'Your story draft has been deleted.')
            cdmHide()
            if (typeof onDelete == 'function') {
                onDelete(story, response)
            }
        }

    }, [response, error])

    const toggleMenu = () => {
        setVisible(!visible)
    }

    const handleDelete = () => {
        cdmShow('Delete story draft', "Deletion is not reversible, and the story draft will be completely deleted. If you do not want to delete click cancel.")
        toggleMenu()
    }

    const confirmDeleteDialogOnConfirm = () => {
        _delete(`/dashboard/stories/${story.id}`)
    }

    return (
        <>
            <NotificationSeverity type={notificationType} message={notificationMessage} visible={notificationVisible} onClickHide={hideNotification} />
            <ConfirmDeleteModal
                title={cdmTitle}
                message={cdmMessage}
                isOpen={cdmIdOpen}
                onConfirm={confirmDeleteDialogOnConfirm}
                onCancel={cdmHide}
                isLoading={isLoading}
            />

            <div className="relative">

                {
                    visible && (
                        <div className="fixed w-full h-full z-[500] top-0 left-0" onClick={toggleMenu}></div>
                    )
                }

                <div className="relative">

                    <div className="flex items-center">
                        <button className='text-secondary' onClick={toggleMenu}>
                            <svg width="21" height="21" viewBox="0 0 21 21" className="jb"><path d="M4 7.33L10.03 14l.5.55.5-.55 5.96-6.6-.98-.9-5.98 6.6h1L4.98 6.45z" fillRule="evenodd" fill='currentColor'></path></svg>
                        </button>
                    </div>

                    {
                        visible && (
                            <div className="absolute z-[501] w-[120px] top-[28px] left-[-47px]">

                                <div className="bg-white shadow-md rounded-sm relative z-50">

                                    <ul className="list-none py-2">
                                        <li className="py-2 px-5 text-secondary text-sm">
                                            <Link to={UserDashboardRoutes().STORIES.UPDATE.nav(story.uuid)} className="flex hover:text-[#242424]">
                                                Edit draft
                                            </Link>
                                        </li>
                                        <li className="py-2 px-5 text-fifth text-sm">
                                            <button className="flex hover:text-[#242424]" onClick={handleDelete}>
                                                Delete draft
                                            </button>
                                        </li>
                                    </ul>

                                </div>

                                <div className="bg-white shadow-[rgba(0,0,0,0.05)_0px_0px_4px,rgba(0,0,0,0.15)_0px_2px_8px] w-3 h-3 absolute top-[-6px] left-[52px] z-40 rotate-[45deg]"></div>

                            </div>
                        )
                    }

                </div>

            </div>
        </>
    )
}

export default ContextMenu
