const ButtonSuccess = ({
    onClick,
    text,
    disabled = false,
    classes,
}) => {
    return (
        <div>
            <button
                className={`text-white bg-sixth border border-transparent rounded-3xl text-sm pt-2 pb-[10px] px-7 flex items-center justify-center leading-[20px] hover:bg-[#156d12] ${classes}`}
                onClick={onClick}
                disabled={disabled}
                >
                {text}
            </button>
        </div>
    )
}

export default ButtonSuccess
