import React from 'react'
import { Outlet } from 'react-router-dom'
import Topbar from '../components/partials/topbar1/Topbar'

const LayoutDashboardStats = () => {
    return (
        <div id='dashboard-main' className='h-full'>

            {/* Topbar */}
            <div className="relative z-10">
                <Topbar/>
            </div>

            <div className="container mx-auto h-full" id='dashboard-content'>
                <Outlet/>
            </div>

        </div>
    )
}

export default LayoutDashboardStats
