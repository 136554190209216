import { useEffect, useRef, useState } from "react"
import { abbreviateNumber } from "../../../../helpers/helpers"
import usePost from "../../../../hooks/usePost"
// import useCache from "../../../../hooks/useCache"

const SelectTopics = ({story, setTopics}) => {

    const tagsInputRef = useRef()
    const [isTagsInputEmpty, setIsTagsInputEmpty] = useState(true)
    const [tags, setTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const { response: postResponse, error: postError, isLoading: postIsLoading, post } = usePost()
    const [isTagsPopupVisible, setIsTagsPopupVisible] = useState(false)
    const tagsPopupRef = useRef()
    // const {put: cachePut, get: cacheGet, exists: cacheExists} = useCache()

    useEffect(() =>{

        if ( story.tags )
        {
            // story.tags.map((tag) =>
            // {
            //     handleAddTag(tag)
            // })
            setSelectedTags(story.tags)
            setTopics(story.tags)
            tagsInputRef.current.innerText = ''
        }

    }, [])

    useEffect(() => {

        if (tagsInputRef.current) {
            tagsInputRef.current.addEventListener('keyup', handleTagsInputChange)
            tagsInputRef.current.addEventListener('click', handleTagsInputClick)
        }

        return () => {
            tagsInputRef?.current?.removeEventListener('keyup', handleTagsInputChange)
            tagsInputRef?.current?.removeEventListener('click', handleTagsInputClick)
        }

    }, [isTagsInputEmpty])

    useEffect(() => {

        if (postError) {
            
        }

        if (postResponse) {
            setTags(postResponse.data)
        }

    }, [postResponse, postError])

    useEffect(() => {
        const checkIfClickedOutsideWrapper = (e) => {
            if (isTagsPopupVisible && tagsPopupRef.current && !tagsPopupRef.current.contains(e.target)) {
                setIsTagsPopupVisible(false)
            }
        }

        document.addEventListener('click', checkIfClickedOutsideWrapper)

        return () => {

            document.removeEventListener('click', checkIfClickedOutsideWrapper)
        }

    }, [isTagsPopupVisible])

    // useEffect(() =>
    // {

    //     // check if there are no selected tags
    //     if ( selectedTags.length == 0 )
    //     {
    //         setIsTagsInputEmpty(true)
    //     }

    // }, [selectedTags])

    const handleTagsInputChange = (e) => {
        const target = e.target
        const value = target.innerText
        
        if (!value) {
            setIsTagsInputEmpty(true)
        }
        else setIsTagsInputEmpty(false)
        
        if ( checkTagsInputHasPlaceholder() ) return

        post('/dashboard/tags/search', {
            searchTerm: value,
            withCount: ['stories'],
            offset: 0,
            limit: 6,
        })
        
        setIsTagsPopupVisible(true)
    }

    const handleTagsInputClick = (e) => {
        const target = e.target
        const value = target.innerText

        if (value) {
            setIsTagsInputEmpty(false)
        }
    }

    const checkTagsInputHasPlaceholder = () => {
        const tags_input_placeholder = tagsInputRef.current.querySelector('#tags_input_placeholder')

        if (tags_input_placeholder) return true

        return false
    }

    const handleAddTag = (tag) => {

        setIsTagsPopupVisible(false)

        // check if max 5
        if ( selectedTags.length == 5 )
        {
            return
        }

        if (selectedTags.some((v) => v.id == tag.id)) {
            return
        }

        const newTags = [...selectedTags, tag]

        setSelectedTags(newTags)
        setTopics(newTags)

        tagsInputRef.current.innerText = ''
    }

    const handleRemoveTag = (tag) => {

        const filtered = selectedTags.filter((v) =>
        {
            if ( v.id != tag.id )
            {
                return v
            }
        })

        setSelectedTags(filtered)
        setTopics(filtered)
    }

    return (
        <div className='border border-[#00000026] bg-[#fafafa] pt-[6px] pl-[10px] relative'>

            <div className="min-h-[37px] flex items-center relative">

                <div className="text-[15px]">

                    {
                        selectedTags && selectedTags.map((tag, i) => {
                            return (
                                <div key={i} className="mr-2 mb-2 inline-block relative">
                                    <button className='pr-[24px] text-[#000] text-sm bg-white rounded-[3px] border border-[#f0f0f0] py-[5px] px-[10px] inline-block select-none box-border cursor-pointer hover:border-[#0000004d]'>
                                        {tag.name}
                                    </button>
                                    <button className="absolute top-0 right-0 py-[5px] px-2 text-[#0000008a] hover:text-[#000000d6]" onClick={() => handleRemoveTag(tag)}>&times;</button>
                                </div>
                            )
                        })
                    }

                    <div className="text-[#000000d6] py-[10px] min-w-[100px] inline-block rounded-[4px] mb-[5px] cursor-text outline-none relative" suppressContentEditableWarning contentEditable data-default-value="Add a topic…" ref={tagsInputRef} >
                        
                            <span id="tags_input_placeholder" className='absolute text-[#0000008a] select-none text-[13px] inline-block pointer-events-none'>Add a topic...</span>
                        
                    </div>

                </div>

                {
                    (tags && isTagsPopupVisible) ? (
                        <div className="absolute max-w-[100%] min-w-[160px] top-[75%] left-[-25px] text-sm p-[15px]" ref={tagsPopupRef}>

                            <div className="relative bg-white shadow-[0_1px_2px_rgba(0,0,0,.25),0_0_1px_rgba(0,0,0,.35)] z-10">

                                <ul className="list-none">

                                    {
                                        tags.map((tag, tagIndex) => {
                                            return (
                                                <li key={tag.id} className="text-sm cursor-pointer py-[10px] px-[10px] whitespace-nowrap overflow-ellipsis overflow-hidden flex hover:bg-[#1a8917] hover:text-white group" onClick={() => handleAddTag(tag)}>
                                                    <div className="font-semibold">{tag.name}</div>
                                                    <div className="w-1"></div>
                                                    <div className="text-[#0000008a] group-hover:text-white">({ abbreviateNumber(tag.stories_count) })</div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                            </div>

                        </div>
                    )
                        : ''
                }

            </div>

        </div>
    )
}

export default SelectTopics
