import { useState } from "react"

const useAlert = () => {
    const [type, setType] = useState('danger')
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState('')

    const show = (type, message) =>
    {
        setType(type)
        setMessage(message)
        setVisible(true)
    }

    const hide = () =>
    {
        setMessage('')
        setVisible(false)
    }

    return { type, visible, message, show, hide }

}

export default useAlert
