import { AuthRoutes } from "../../../routes/RouteNames"
import logo from '../../../assets/img/logo/logo.png'
import { Link } from "react-router-dom"
import useAuth from "../../../hooks/useAuth"
import ProfileDropDown from "../../../components/profile/profile-dropdown1/ProfileDropDown"
const StoryUpdateSidebar = ({isLoading = true, handlePublish}) => {

    const { Auth } = useAuth()

    return (
        <div>

            <div className="max-w-[1032px] mx-auto px-5 py-3 grid md:grid-cols-2 gap-y-3">

                <div className="">

                    <div className="flex items-center">

                        <div className="">
                            <Link to={AuthRoutes().USER.PROFILE}>
                                <img src={logo} className="w-10" alt="" />
                            </Link>
                        </div>

                        <div className="w-3"></div>

                        <div className="text-sm text-[#000000d6] font-medium flex items-center">
                            <div className="">Draft in {Auth().user.name}</div>
                            <div className="w-5"></div>
                            {
                                isLoading ? (<div className="font-light text-secondary">Saving...</div>)
                                : (<div className="font-light text-secondary">Saved</div>)
                            }
                            
                        </div>

                    </div>

                </div>

                <div className="flex md:justify-end justify-center">

                    <div className="flex items-center">

                        <div className="">
                            <button 
                                className={`bg-sixth text-white text-sm py-1 min-w-[100px] hover:bg-[#0f730c] rounded-3xl ${isLoading ? 'disabled' : ''}`}
                                onClick={handlePublish}>
                                Publish
                            </button>
                        </div>

                        <div className="w-5"></div>

                        <div className="">
                            <Link to=''>
                                <div className="fill-[#6B6B6B] text-[#6B6B6B] hover:fill-[#242424] hover:text-[#242424] text-sm">
                                    <svg className="svgIcon-use" width="25" height="25" viewBox="-293 409 25 25"><path d="M-273.327 423.67l-1.673-1.52v-3.646a5.5 5.5 0 00-6.04-5.474c-2.86.273-4.96 2.838-4.96 5.71v3.41l-1.68 1.553c-.204.19-.32.456-.32.734V427a1 1 0 001 1h3.49a3.079 3.079 0 003.01 2.45 3.08 3.08 0 003.01-2.45h3.49a1 1 0 001-1v-2.59c0-.28-.12-.55-.327-.74zm-7.173 5.63c-.842 0-1.55-.546-1.812-1.3h3.624a1.92 1.92 0 01-1.812 1.3zm6.35-2.45h-12.7v-2.347l1.63-1.51c.236-.216.37-.522.37-.843v-3.41c0-2.35 1.72-4.356 3.92-4.565a4.353 4.353 0 014.78 4.33v3.645c0 .324.137.633.376.85l1.624 1.477v2.373z"></path></svg>
                                </div>
                            </Link>
                        </div>

                        <div className="w-5"></div>

                        <div className="">

                            <ProfileDropDown />

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default StoryUpdateSidebar
