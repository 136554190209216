import { useEffect, useRef, useState } from "react"
import Editor from "../../components/editorjs/editor1/Editor"
import { changeDocumentTitle } from "../../helpers/helpers"
import StoryUpdateSidebar from "./sidebar/StoryUpdateSidebar"
import useNotification from '../../hooks/useNotification'
import NotificationSeverity from "../../components/notifications/notification1/NotificationSeverity"
import useAuth from "../../hooks/useAuth"
import useFetch from "../../hooks/useFetch"
import { useParams } from "react-router-dom"
import usePatch from "../../hooks/usePatch"
import SeoTextarea from "../../components/inputs/textarea2/SeoTextarea"
import PublishStoryModal from "../../components/modals/PublishStoryModal"
import usePublishStoryModal from "../../hooks/usePublishStoryModal"
import SelectMediaModal from "../../components/modals/SelectMediaModal"
import useModal from "../../hooks/useModal"
const StoryUpdate = () => {
    // change page title
    changeDocumentTitle('New story')

    const { Auth } = useAuth()
    const { uuid } = useParams()
    // const [story, setStory] = useState({})
    const [title, setTitle] = useState('')
    const { isLoading, response, error, patch } = usePatch()
    const { isLoading: isFetchLoading, response: fetchResponse, error: fetchError, fetch } = useFetch()
    const { message: notificationMessage, visible: notificationVisible, type: notificationType, show: showNotification, hide: hideNotification } = useNotification()
    const [editorjs, setEditorJs] = useState(null)
    const editorJsWrapper = useRef()
    const ceBlocks = useRef()
    const ceActiveBlock = useRef()
    const [editorUpdates, setEditorUpdates] = useState(0)
    const [story, setStory] = useState({})
    const {visible: publishStoryModalvisible, show: publishStoryModalShow, hide: publishStoryModalHide} = usePublishStoryModal()
    const { visible: smmVisible, show: smmShow, hide: smmHide } = useModal()
    const saveDraftButtonRef = useRef()

    const storyParams = {
        title: title,
        user_id: Auth().user.id,
        status: 'draft',
    }

    useEffect(() =>
    {

        if ( error )
        {
            console.log(error)
            showNotification('danger', error.message)
        }

        if ( response )
        {
            // showNotification('success', 'Your story has been created.')
        }

    }, [response, error])

    useEffect(() => {

        if (fetchError) {
            showNotification('danger', fetchError.message)
        }

        if (fetchResponse) {
            setStory(fetchResponse.data)
            fillForm(fetchResponse.data)
        }

    }, [fetchResponse, fetchError])

    useEffect(() => {

        // fetch story
        fetch(`/dashboard/stories/${uuid}`)

    }, [])

    useEffect(() => {

        const handleCEBlockClick = async (e) => {
            const target = e.target
            const ceBlock = target.closest('.ce-block')
            const img = ceBlock.querySelector('.image-tool__image img')

            ceActiveBlock.current = ceBlock

            if ( img ) smmShow()
        }

        if ( editorJsWrapper.current )
        {
            ceBlocks.current = editorJsWrapper.current.querySelectorAll('.ce-block')
        }

        if ( ceBlocks.current )
        {
            ceBlocks.current.forEach(el => {
                
                el.addEventListener('dblclick', handleCEBlockClick)

            })
        }

        return () => {

            if ( ceBlocks.current )
            {
                ceBlocks.current.forEach(el => {
                    
                    el.removeEventListener('dblclick', handleCEBlockClick)

                })
            }

        }
        
    }, [editorJsWrapper.current, editorUpdates])

    const saveStory = async () => {

        if ( !title ) return true

        if (title == '') return

        if (isLoading) return

        if ( editorjs )
        {
            var editorData = await editorjs.save()
            // check has blocks
            if ( editorData.blocks.length > 0 )
            {
                storyParams.content = editorData
            }
        }

        patch(`/dashboard/stories/${story.id}`, storyParams)
    }

    const handlePublish = () =>
    {
        publishStoryModalShow(story)
    }

    const handleOnPublish = (e) => {

        storyParams.status = 'publish'
        saveStory()
    }

    const handleEditorChange = (api, e) =>
    {
        if ( api.blocks.getCurrentBlockIndex() == -1 ) return

        saveDraftButtonRef.current.click()
    }

    const fillForm = (data) =>
    {
        setTitle(data.title)
        
        if ( data.content )
        {
            if ( data.content.blocks.length > 0 )
            {
                if ( typeof editorjs.render == 'undefined' ) return
                editorjs.render({
                    blocks: data.content.blocks 
                })
                
                editorjs.isReady.then(() => {
                    setEditorUpdates(editorUpdates + 1)
                })
            }
        }
        // 
    }

    const handleSaveDraftButtonClick = (e) => {
        e.stopPropagation()
        
        saveStory()
        setEditorUpdates(editorUpdates + 1)
    }

    const setBlockImage = async (image) => {
        const ceBlockIndex = Array.prototype.indexOf.call(ceBlocks.current, ceActiveBlock.current)

        const img = ceActiveBlock.current.querySelector('.image-tool__image img')
        var editorData = await editorjs.save()

        if ( img )
        {
            const block = editorData.blocks[ceBlockIndex]
      
            block.data.file.url = image.url

            // sizes
            if ( image?.urls?.sizes )
            {
                block.data.file.sizes = {}
                block.data.file.sizes.urls = image?.urls?.sizes
                block.data.file.sizes.paths = image?.paths
            }

            editorData.blocks[ceBlockIndex] = block

            var storyContent = story.content

            storyContent.blocks = editorData.blocks

            editorjs.render(storyContent)

            saveStory()
        }
    }

    const handleOnMedia = (image) => {

        setBlockImage(image)
    }

    return (
        <div className="">

            <button className="hidden" ref={saveDraftButtonRef} onClick={handleSaveDraftButtonClick}></button>

            <SelectMediaModal classes="!z-[1100]" contentClasses="max-w-[900px] !block" visible={smmVisible} onHide={smmHide} onMedia={handleOnMedia} />

            <PublishStoryModal visible={publishStoryModalvisible} handleHide={publishStoryModalHide} story={story} onPublish={handleOnPublish} />

            <div className="">
                <StoryUpdateSidebar isLoading={isLoading} handlePublish={handlePublish} />
            </div>

            <main>

                <section className="mt-12">

                    <div className="px-5 max-w-[740px] mx-auto">

                        <div className="">
                            <NotificationSeverity type={notificationType} visible={notificationVisible} message={notificationMessage} onClickHide={hideNotification} />
                        </div>

                        <div className="mb-4">
                            <SeoTextarea placeholder="Title" value={title} setValue={setTitle} onChange={(e) => setTitle(e.target.value)} rows={2} max={50} />
                        </div>

                        <div className="" ref={editorJsWrapper}>
                            <Editor setEditor={setEditorJs} onChange={handleEditorChange} />
                        </div>

                    </div>

                </section>

            </main>

        </div>
    )
}

export default StoryUpdate
