// import { useEffect, useState } from "react"

// const CheckableBox = ({
//     children,
//     classes = '',
//     onChecked,
//     onUnchecked,
//     data,
// }) => {

//     const [isChecked, setIsChecked] = useState(false)

//     const checkedClasses = 'ring-orange-300 is-checked'
//     const uncheckedClasses = 'ring-transparent'

//     useEffect(() =>{

//         if ( isChecked )
//         {
//             if ( typeof onChecked == 'function' )
//                 onChecked(data)
//         }
//         else
//         {
//             if ( typeof onUnchecked == 'function' )
//                 onUnchecked(data)
//         }

//     }, [isChecked])

//     const handleCheck = () =>
//     {
//         setIsChecked(!isChecked)
//     }

//     return (
//         <div className={`cursor-pointer p-2 rounded-sm border border-[#e5e5e5] ring-2 ${ (isChecked) ? checkedClasses : uncheckedClasses } ${classes}`} onClick={handleCheck}>
//             {children}
//         </div>
//     )
// }

// export default CheckableBox

import { useEffect, useState } from "react"

const CheckableBox = ({
    children,
    dataId = '',
    classes = '',
    isChecked,
    onClick,
}) => {

    const checkedClasses = 'ring-orange-300 is-checked'
    const uncheckedClasses = 'ring-transparent'

    return (
        <div data-id={dataId} className={`cursor-pointer p-2 rounded-sm border border-[#e5e5e5] ring-2 ${ (isChecked) ? checkedClasses : uncheckedClasses } ${classes}`} onClick={onClick}>
            {children}
        </div>
    )
}

export default CheckableBox
