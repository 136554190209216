import React, { useEffect, useRef } from 'react';
import { app } from '../../../helpers/app'
import './Editor.css'
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import Checklist from '@editorjs/checklist';
import List from "@editorjs/list";
import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table'
import CodeTool from '@editorjs/code';
import Warning from '@editorjs/warning';
import Delimiter from '@editorjs/delimiter';
import NestedList from '@editorjs/nested-list';
import Alert from 'editorjs-alert'

const Editor = ({ setEditor, data, onChange, holder = 'editorjs' }) => {

    const editorRef = useRef()
    let editor = { isReady: false };

    useEffect(() => {
      
        // if ( editorRef.current === null || editorRef.current == undefined )
        // {
        //     initEditorjs()
        // }

        if ( !editor.isReady )
        {
            initEditorjs()
        }

        // return () =>
        // {
        //     // console.log(editorRef?.current)
        //     editorRef?.current?.destroy()
        //     editorRef.current = undefined
        // }

    }, [])

    const initEditorjs = () => {
        editor = new EditorJS({
            holder: holder,
            onReady: () => {

                editorRef.current = editor

                // render new data
                if ( data )
                {
                    editor.render(data)
                }
                // set editor
                if (typeof setEditor == 'function') {
                    setEditor(editor)
                }
            },
            onChange: (api, e) =>
            {
                onChange(api, e)
            },
            
            tools: {
                header: {
                    class: Header,
                    shortcut: 'CMD+SHIFT+H',
                    config: {
                        placeholder: 'Enter a header',
                        levels: [2, 3, 4],
                    },
                    inlineToolbar: true,
                },
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: `${app().API_END_POINT}/media`,
                            // byUrl: `${app().API_END_POINT}/media/uploadByUrl`,
                        },
                        field: 'file'
                    },
                },
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    }
                },
                embed: {
                    class: Embed,
                    inlineToolbar: true
                },
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+O',
                    config: {
                        quotePlaceholder: 'Enter a quote',
                        captionPlaceholder: 'Quote\'s author',
                    },
                },
                table: {
                    class: Table,
                    inlineToolbar: true,
                    config: {
                        rows: 2,
                        cols: 3,
                    },
                },
                code: CodeTool,
                warning: {
                    class: Warning,
                    inlineToolbar: true,
                    config: {
                        titlePlaceholder: 'Title',
                        messagePlaceholder: 'Message',
                    },
                },
                delimiter: Delimiter,
                list: {
                    class: NestedList,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    },
                },
                alert: {
                    class: Alert,
                    inlineToolbar: true,
                    config: {
                        defaultType: 'light',
                        messagePlaceholder: 'Enter something',
                    },
                },
            }
        });

    }

    return (
        <div id={holder}></div>
    )
}

export default Editor
