import { useEffect, useState } from "react"
import useFetch from "../../../hooks/useFetch"
import Placeholder from '../../../components/placeholders/placeholder1/Placeholder'
import { formatReadTime, getDatetimeDifference, summarizeText } from "../../../helpers/helpers"
import Table from '../../../components/tables/table1/Table'
import TBodyItem from "../../../components/tables/table1/TBodyItem"
const CommentsLatestList = ({
    total,
}) => {

    const { response, error, isLoading, fetch } = useFetch()
    const [comments, setComments] = useState(null)

    useEffect(() => {

        // if ( error )
        // {
        //     console.log(error)
        // }

        if (response) {
            setComments(response.data)
        }

    }, [response, error])

    useEffect(() => {

        fetch(`/dashboard/comments/latest/${total}`)

    }, [])

    return (
        <div>

            <table className="w-full bg-white">
                <tbody>

                    {
                        isLoading && Array.from([1, 2, 3, 4]).map((v, k) => {
                            return (
                                <tr key={k}>
                                    <TBodyItem className="!border-b-0">
                                        <div className="">

                                            <div className="flex items-center mb-[5px]">
                                                <div className="mr-3">
                                                    <Placeholder className={'w-8 h-8 box-border rounded-full'} />
                                                </div>
                                                <div className="">
                                                    <div className="flex items-center">
                                                        <Placeholder className={'!block h-2 box-border !rounded-sm'} />
                                                    </div>
                                                    <div className="text-xs text-secondary leading-[20px]">
                                                        <Placeholder className={'w-8 h-2 box-border !rounded-sm'} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="mb-1">
                                                    <Placeholder className={'!block h-2 box-border !rounded-sm'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'!block h-2 box-border !rounded-sm'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'!block h-2 box-border !rounded-sm'} />
                                                </div>
                                                <div className="mb-1">
                                                    <Placeholder className={'!block h-2 box-border !rounded-sm'} />
                                                </div>
                                            </div>

                                        </div>
                                    </TBodyItem>
                                </tr>
                            )
                        })
                    }

                    {
                        comments && comments.map((comment, commentIndex) => {
                            return (
                                <tr key={comment.id}>
                                    <TBodyItem className="!border-b-0">
                                        <div className="">

                                            <div className="flex items-center mb-[5px]">
                                                <div className="mr-3">
                                                    <img src={comment.avatar} className="w-8 h-8 box-border rounded-full bg-forth" alt="" />
                                                </div>
                                                <div className="">
                                                    <div className="flex items-center">
                                                        <p className="text-primary text-sm font-medium">{comment.name}</p>
                                                    </div>
                                                    <div className="text-xs text-secondary leading-[20px]">
                                                        {getDatetimeDifference(comment.created_at).auto}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="" dangerouslySetInnerHTML={{ __html: summarizeText(comment.content, 140) }}></div>

                                        </div>
                                    </TBodyItem>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

        </div>
    )
}

export default CommentsLatestList
