import { useEffect, useState } from "react"
import useFetch from "../../../hooks/useFetch"
import Placeholder from '../../../components/placeholders/placeholder1/Placeholder'
import { formatReadTime, getDatetimeDifference, summarizeText } from "../../../helpers/helpers"
const StoriesLatestList = ({
    total,
}) => {

    const {response, error, isLoading, fetch} = useFetch()
    const [stories, setStories] = useState(null)

    useEffect(() => {

        // if ( error )
        // {
        //     console.log(error)
        // }

        if ( response )
        {
            setStories(response.data)
        }

    }, [response, error])

    useEffect(() => {

        fetch(`/dashboard/stories/latest/${total}`)

    }, [])
    
    return (
        <div>

            {
                isLoading && Array.from([1,2,3,4]).map((v,k) => {
                    return (
                        <div className="mb-4" key={k}>

                            <div className="mb-3">
                                <Placeholder className={'!block h-3 !rounded-sm'} />
                            </div>

                            <div className="flex items-center">
                                
                                <div className="">
                                    <Placeholder className={'w-10 h-1 !rounded-sm'} />
                                </div>

                            </div>

                        </div>
                    )
                })
            }

            {
                stories && stories.map((story, storyIndex) => {
                    return (
                        <div className="mb-3" key={story.id}>

                            <div className="mb-3">
                                <a href={story.urls.canonical} target={'_blank'}>
                                    <h2 className="text-primary text-md font-bold">
                                        { summarizeText(story.title, 50) }
                                    </h2>
                                </a>
                            </div>

                            <div className="flex items-center text-secondary text-[13px]">

                                <div className="">
                                    { formatReadTime(story.read_time * 60) }
                                </div>

                                <div className="mx-1">.</div>

                                <div className="">
                                    { getDatetimeDifference(story.created_at).auto }
                                </div>

                            </div>

                        </div>
                    )
                })
            }

        </div>
    )
}

export default StoriesLatestList
