import React, { useEffect, useState } from 'react'
import usePost from '../../../hooks/usePost'
import Placeholder from '../../placeholders/placeholder1/Placeholder'

const RecommendedTags = () => {

    const [tags, setTags] = useState(null)
    const { post, response, error, isLoading } = usePost()

    useEffect(() => {

        if (response) {
            setTags(response.data)
        }

    }, [response, error])

    useEffect(() => {
        post('/dashboard/tags/search', {
            searchTerm: '',
            offset: 0,
            limit: 7
        })
    }, [])

    return (
        <div>

            {
                isLoading && (
                    Array.from(['', '', '', '']).map((v, i) => {
                        return (
                            <div key={i} className="bg-[#F2F2F2] border border-[#f2f2f2] py-2 px-8 text-center rounded-[100px] inline-flex flex-col mb-2 mr-2 cursor-pointer hover:bg-[#faebd7]">
                                <div className="mb-1 text-sm">
                                    <Placeholder className={'h-1'} />
                                </div>
                                <div className="text-xs font-thin">
                                    <Placeholder className={'h-1'} />
                                </div>
                            </div>
                        )
                    })
                )
            }
            {
                tags ? (
                    <div className="">

                        {
                            tags.map((tag, tagIndex) => {
                                return (
                                    <div key={tag.id} className="bg-[#F2F2F2] border border-[#f2f2f2] py-2 px-8 text-center rounded-[100px] inline-flex flex-col mb-2 mr-2 cursor-pointer hover:bg-[#faebd7]">
                                        <div className="mb-1 text-sm">{tag.name}</div>
                                        <div className="text-xs font-thin">{tag.slug}</div>
                                    </div>
                                )
                            })
                        }

                    </div>
                )
                    : <p className='text-sm font-light my-2'>There are no tags available!</p>
            }
        </div>
    )
}

export default RecommendedTags
