import { Link } from "react-router-dom"
import logo from '../../../assets/img/logo/logo.png'
import SearchBox from "../../searchbox/searchbox1/SearchBox"
import { AuthRoutes, UserDashboardRoutes } from "../../../routes/RouteNames"
import ProfileDropDown from "../../profile/profile-dropdown1/ProfileDropDown"
import TopBarNotificationIcon from "../../notifications/TopBarNotificationIcon"
import useAuth from "../../../hooks/useAuth"
const Topbar = () => {

    const {Auth} = useAuth()

    return (
        <div id="topbar">

            <div className="md:justify-between md:py-0 md:text-center md:h-[57px] flex items-center px-6 py-4 border-b border-[#f2f2f2] flex-wrap gap-y-2 justify-center">

                <div className="">

                    <div className="flex">

                        <div className="">
                            <Link to={AuthRoutes().USER.PROFILE}>
                                <img src={logo} className="w-10" alt="" />
                            </Link>
                        </div>

                        <div className="w-4"></div>

                        <div className="text-sm text-secondary text-left">
                            
                            <Link to={UserDashboardRoutes().USER.SETTINGS.nav()}>
                                <div className="">{ Auth().user.name }</div>
                                <div className="text-xs">{ Auth().user.username }</div>
                            </Link>

                        </div>

                    </div>

                </div>

                <div className="flex items-center">

                    <div className="flex">

                        <div className="">
                            <Link to={UserDashboardRoutes().STORIES.CREATE.nav()}>
                                <div className="flex items-center text-sm fill-[#6B6B6B] text-[#6B6B6B] hover:fill-[#242424] hover:text-[#242424]">
                                    <div className="">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label="Write"><path d="M14 4a.5.5 0 0 0 0-1v1zm7 6a.5.5 0 0 0-1 0h1zm-7-7H4v1h10V3zM3 4v16h1V4H3zm1 17h16v-1H4v1zm17-1V10h-1v10h1zm-1 1a1 1 0 0 0 1-1h-1v1zM3 20a1 1 0 0 0 1 1v-1H3zM4 3a1 1 0 0 0-1 1h1V3z" fill="currentColor"></path><path d="M17.5 4.5l-8.46 8.46a.25.25 0 0 0-.06.1l-.82 2.47c-.07.2.12.38.31.31l2.47-.82a.25.25 0 0 0 .1-.06L19.5 6.5m-2-2l2.32-2.32c.1-.1.26-.1.36 0l1.64 1.64c.1.1.1.26 0 .36L19.5 6.5m-2-2l2 2" stroke="currentColor"></path></svg>
                                    </div>
                                    <div className="ml-1">Write</div>
                                </div>
                            </Link>
                        </div>

                    </div>

                    <div className="w-[32px]"></div>

                    <div className="">
                        <Link to={UserDashboardRoutes().USER.ACTIVITY.NOTIFICATIONS.INDEX.nav()}>
                            <TopBarNotificationIcon />
                        </Link>
                    </div>

                    <div className="w-[32px]"></div>

                    <div className="">

                        <ProfileDropDown />

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Topbar
