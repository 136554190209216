import {useCallback, useState } from 'react'
import axios from '../api/axios'
import useAuth from './useAuth'
const usePatch = () => {
    
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(null)
    const [error, setError] = useState(null)
    const { Auth } = useAuth()

    const patch = useCallback(async (url, params, headers) =>
    {
        const defaultHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-TOKEN': `${Auth()?.user?.uuid}`
        }
        
        headers = {...defaultHeaders, ...headers}
    
        try 
        {
            setIsLoading(true)
            setResponse(null)
            setError(null)
            const res = await axios({
                url: url,
                method: 'PATCH',
                data: params,
                headers: headers,
            })
            setResponse(res)
        } catch (error) {
            setError(error?.response?.data)
            setIsLoading(false)
        }
        
        setIsLoading(false)

    }, [])

    return {isLoading, response, error, patch}

}

export default usePatch

