import React from 'react'
import Loader from '../../loaders/loader1/Loader'

const Button = ({isLoaderVisible, disabled, text = 'Continue'}) => {
    return (
        <div className="flex">
            <div className="flex items-center justify-center">
                <button type='submit' disabled={disabled} className='bg-[#191919] hover:bg-black text-sm px-4 py-2 text-white rounded-3xl w-[226px]'>
                    {text}
                </button>
            </div>
            <div className="w-2"></div>
            <div className="">
                <Loader visible={isLoaderVisible} />
            </div>
        </div>
    )
}

export default Button
