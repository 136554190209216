import useAuth from "../hooks/useAuth";

export const DashboardRoutes = () => {

    return {
        LOGIN: {
            path: '/',
            nav: '/',
        },
    };
};

export const UserDashboardRoutes = () => {
    return {
        USER: {
            PROFILE: {
                path: '/:username',
                nav: (username) =>
                {
                    return `/${username}`
                }
            },
            INDEX: {
                path: '/me',
                nav: () =>
                {
                    return '/me'
                }
            },
            SETTINGS: {
                path: '/me/settings',
                nav: () =>
                {
                    return `/me/settings`
                }
            },
            WEBSITE: {
                path: '/me/website',
                nav: () =>
                {
                    return `/me/website`
                }
            },
            ACTIVITY: {
                NOTIFICATIONS: {
                    INDEX: {
                        path: '/activity/notifications',
                        nav: () => {
                            return '/activity/notifications'
                        }
                    }
                }
            }
        },
        TAGS: {
            CREATE: {
                path: '/tags/create',
                nav: () =>
                {
                    return `/tags/create`
                }
            },
            UPDATE: {
                path: '/tags/:id/edit',
                nav: (id) =>
                {
                    return `/tags/${id}/edit`
                }
            },
            INDEX: {
                path: '/tags',
                nav: () =>
                {
                    return `/tags`
                }
            }
        },
        STORIES: {
            CREATE: {
                path: '/stories/new-story',
                nav: () =>
                {
                    return `/stories/new-story`
                }
            },
            UPDATE: {
                path: '/stories/:uuid/edit',
                nav: (uuid) =>
                {
                    return `/stories/${uuid}/edit`
                }
            },
            INDEX: {
                path: '/stories',
                nav: () =>
                {
                    return `/stories`
                }
            },
            DRAFTS: {
                path: '/stories/drafts',
                nav: () =>
                {
                    return `/stories/drafts`
                }
            },
            PUBLIC: {
                path: '/stories/public',
                nav: () =>
                {
                    return `/stories/public`
                }
            },
            RESPONSES: {
                INDEX: {
                    path: '/stories/responses',
                    nav: () =>
                    {
                        return `/stories/responses`
                    }
                },
                SHOW: {
                    path: '/response/:uuid/:id?',
                    nav: (uuid, id = '') =>
                    {
                        return `/response/${uuid}/${id}`
                    }
                },
            },
            SHOW: {
                path: '/story/:slug',
                nav: (slug) =>
                {
                    return `/story/${slug}`
                }
            },
        },
        STATS: {
            INDEX: {
                path: '/statistics',
                nav: () => {
                    return '/statistics'
                }
            }
        }
    };
};

export const AuthRoutes = () => {

    const { Auth } = useAuth()

    return {
        USER: {
            PROFILE: UserDashboardRoutes().USER.PROFILE.nav(Auth().user.username)
        }
    }
}