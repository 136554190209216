import useAuth from "../../../hooks/useAuth"
import UserPlaceholder from '../../../assets/img/male.png'
import { Link, useNavigate } from "react-router-dom"
import { hideEmail } from "../../../helpers/helpers"
import { useEffect, useRef, useState } from "react"
import {DashboardRoutes, UserDashboardRoutes} from '../../../routes/RouteNames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from "@fortawesome/free-solid-svg-icons"
import ProfileImage from '../../profile/images/image1/ProfileImage'
const ProfileDropDown = () => {

    const { Auth, logout } = useAuth()
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const wrapperRef = useRef()

    useEffect(() =>
    {

        const checkIfClickedOutsideWrapper = (e) =>
        {
            if ( visible && wrapperRef.current && !wrapperRef.current.contains(e.target) )
            {
                setVisible(false)
            }
        }

        document.addEventListener('click', checkIfClickedOutsideWrapper)

        return () =>
        {
           
            document.removeEventListener('click', checkIfClickedOutsideWrapper)
        }

    }, [visible])

    // useEffect(() => {

    //     if ( window.location.pathname == UserDashboardRoutes().STORIES.DRAFTS.path )
    //     {
    //         // triggerTabChange('drafts')
    //     }

    // }, [window.location.pathname])

    const toggleDropDown = () => {
        setVisible(!visible)
    }

    const signout = (e) =>
    {
        e.preventDefault()
        
        logout()

        navigate(DashboardRoutes().LOGIN.nav)
    }

    return (
        <div ref={wrapperRef}>

            <div className="relative">

                <button type="button" className="flex items-center justify-center" onClick={toggleDropDown}>
                    <div className="relative rounded-full overflow-hidden group">
                        <ProfileImage url={ (Auth().user?.avatar_urls?.sizes) ? Auth().user?.avatar_urls?.sizes['32x32']: null } />
                        <div className="absolute w-full h-full top-0 left-0 bg-[#0000001a] opacity-0 group-hover:opacity-100"></div>
                    </div>
                </button>

                {
                    visible && (
                        <div className={`absolute z-50 right-[-48px] translate-x-[-24px] translate-y-[13px] rounded-[4px] box-border shadow-[rgb(0_0_0_/_5%)_0px_0px_4px,rgba(0,0,0,0.15)_0px_2px_8px]`}>

                            <div className="max-h-[392px] overflow-x-hidden overflow-y-auto bg-white">

                                <div className="w-[264px]">

                                    <div className="border-b border-[#f2f2f2] py-4">

                                        <Link to={UserDashboardRoutes().TAGS.INDEX.nav()}>
                                            <div className="py-2 px-6 text-[#6B6B6B] hover:text-[#242424] text-sm ">
                                                <div className="flex items-center">
                                                    <div className="">
                                                        <FontAwesomeIcon icon={faTags} className='text-[20px]' />
                                                    </div>
                                                    <div className="ml-4">
                                                        Tags
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link to=''>
                                            <div className="py-2 px-6 text-[#6B6B6B] hover:text-[#242424] text-sm ">
                                                <div className="flex items-center">
                                                    <div className="">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label="Lists"><path d="M6.44 6.69h0a1.5 1.5 0 0 1 1.06-.44h9c.4 0 .78.16 1.06.44l.35-.35-.35.35c.28.28.44.66.44 1.06v14l-5.7-4.4-.3-.23-.3.23-5.7 4.4v-14c0-.4.16-.78.44-1.06z" stroke="currentColor"></path><path d="M12.5 2.75h-8a2 2 0 0 0-2 2v11.5" stroke="currentColor" strokeLinecap="round"></path></svg>
                                                    </div>
                                                    <div className="ml-4">
                                                        Library
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link to={UserDashboardRoutes().STORIES.INDEX.nav()}>
                                            <div className="py-2 px-6 text-[#6B6B6B] hover:text-[#242424] text-sm ">
                                                <div className="flex items-center">
                                                    <div className="">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label="Stories"><path d="M4.75 21.5h14.5c.14 0 .25-.11.25-.25V2.75a.25.25 0 0 0-.25-.25H4.75a.25.25 0 0 0-.25.25v18.5c0 .14.11.25.25.25z" stroke="currentColor"></path><path d="M8 8.5h8M8 15.5h5M8 12h8" stroke="currentColor" strokeLinecap="round"></path></svg>
                                                    </div>
                                                    <div className="ml-4">
                                                        Stories
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        {/* <Link to={UserDashboardRoutes().USER.SETTINGS.nav()}>
                                            <div className="py-2 px-6 text-[#6B6B6B] hover:text-[#242424] text-sm ">
                                                <div className="flex items-center">
                                                    <div className="">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label="Profile"><circle cx="12" cy="7" r="4.5" stroke="currentColor" strokeWidth="1.75"></circle><path d="M3.5 21.5v-4.34C3.5 15.4 7.3 14 12 14s8.5 1.41 8.5 3.16v4.34" stroke="currentColor" strokeWidth="1.75" strokeLinecap="round"></path></svg>
                                                    </div>
                                                    <div className="ml-4">
                                                        Profile
                                                    </div>
                                                </div>
                                            </div>
                                        </Link> */}

                                        <Link to={UserDashboardRoutes().STATS.INDEX.nav()}>
                                            <div className="py-2 px-6 text-[#6B6B6B] hover:text-[#242424] text-sm ">
                                                <div className="flex items-center">
                                                    <div className="">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label="Stats"><path d="M2.75 19h4.5c.14 0 .25-.11.25-.25v-6.5a.25.25 0 0 0-.25-.25h-4.5a.25.25 0 0 0-.25.25v6.5c0 .14.11.25.25.25zM9.75 19h4.5c.14 0 .25-.11.25-.25V8.25a.25.25 0 0 0-.25-.25h-4.5a.25.25 0 0 0-.25.25v10.5c0 .14.11.25.25.25zM16.75 19h4.5c.14 0 .25-.11.25-.25V4.25a.25.25 0 0 0-.25-.25h-4.5a.25.25 0 0 0-.25.25v14.5c0 .14.11.25.25.25z" stroke="currentColor"></path></svg>
                                                    </div>
                                                    <div className="ml-4">
                                                        Stats
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                    </div>

                                    <div className="border-b border-[#f2f2f2] py-4">

                                        <Link to={UserDashboardRoutes().USER.SETTINGS.nav()}>
                                            <div className="py-2 px-6 text-[#6B6B6B] hover:text-[#242424] text-sm ">
                                                <div className="flex items-center">
                                                    <div className="">
                                                        Settings
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                    </div>

                                    <div className="py-4">

                                        <a href='#' onClick={signout}>
                                            <div className="py-2 px-6 text-[#6B6B6B] text-sm text-left">
                                                <div className="">
                                                    <div className=" hover:text-[#242424] mb-2">
                                                        Sign out
                                                    </div>
                                                    <div className="">
                                                        {hideEmail(Auth().user.email)}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>

                                    </div>

                                </div>

                            </div>

                        </div>
                    )
                }

            </div>

        </div>
    )
}

export default ProfileDropDown
