import { Link, Outlet } from "react-router-dom"
import { changeDocumentTitle } from "../../helpers/helpers"
import { UserDashboardRoutes } from "../../routes/RouteNames"
import Sidebar from "./sidebar/Sidebar"
import TabList from "./tabs/TabList"

const UserIndex = () => {

    // change page title
    const PAGE_TITLE = changeDocumentTitle('Settings')

    return (
        <div className="h-full">

            <div className="flex justify-evenly flex-row h-full">

                <main className="md:min-w-[728px] md:max-w-[728px] px-2">

                    <div className="mt-[40px]">

                        <div className="">

                            <div className="">
                                <h1 className="text-[42px] mb-11">{PAGE_TITLE}</h1>
                            </div>

                        </div>

                        <div className="">

                            <div className="">
                                <TabList />
                            </div>

                            <div className="">

                                <Outlet />

                            </div>

                        </div>

                    </div>

                </main>

                <div className="md:block hidden border-l border-[#f2f2f2] pl-8 max-w-[368px]">
                    <div className="sticky top-0 h-[100vh] max-h-[100vh]">
                        <Sidebar />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default UserIndex
