import { useState } from 'react'

const useConfirmDeleteDialog = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')

    const show = (title = 'Confirm delete', message = 'Are you sure you want to delete?') =>
    {
        setIsOpen(true)
        setTitle(title)
        setMessage(message)
    }

    const hide = () =>
    {
        setIsOpen(false)
    }

    return {
        isOpen,
        message,
        title,
        show,
        hide,
    }
}

export default useConfirmDeleteDialog
