import { Pagination } from '@mui/material'
import { useEffect, useState } from 'react'
import Placeholder from '../../../components/placeholders/placeholder1/Placeholder'
import TBodyItem from '../../../components/tables/table1/TBodyItem'
import usePagination from '../../../hooks/usePagination'
import usePost from '../../../hooks/usePost'

const TotalRequestsList = () => {

    const { response, error, isLoading, post } = usePost()
    const [filter, setFilter] = useState('current_month')
    // Pagination
    const { totalPageNumbers, currentResults, currentPage, setData, changePage, setResultsPerPage } = usePagination()

    useEffect(() => {

        currentMonth()

    }, [])

    useEffect(() => {

        // if (error) {
        //     console.log(error)
        // }

        if (response) {
            const data = response.data

            setResultsPerPage(5)
            setData(data)

        }

    }, [response, error])

    const allTime = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'request_method',
                'route',
                'route_path',
                'COUNT(id) as total',
                'DATE(created_at) as date',
            ],
            groupBy: 'route',
        })
    }

    const today = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'request_method',
                'route',
                'route_path',
                'COUNT(id) as total',
                'DATE(created_at) as date',
            ],
            today: true,
            groupBy: 'route',
        })
    }

    const currentWeek = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'request_method',
                'route',
                'route_path',
                'COUNT(id) as total',
                'DATE(created_at) as date',
                `DATE_FORMAT(created_at, '%W') AS days_of_week`,
            ],
            current_week: true,
            groupBy: 'route',
        })
    }

    const currentMonth = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'request_method',
                'route',
                'route_path',
                'COUNT(id) as total',
                'DATE(created_at) as date',
            ],
            current_month: true,
            groupBy: 'route',
        })
    }

    const currentYear = () => {
        post('/dashboard/visitors-statistics/chart', {
            selectRaws: [
                'request_method',
                'route',
                'route_path',
                'COUNT(id) as total',
                'DATE(created_at) as date',
                `DATE_FORMAT(created_at, '%M') AS months_of_year`,
            ],
            current_year: true,
            groupBy: 'route',
        })
    }

    const handleFilterChange = (e) => {
        const value = e.target.value

        setFilter(value)

        if (value == 'today') {
            today()
        }
        else if (value == 'current_week') {
            currentWeek()
        }
        else if (value == 'current_month') {
            currentMonth()
        }
        else if (value == 'current_year') {
            currentYear()
        }
        else if ( value == 'all_time' )
        {
            allTime()
        }
    }

    return (
        <div>

            <div className="mb-5">
                <select className="outline-none border-none shadow-[1px_2px_65px_14px_rgba(235,235,235,0.30)_inset] px-3 py-1 rounded-md text-sm text-secondary" value={filter} onChange={handleFilterChange}>
                    <option value="all_time">All Time</option>
                    <option value="today">Today</option>
                    <option value="current_week">This Week</option>
                    <option value="current_month">This Month</option>
                    <option value="current_year">This Year</option>
                </select>
            </div>

            <div className="mb-5">
                <Pagination count={totalPageNumbers} size='large' shape='rounded' showFirstButton showLastButton onChange={changePage} />
            </div>

            <div>

                <table className="w-full bg-white">
                    <thead>
                        <tr>
                            <th className="font-light p-4 text-left border-b border-[#e0e0e0]">Index</th>
                            <th className="font-light p-4 text-left border-b border-[#e0e0e0]">Method</th>
                            <th className="font-light p-4 text-left border-b border-[#e0e0e0]">Route</th>
                            <th className="font-light p-4 text-left border-b border-[#e0e0e0]">Total</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            isLoading && Array.from([1, 2, 3, 4]).map((v, k) => {
                                return (
                                    <tr key={k}>
                                        <TBodyItem className="">
                                            <Placeholder className={'!block h-2'} />
                                        </TBodyItem>
                                        <TBodyItem className="">
                                            <Placeholder className={'!block h-2'} />
                                        </TBodyItem>
                                        <TBodyItem className="">
                                            <Placeholder className={'!block h-2'} />
                                        </TBodyItem>
                                        <TBodyItem className="">
                                            <Placeholder className={'!block h-2'} />
                                        </TBodyItem>
                                    </tr>
                                )
                            })
                        }

                        {
                            !isLoading && currentResults && currentResults.map((request, requestIndex) => {
                                return (
                                    <tr key={requestIndex}>
                                        <TBodyItem className="">
                                            {requestIndex + 1}
                                        </TBodyItem>
                                        <TBodyItem className="">
                                            {request.request_method}
                                        </TBodyItem>
                                        <TBodyItem className="text-secondary text-sm">
                                            <div className="mb-1">
                                                <div className="">
                                                    {request.route}
                                                </div>
                                            </div>
                                            <div className="">
                                                {request.route_path}
                                            </div>
                                        </TBodyItem>
                                        <TBodyItem className="">
                                            {request.total}
                                        </TBodyItem>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default TotalRequestsList
