import { Outlet } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

function LayoutDashboardClean() {

    const {Auth} = useAuth()

    return (
        <div id='dashboard-main' className='h-full'>

            <div className="container mx-auto h-full" id='dashboard-content'>
                <Outlet/>
            </div>

        </div>
    )
}

export default LayoutDashboardClean
