import Overlay from "../overlays/overlay1/Overlay"
import SelectTopics from "../tags/select-inputs/select-input1/SelectTopics"
import { disableDocumentOverflow, enableDocumentOverflow, calculateReadTime, frontendAsset } from "../../helpers/helpers"
import { useEffect, useState } from "react"
import useAuth from "../../hooks/useAuth"
import usePatch from "../../hooks/usePatch"
import NotificationSeverity from "../notifications/notification1/NotificationSeverity"
import useNotification from "../../hooks/useNotification"
import useFetch from "../../hooks/useFetch"
import { Step, StepLabel, Stepper } from "@mui/material"
import NextStepButton from '../../components/buttons/Button'
import PreviousStepButton from '../../components/buttons/Button'
import MetaTitleInput from "../inputs/input2/Input"
import MetaDescriptionInput from "../inputs/textarea3/SeoTextarea"
import MetaOGTitleInput from "../inputs/input2/Input"
import MetaOGDescriptionInput from "../inputs/textarea3/SeoTextarea"
import RelevantKeywordsInput from "../inputs/input2/Input"
import AllowSearchEngineEndexingCheckbox from "../checkboxes/checkbox1/Checkbox"
import HasFeaturedImageCheckbox from "../checkboxes/checkbox1/Checkbox"
import SelectMediaModal from "./SelectMediaModal"
import useModal from "../../hooks/useModal"
import SelectOGImageButton from '../../components/buttons/Button'
const PublishStoryModal = ({ visible, story, handleHide, onPublish }) => {

    const [previewImageUrl, setPreviewImageUrl] = useState(null)
    const [description, setDescription] = useState('')
    const [readTime, setReadTime] = useState(1)
    const { Auth } = useAuth()
    const [topics, setTopics] = useState([])
    const { response, error, isLoading, patch } = usePatch()
    const { response: fetchResponse, error: fetchError, isLoading: fetchIsLoading, fetch } = useFetch()
    const { message: notificationMessage, visible: notificationVisible, type: notificationType, show: showNotification, hide: hideNotification } = useNotification()
    const { visible: smmVisible, show: smmShow, hide: smmHide } = useModal()
    const [storyData, setStoryData] = useState([])
    const [stepper, setStepper] = useState({
        activeStep: 0,
        isLastStep: false,
        isFirstStep: true,
    })
    const [seoData, setSeoData] = useState({
        mediaId: null,
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
        allowSearchEngineIndexing: 1,
        ogTitle: '',
        ogDescription: '',
        ogImage: '',
        ogImageSizes: [],
        hasFeaturedImage: 0,
        relevant_keywords: [],
    })

    const steps = [
        {
            label: 'Select relavant topics',
        },
        {
            label: 'Setup "Seo" settings for search engines',
        },
        {
            label: 'Publish Your Story',
        },
    ]

    if (visible) {
        // hide overflow
        disableDocumentOverflow()
    }
    else {
        enableDocumentOverflow()
    }

    useEffect(() => {

        if (error) {
            console.log(error)
            showNotification('danger', error.message)
        }

        if (response) {
            // console.log(response)
            showNotification('success', 'Congrats, your story has been published!')
            // fetchStory()
            if ( typeof onPublish == 'function' )
            {
                onPublish(response)
            }
        }

    }, [response, error])

    useEffect(() => {

        if (storyData) {

            getFirstImage()
            // getFirstText()
            getReadTime()
        }

    }, [storyData])

    useEffect(() => {

        if (fetchError) {
            showNotification('danger', error.message)
        }

        if (fetchResponse) {
            const data = fetchResponse.data
            // console.log(data)
            setStoryData(data)
            setSeoData({
                ...seoData,
                metaTitle: data.meta_title ?? '',
                metaDescription: data.meta_description ?? '',
                ogTitle: data.og_title ?? '',
                ogDescription: data.og_description ?? '',
                ogImage: data.og_image ?? '',
                hasFeaturedImage: data.has_featured_image,
                relevant_keywords: data.relevant_keywords,
            })
        }
        
    }, [fetchResponse, fetchError])

    useEffect(() => {

        if (visible) {

            fetchStory()
        }

    }, [visible])

    useEffect(() => {
        if (stepper.activeStep == steps.length - 1) {
            setStepper({
                ...stepper,
                isLastStep: true,
            })
        }

        if (stepper.activeStep == 0) {
            setStepper({
                ...stepper,
                isFirstStep: true,
            })
        }

    }, [stepper.activeStep])

    // useEffect(() => {

    //     handlePublish()

    // }, [seoData])

    const fetchStory = () => {
        fetch(`/dashboard/stories/${story.uuid}`)
    }

    const getFirstImage = () => {
        const blocks = storyData?.content?.blocks?.filter(block => {
            if (block.type == 'image') {
                return block
            }
        })

        if (blocks) {
            setPreviewImageUrl(blocks[0]?.data?.file?.url)
        }

    }

    const getFirstText = () => {
        const blocks = storyData?.content?.blocks?.filter(block => {
            if (block.type == 'paragraph') {
                return block
            }
        })

        if (blocks) {
            setDescription(blocks[0]?.data?.text)
        }

    }

    const getReadTime = () => {
        const blocks = storyData?.content?.blocks?.filter(block => {
            if (
                block.type == 'paragraph'
                || block.type == 'header'
                || block.type == 'list'
            ) {
                return block
            }
        })

        var text = ''

        if (blocks) {
            for (var i = 0; i < blocks.length; i++) {
                const block = blocks[i]

                if (
                    block.type == 'paragraph'
                    || block.type == 'header'
                ) {
                    text += block.data.text
                }

                if (
                    block.type == 'list'
                ) {
                    for (var j = 0; j < block.data.items.length; j++) {
                        const item = block.data.items[j]

                        text += item.content
                    }
                }
            }
        }

        setReadTime(calculateReadTime(text))
    }

    const handlePublish = () => {


        if (topics.length == 0) {
            showNotification('danger', 'Please select topics relevant to your story before publishing.')
            return
        }

        const syncTags = topics.map(v => {
            return {
                story_id: story.id,
                tag_id: v.id,
            }
        })

        patch(`/dashboard/stories/${story.id}`, {
            status: 'publish',
            tags: syncTags,
            description: seoData.metaDescription,
            read_time: readTime,
            meta_title: seoData.metaTitle,
            meta_description: seoData.metaDescription,
            meta_keywords: seoData.metaKeywords,
            allow_search_engine_indexing: seoData.allowSearchEngineIndexing,
            og_title: seoData.ogTitle,
            og_description: seoData.ogDescription,
            og_image: seoData.ogImage,
            og_image_sizes: seoData.ogImageSizes,
            has_featured_image: seoData.hasFeaturedImage,
            relevant_keywords: (typeof seoData.relevant_keywords == 'string') ? seoData.relevant_keywords.split(',') : [],
            media_id: seoData.mediaId,
        })
    }

    const handleNextStep = () => {
        setStepper({
            ...stepper,
            activeStep: stepper.activeStep + 1,
            isFirstStep: false,
        })
    }

    const handlePreviousStep = () => {
        setStepper({
            ...stepper,
            activeStep: stepper.activeStep - 1,
            isLastStep: false,
        })
    }

    const handleSeoDataChange = (e) => {
        const target = e.target

        if (target.type == 'checkbox') {
            setSeoData({
                ...seoData,
                [target.name]: target.checked ? 1 : 0,
            })

            return
        }

        setSeoData({
            ...seoData,
            [target.name]: target.value,
        })
    }

    const handleOnMedia = (image) => {

        setSeoData({
            ...seoData,
            ogImage: image ? image.path : '',
            ogImageSizes: (image.paths) ?? null,
            mediaId: (image.media_id) ?? image.id
        })

    }

    return (
        <>
            <SelectMediaModal classes="!z-[1100]" contentClasses="max-w-[900px] !block" visible={smmVisible} onHide={smmHide} onMedia={handleOnMedia} />
            <div className="">
                <NotificationSeverity type={notificationType} visible={notificationVisible} message={notificationMessage} onClickHide={hideNotification} />
            </div>
            {
                visible && (
                    <div className="fixed top-0 left-0 w-full h-full z-[900] bg-white flex items-center justify-center">

                        <div className="max-w-[1040px] w-full relative overflow-hidden py-[100px] px-[5px] flex">

                            <button className="absolute top-0 right-0 text-[#0000008a] inline-flex hover:text-[#000000ad] group" onClick={handleHide}>
                                <svg className="svgIcon-use group-hover:fill-[#000000d6]" width="29" height="29"><path d="M20.13 8.11l-5.61 5.61-5.609-5.61-.801.801 5.61 5.61-5.61 5.61.801.8 5.61-5.609 5.61 5.61.8-.801-5.609-5.61 5.61-5.61" fillRule="evenodd"></path></svg>
                            </button>

                            <div className="w-full">

                                <Stepper activeStep={stepper.activeStep} alternativeLabel>

                                    {
                                        steps && steps.map((step, stepIndex) => {
                                            return (
                                                <Step key={stepIndex}>
                                                    <StepLabel>
                                                        <div className="max-w-[200px] mx-auto">
                                                            <span className="font-semibold text-[#000000de]">{step.label}</span>
                                                        </div>
                                                    </StepLabel>
                                                </Step>
                                            )
                                        })
                                    }

                                </Stepper>

                                {
                                    (stepper.activeStep == 0) && (
                                        <div className="grid md:grid-cols-2">

                                            <div className="p-[40px]">

                                                <div className="text-[#000000c2] text-[17px] font-semibold mb-2">
                                                    <p className="">Story Preview</p>
                                                </div>

                                                {
                                                    previewImageUrl ? (
                                                        <div className="relative mb-3">
                                                            <img src={previewImageUrl} className="w-full h-[200px]" alt="" />
                                                            <Overlay />
                                                        </div>
                                                    )
                                                        : (
                                                            <div className="bg-[#fafafa] w-full h-[200px] flex items-center justify-center text-sm px-4 py-2 text-center text-[#0000008a] mb-3">
                                                                <p>
                                                                    Include a high-quality image in your story to make it more inviting to readers.
                                                                </p>
                                                            </div>
                                                        )
                                                }


                                                <div className="">
                                                    <p className="text-[#0000008a]">
                                                        <span className="font-semibold ">Note: </span>
                                                        <span className="text-sm">
                                                            Changes here will affect how your story appears in public places like PondStories homepage and in subscribers’ inboxes — not the contents of the story itself.
                                                        </span>
                                                    </p>
                                                </div>

                                            </div>

                                            <div className="p-[40px]">

                                                <div className="text-[#000000c2] text-[17px] mb-4">
                                                    <p className="">
                                                        <span>Publishing to: </span>
                                                        <span className="font-bold">{Auth().user.name}</span>
                                                    </p>
                                                </div>

                                                <div className="text-sm mb-4">
                                                    <p className="leading-6">
                                                        Add or change topics (up to 5) so readers know what your story is about
                                                    </p>
                                                </div>

                                                <div className="mb-5">
                                                    <SelectTopics story={story} setTopics={setTopics} />
                                                </div>

                                                <div className="text-sm text-[#0000008a]">
                                                    {
                                                        story && (
                                                            <p>"{story.title}"</p>
                                                        )
                                                    }
                                                </div>

                                            </div>

                                        </div>
                                    )
                                }


                                {
                                    (stepper.activeStep == 1) && (
                                        <div className="">

                                            <div className="grid md:grid-cols-2 mb-4">

                                                <div className="p-[40px]">

                                                    <div className="mb-2">
                                                        <MetaTitleInput classes={'!py-2 !px-0'} hint="Seo meta title must not exceed a certain length." showCharacterCount max={60} value={seoData.metaTitle} onChange={handleSeoDataChange} name='metaTitle' />
                                                    </div>

                                                    <div className="mb-2">
                                                        <MetaDescriptionInput rows={3} showCharacterCount max={160} hint="Seo meta description is good for showing a small introduction." value={seoData.metaDescription} onChange={handleSeoDataChange} name="metaDescription" />
                                                    </div>

                                                    <div className="mb-2">
                                                        <RelevantKeywordsInput classes={'!py-2 !px-0'} hint="Relevant keywords for internal linking separated by comma (,)." showCharacterCount max={60} value={ (seoData.relevant_keywords) ? seoData.relevant_keywords.toString() : '' } onChange={handleSeoDataChange} name='relevant_keywords' />
                                                    </div>

                                                </div>

                                                <div className="p-[40px]">

                                                    <div className="mb-2">
                                                        <MetaOGTitleInput classes={'!py-2 !px-0'} hint="Seo open graph (og) meta title must not exceed a certain length." showCharacterCount max={60} value={seoData.ogTitle} onChange={handleSeoDataChange} name='ogTitle' />
                                                    </div>

                                                    <div className="mb-2">
                                                        <MetaOGDescriptionInput rows={3} showCharacterCount max={160} hint="Seo open graph (og) meta description is good for showing a small introduction." value={seoData.ogDescription} onChange={handleSeoDataChange} name="ogDescription" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="flex justify-between">

                                                <div className="flex">
                                                    <div className="">
                                                        <SelectOGImageButton text={'Select og image'} classes='!text-sky-500 font-semibold' onClick={smmShow} />
                                                    </div>
                                                </div>

                                                <div className="">

                                                    <div className="flex mb-3">
                                                        <div className=""></div>
                                                        <div className="">
                                                            <AllowSearchEngineEndexingCheckbox labelClasses="font-semibold text-secondary" label="Allow search engines to index your story?" value={seoData.allowSearchEngineIndexing} onChange={handleSeoDataChange} name="allowSearchEngineIndexing" checked={seoData.allowSearchEngineIndexing} />
                                                        </div>
                                                    </div>

                                                    <div className="flex">
                                                        <div className=""></div>
                                                        <div className="">
                                                            <HasFeaturedImageCheckbox labelClasses="font-semibold text-secondary" label="Has featured image?" value={seoData.hasFeaturedImage} onChange={handleSeoDataChange} name="hasFeaturedImage" checked={seoData.hasFeaturedImage} />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    )
                                }

                                {
                                    (stepper.activeStep == 2) && (
                                        <div className="">

                                            <div className="p-[40px]">

                                                <div className="text-[#000000c2] text-[17px] font-semibold mb-2">
                                                    <p className="">{story.title}</p>
                                                </div>

                                                {
                                                    seoData.ogImage ? (
                                                        <div className="relative mb-3">
                                                            <img src={ frontendAsset(seoData.ogImage) } className="w-full h-[350px]" alt="" />
                                                            <Overlay />
                                                        </div>
                                                    )
                                                        : (
                                                            <div className="bg-[#fafafa] w-full h-[300px] flex items-center justify-center text-sm px-4 py-2 text-center text-[#0000008a] mb-3">
                                                                <p>
                                                                    Include a high-quality image in your story to make it more inviting to readers.
                                                                </p>
                                                            </div>
                                                        )
                                                }


                                                <div className="text-right">
                                                    {
                                                        !fetchIsLoading && (
                                                            <div className="mb-3">
                                                                <button
                                                                    disabled={isLoading}
                                                                    onClick={handlePublish}
                                                                    className={`bg-sixth text-white text-sm py-1 min-w-[120px] min-h-[37px] hover:bg-[#0f730c] rounded-3xl`}>
                                                                    Publish now
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                            </div>

                                        </div>
                                    )
                                }

                                <div className="flex justify-between mt-5">

                                    <div className="">
                                        <PreviousStepButton classes={'!text-[#1976d2] font-medium uppercase'} text='Back' onClick={handlePreviousStep} disabled={stepper.isFirstStep} />
                                    </div>
                                    <div className="">
                                        <NextStepButton classes={'!text-[#1976d2] font-medium uppercase'} text='Next' onClick={handleNextStep} disabled={stepper.isLastStep} />
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                )
            }
        </>
    )
}

export default PublishStoryModal
