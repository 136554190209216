import { useState } from "react"
import { copyToClipboard, shareOnFacebook, shareOnLinkedIn, shareOnTwitter } from "../../helpers/helpers"
import useNotification from "../../hooks/useNotification"
import NotificationSeverity from "../notifications/notification1/NotificationSeverity"

const ShareStoryMenu = ({story}) => {

    const [visible, setVisible] = useState(false)
    const {message: notificationMessage, visible: notificationVisible, type: notificationType, show: showNotification, hide: hideNotification} = useNotification()

    const toggleMenu = () => {
        setVisible(!visible)
    }

    // copy to clipboard
    const handleCopyLinkToClipboard = () => 
    {
        copyToClipboard(story.urls.canonical)

        showNotification('dark','Link copied to your clipboard')

        toggleMenu()
    }

    const handleShareOnFacebook = () =>
    {
        shareOnFacebook(story.urls.canonical)
    }

    const handleShareOnTwitter = () =>
    {
        shareOnTwitter(story.urls.canonical)
    }

    const handleShareOnLinkedIn = () =>
    {
        shareOnLinkedIn(story.urls.canonical)
    }

    return (
        <div className="relative">

            <NotificationSeverity type={notificationType} visible={notificationVisible} message={notificationMessage} onClickHide={hideNotification} />

            {
                visible && (
                    <div className="fixed w-full h-full z-[500] top-0 left-0" onClick={toggleMenu}></div>
                )
            }

            <div className="relative">

                <div className="flex items-center">
                    <button className='text-secondary' onClick={toggleMenu}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M15.22 4.93a.42.42 0 0 1-.12.13h.01a.45.45 0 0 1-.29.08.52.52 0 0 1-.3-.13L12.5 3v7.07a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5V3.02l-2 2a.45.45 0 0 1-.57.04h-.02a.4.4 0 0 1-.16-.3.4.4 0 0 1 .1-.32l2.8-2.8a.5.5 0 0 1 .7 0l2.8 2.8a.42.42 0 0 1 .07.5zm-.1.14zm.88 2h1.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-11a2 2 0 0 1-2-2v-10a2 2 0 0 1 2-2H8a.5.5 0 0 1 .35.14c.1.1.15.22.15.35a.5.5 0 0 1-.15.35.5.5 0 0 1-.35.15H6.4c-.5 0-.9.4-.9.9v10.2a.9.9 0 0 0 .9.9h11.2c.5 0 .9-.4.9-.9V8.96c0-.5-.4-.9-.9-.9H16a.5.5 0 0 1 0-1z" fill="currentColor"></path></svg>
                    </button>
                </div>

                {
                    visible && (
                        <div className="absolute z-[501] w-[240px] top-[35px] left-[-100px]">

                            <div className="bg-white shadow-md rounded-sm relative z-50">

                                <ul className="list-none py-2">
                                    <li className="pt-3 pb-3 px-5 text-secondary text-sm flex items-center hover:text-[#242424]">
                                        <div className="">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.5 9.68l.6-.6a5 5 0 0 1 1.02 7.87l-2.83 2.83a5 5 0 0 1-7.07-7.07l2.38-2.38c0 .43.05.86.12 1.3l-1.8 1.79a4 4 0 0 0 5.67 5.65l2.82-2.83a4 4 0 0 0-1.04-6.4l.14-.16zm-1 4.64l-.6.6a5 5 0 0 1-1.02-7.87l2.83-2.83a5 5 0 0 1 7.07 7.07l-2.38 2.39c0-.43-.05-.87-.12-1.3l1.8-1.8a4 4 0 1 0-5.67-5.65L10.6 7.76a4 4 0 0 0 1.04 6.4l-.13.16z" fill="currentColor"></path></svg>
                                        </div>
                                        <div className="w-2"></div>
                                        <div className="">
                                            <button className="" onClick={handleCopyLinkToClipboard}>
                                                Copy link
                                            </button>
                                        </div>
                                    </li>
                                    <li className="">
                                        <div className="border-t border-forth"></div>
                                    </li>
                                    <li className="pt-3 pb-1 px-5 text-secondary text-sm flex items-center hover:text-[#242424]">
                                        <div className="">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="jb"><path d="M19.1 8.06a8 8 0 0 1 .01.51c0 5.24-3.68 11.27-10.42 11.27-2 0-3.95-.61-5.62-1.78a6.9 6.9 0 0 0 5.42-1.64 3.72 3.72 0 0 1-3.42-2.75c.55.12 1.11.1 1.65-.07a3.9 3.9 0 0 1-2.94-3.88v-.05c.51.3 1.08.48 1.67.5a4.16 4.16 0 0 1-1.14-5.3 10.17 10.17 0 0 0 7.56 4.15c-.3-1.38.1-2.82 1.06-3.79a3.48 3.48 0 0 1 5.18.17c.82-.17 1.6-.5 2.33-.96-.27.92-.85 1.7-1.61 2.2.72-.1 1.43-.3 2.1-.63a7.8 7.8 0 0 1-1.83 2.05z" fill="currentColor"></path></svg>
                                        </div>
                                        <div className="w-2"></div>
                                        <div className="">
                                            <button className="" onClick={handleShareOnTwitter}>
                                                Share on Twitter
                                            </button>
                                        </div>
                                    </li>
                                    <li className="pt-3 pb-1 px-5 text-secondary text-sm flex items-center hover:text-[#242424]">
                                        <div className="">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="jb"><path d="M22 12.06C22 6.5 17.52 2 12 2S2 6.5 2 12.06c0 5.02 3.66 9.19 8.44 9.94v-7.03H7.9v-2.9h2.54V9.83c0-2.52 1.5-3.91 3.77-3.91 1.1 0 2.24.2 2.24.2V8.6H15.2c-1.24 0-1.63.78-1.63 1.57v1.9h2.78l-.45 2.9h-2.33V22A10.04 10.04 0 0 0 22 12.06z" fill="currentColor"></path></svg>
                                        </div>
                                        <div className="w-2"></div>
                                        <div className="">
                                            <button className="" onClick={handleShareOnFacebook}>
                                                Share on Facebook
                                            </button>
                                        </div>
                                    </li>
                                    <li className="pt-3 pb-1 px-5 text-secondary text-sm flex items-center hover:text-[#242424]">
                                        <div className="">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="jb"><path d="M21 4.32v15.36A1.32 1.32 0 0 1 19.68 21H4.32A1.32 1.32 0 0 1 3 19.68V4.32A1.32 1.32 0 0 1 4.32 3h15.36A1.32 1.32 0 0 1 21 4.32zM8.3 9.9H5.65v8.47h2.63V9.9h.02zm.22-2.92A1.52 1.52 0 0 0 7 5.44h-.05a1.53 1.53 0 0 0 0 3.06 1.52 1.52 0 0 0 1.56-1.48v-.05zm9.82 6.24c0-2.56-1.63-3.54-3.23-3.54a3.02 3.02 0 0 0-2.67 1.37h-.08V9.87H9.87v8.48h2.65v-4.5a1.75 1.75 0 0 1 1.58-1.88h.1c.84 0 1.47.52 1.47 1.85v4.54h2.65l.02-5.14v-.01z" fill="currentColor"></path></svg>
                                        </div>
                                        <div className="w-2"></div>
                                        <div className="">
                                            <button className="" onClick={handleShareOnLinkedIn}>
                                                Share on LinkedIn
                                            </button>
                                        </div>
                                    </li>
                                </ul>

                            </div>

                            <div className="bg-white shadow-[rgba(0,0,0,0.05)_0px_0px_4px,rgba(0,0,0,0.15)_0px_2px_8px] w-3 h-3 absolute top-[-6px] left-[106px] z-40 rotate-[45deg]"></div>

                        </div>
                    )
                }

            </div>

        </div>
    )
}

export default ShareStoryMenu
