import { Link } from "react-router-dom"
import Links from "../../../../components/links/footer-links/links1/Links"
import LatestNotifications from "../../../../components/notifications/LatestNotifications"
import ProfileImage from "../../../../components/profile/images/image1/ProfileImage"
import useAuth from "../../../../hooks/useAuth"
import { AuthRoutes, UserDashboardRoutes } from "../../../../routes/RouteNames"

const Sidebar = () => {

    const { Auth } = useAuth()

    return (
        <div className='flex flex-col justify-between h-full'>

            <div className="mt-[40px]">

                <LatestNotifications total={10} />

            </div>

            <div className="mt-[40px]"></div>

            <div className="py-[24px]">

                <Links/>

            </div>

        </div>
    )
}

export default Sidebar
