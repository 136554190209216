
import ProfileImage from "../../../components/profile/images/image1/ProfileImage"
import useAuth from "../../../hooks/useAuth"
import UpdateUserEmailModal from "../../../components/modals/UpdateUserEmailModal"
import useModal from '../../../hooks/useModal'
import UpdateUserUsernamePhoneModal from "../../../components/modals/UpdateUserUsernamePhoneModal"
import UpdateUserProfileInfoModal from "../../../components/modals/UpdateUserProfileInfoModal"
// import { useEffect, useState } from "react"
const UserSettings = () => {

    const { Auth } = useAuth()
    const { visible: uuemVisible, show: uuemShow, hide: uuemHide } = useModal()
    const { visible: uuupVisible, show: uuupShow, hide: uuupHide } = useModal()
    const { visible: uupiVisible, show: uupiShow, hide: uupiHide } = useModal()

    return (
        <div>

            <UpdateUserEmailModal 
                visible={uuemVisible}
                onHide={uuemHide}
            />

            <UpdateUserUsernamePhoneModal
                visible={uuupVisible}
                onHide={uuupHide}
            />

            <UpdateUserProfileInfoModal
                visible={uupiVisible}
                onHide={uupiHide}
            />

            <div className="my-8">

                <button className="block w-full group" onClick={uuemShow}>
                    <div className="flex justify-between items-center text-sm">
                        <div className="">
                            <p className="text-primary">Email address</p>
                        </div>
                        <div className="">
                            <p className="text-secondary group-hover:text-[#242424] text-[13px]">{Auth().user.email}</p>
                        </div>
                    </div>
                </button>

            </div>

            <div className="my-8">

                <button className="block w-full group" onClick={uuupShow}>
                    <div className="flex justify-between items-center text-sm">
                        <div className="">
                            <p className="text-primary">Username and subdomain</p>
                        </div>
                        <div className="">
                            <p className="text-secondary group-hover:text-[#242424] text-[13px]">{Auth().user.username}</p>
                        </div>
                    </div>
                </button>

            </div>

            <div className="my-8">

                <button className="block w-full group" onClick={uupiShow}>
                    <div className="text-left text-sm">
                        <div className="mb-1">
                            <p className="text-primary">Profile information</p>
                        </div>
                        <div className="flex justify-between items-center">
                            <div className="">
                                <p className="text-secondary text-[13px]">Edit your photo, name, bio, etc.</p>
                            </div>
                            <div className="flex items-center">
                                <div className="flex">
                                    <div className="">
                                        <p className="text-secondary group-hover:text-[#242424] text-[13px]">{Auth().user.name}</p>
                                    </div>
                                    <div className="w-2"></div>
                                    <div className="">
                                        <ProfileImage url={ (Auth().user?.avatar_urls?.sizes) ? Auth().user?.avatar_urls?.sizes['24x24']: null } />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </button>

            </div>

        </div>
    )
}

export default UserSettings
