import { useState } from "react"
import { copyToClipboard } from "../../helpers/helpers"
import useNotification from "../../hooks/useNotification"
import NotificationSeverity from "../notifications/notification1/NotificationSeverity"

const CopyUrlToClipboard = ({
    url = '',
    className = '',
}) => {

    const { message: notificationMessage, visible: notificationVisible, type: notificationType, show: showNotification, hide: hideNotification } = useNotification()
    const [isCopied, setIsCopied] = useState(false)

    // copy to clipboard
    const handleCopyLinkToClipboard = () => {
        copyToClipboard(url)

        showNotification('dark', 'Link copied to your clipboard')
        setIsCopied(true)

        setTimeout(() => {
            setIsCopied(false)
        }, 5 * 1000)
    }

    return (
        <>

            <NotificationSeverity type={notificationType} visible={notificationVisible} message={notificationMessage} onClickHide={hideNotification} />

            <div className="flex items-center">

                <div className="mr-2">
                    <p className={`text-ellipsis whitespace-nowrap overflow-hidden max-w-xs text-sm font-light ${className}`}>
                        {url}
                    </p>
                </div>
                <div className="">
                    <button className={`w-6 h-6 px-1 py-1 rounded-sm flex items-center justify-center ${ (isCopied) ? 'text-sixth' : 'text-secondary' } `} onClick={handleCopyLinkToClipboard}>

                        {
                            !isCopied ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                </svg>
                            )
                                : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                )
                        }

                    </button>
                </div>

            </div>

        </>
    )
}

export default CopyUrlToClipboard
