import React from 'react'

const TBodyItem = ({
    children,
    className = '',
}) => {
    return (
        <>
            <td className={`font-light p-4 text-left border-b border-[#e0e0e0] ${className}`}>{children}</td>
        </>
    )
}

export default TBodyItem
