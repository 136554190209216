import { useEffect } from "react"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { changeDocumentTitle } from "../../helpers/helpers"
import useAuth from "../../hooks/useAuth"
import { UserDashboardRoutes } from "../../routes/RouteNames"
import Sidebar from "./sidebar/Sidebar"
import TabList from "./tabs/TabList"

const UserProfile = () => {

    // change page title
    const PAGE_TITLE = changeDocumentTitle('Settings')

    const {Auth} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {

        if (window.location.pathname == UserDashboardRoutes().USER.PROFILE.nav(Auth().user.username) ) {
            navigate(UserDashboardRoutes().USER.SETTINGS.nav())
        }

    }, [window.location.pathname])

    return (
        <div className="h-full">

            <div className="flex justify-evenly flex-row h-full">

                <main className="md:min-w-[728px] md:max-w-[728px] px-2">

                    

                </main>

                <div className="md:block hidden border-l border-[#f2f2f2] pl-8 max-w-[368px]">
                    <div className="sticky top-0 h-[100vh] max-h-[100vh]">
                        <Sidebar />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default UserProfile
