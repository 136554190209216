import { createRef, useEffect, useRef, useState } from "react"
import Table from "../../components/tables/table1/Table"
import TBody from "../../components/tables/table1/TBody"
import TBodyItem from "../../components/tables/table1/TBodyItem"
import THead from "../../components/tables/table1/THead"
import { changeDocumentTitle, summarizeText } from "../../helpers/helpers"
import usePost from "../../hooks/usePost"
import Sidebar from "./sidebar/Sidebar"
import SearchBox from '../../components/searchbox/searchbox1/SearchBox'
import AlertSeverity from '../../components/alerts/AlertSeverity'
import useAlert from "../../hooks/useAlert"
import { formatTimeStamp, getDatetimeDifference } from "../../helpers/helpers"
import Checkbox from "../../components/checkboxes/checkbox1/Checkbox"
import SelectAllCheckbox from "../../components/checkboxes/checkbox1/Checkbox"
import usePagination from "../../hooks/usePagination"
import { Link } from "react-router-dom"
import { UserDashboardRoutes } from "../../routes/RouteNames"
import useDelete from "../../hooks/useDelete"
import Placeholder from "../../components/placeholders/placeholder1/Placeholder"
import useConfirmDeleteDialog from "../../hooks/useConfirmDeleteDialog"
import ConfirmDeleteModal from "../../components/modals/ConfirmDeleteModal"
import { Pagination } from "@mui/material"
const TagsIndex = () => {

    // change page title
    const PAGE_TITLE = changeDocumentTitle('All tags')
    const { response, isLoading, error, post } = usePost()
    const { response: deleteResponse, isLoading: deleteLoading, error: deleteError, _delete } = useDelete()
    const [searchBoxValue, setSearchBoxValue] = useState('')
    const [deleteTagId, setDeleteTagId] = useState(null)
    const deleteRowRef = useRef()
    const { type: alertType, visible: alertVisible, message: alertMessage, show: showAlert, hide: hideAlert } = useAlert()
    const {title: cdmTitle, message: cdmMessage, isOpen: cdmIdOpen, show: cdmShow, hide: cdmHide} = useConfirmDeleteDialog()
    const [checkboxes, setCheckboxes] = useState({
        isAllChecked: false,
        checkboxes: []
    })
    // const rowRefs = useRef([])

    // Pagination
    const { totalPageNumbers, currentResults, currentPage, setData, changePage, setResultsPerPage } = usePagination()

    useEffect(() => {

        if (error) {
            showAlert('danger', error.message)
        }

        if (response) {
            const data = response.data
            setData(data)
            setResultsPerPage(5)

            const checks = data.map((v) => {
                return {
                    dataId: v.id,
                    checked: false,
                }
            })

            setCheckboxes({
                ...checkboxes,
                checkboxes: checks,
            })
        } 

    }, [response, error])

    useEffect(() => {

        if (deleteError) {
            showAlert('danger', deleteError.message)
        }

        if ( deleteResponse )
        {
            // onSearchBoxTypingEnd()
            // hide row element
            if ( deleteRowRef.current )
            {
                deleteRowRef.current.classList.add('hidden')
            }
        }

    }, [deleteResponse, deleteError])
    
    useEffect(() => {

        const filtered = checkboxes.checkboxes.filter((v) => {
            
            v.checked = checkboxes.isAllChecked

            return v

        })

        setCheckboxes({
            ...checkboxes,
            checkboxes: filtered,
        })

    }, [checkboxes.isAllChecked])

    useEffect(() => {
        search({
            searchTerm: searchBoxValue,
            orderBy: 'id',
            order: 'desc',
        })
    }, [])

    const search = (args) => {
        post('/dashboard/tags/search', args)
    }

    const onSearchBoxTypingEnd = (e) => {

        setData([])
        // updateCurrentResults([])

        search({
            searchTerm: searchBoxValue,
            orderBy: 'id',
            order: 'desc',
        })
    }

    const confirmDeleteDialogOnConfirm = () =>
    {
        _delete(`/dashboard/tags/${deleteTagId}`)
        cdmHide()
    }

    const handleDelete = (e, id) =>
    {
        e.preventDefault()
        deleteRowRef.current = e.target.closest('[role="row"]')
        setDeleteTagId(id)
        cdmShow('Delete tag', "Deletion is not reversible, are you sure you want to proceed?")
    }

    const handleSelectAllCheckbox = (e) => 
    {
        setCheckboxes({
            ...checkboxes,
            isAllChecked: !checkboxes.isAllChecked,
        })
    }

    const handleRowCheckbox = (id) =>
    {
        const filtered = checkboxes.checkboxes.filter((v) => {
            
            if ( v.dataId == id )
            {
                v.checked = !v.checked
            }

            return v

        })

        setCheckboxes({
            ...checkboxes,
            checkboxes: filtered,
        })
    }

    return (
        <div>
            <ConfirmDeleteModal 
                title={cdmTitle} 
                message={cdmMessage}
                isOpen={cdmIdOpen}
                onConfirm={confirmDeleteDialogOnConfirm}
                onCancel={cdmHide}
                isLoading={isLoading}
            />
            <div className="h-full">

                <div className="flex justify-evenly flex-row h-full">

                    <main className="md:min-w-[728px] md:max-w-[728px]">

                        <div className="mt-[40px]">

                            <h1 className="text-[42px] mb-11">{PAGE_TITLE}</h1>

                            <div className="">
                                <div className="my-3">
                                    <AlertSeverity type={alertType} visible={alertVisible} message={alertMessage} onClickHide={hideAlert} />
                                </div>

                                <div className="mb-2">
                                    <SearchBox value={searchBoxValue} setValue={setSearchBoxValue} getValue={onSearchBoxTypingEnd} />
                                </div>

                                <Table resultsCount={totalPageNumbers} onPageChange={changePage} >
                                    <THead isCheckboxChecked={checkboxes.isAllChecked} onCheckboxChange={handleSelectAllCheckbox} columns={[
                                        {
                                            heading: 'Name',
                                        },
                                        {
                                            heading: 'Description',
                                        },
                                        {
                                            heading: 'Slug',
                                        },
                                        {
                                            heading: 'Created at',
                                        },
                                    ]} />
                                    <TBody>
                                        {
                                            isLoading && (
                                                <>
                                                    {
                                                        Array.from(['', '', '', '']).map((v,i) =>
                                                        {
                                                            return (
                                                                <tr key={i}>
                                                                    <TBodyItem>
                                                                        <Placeholder className={'h-10'} />
                                                                    </TBodyItem>
                                                                    <TBodyItem>
                                                                        <Placeholder className={'h-10'} />
                                                                    </TBodyItem>
                                                                    <TBodyItem>
                                                                        <Placeholder className={'h-10'} />
                                                                    </TBodyItem>
                                                                    <TBodyItem>
                                                                        <Placeholder className={'h-10'} />
                                                                    </TBodyItem>
                                                                    <TBodyItem>
                                                                        <Placeholder className={'h-10'} />
                                                                    </TBodyItem>
                                                                    <TBodyItem>
                                                                        <Placeholder className={'h-10'} />
                                                                    </TBodyItem>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        }
                                        {
                                            currentResults && currentResults.map((tag, tagIndex) => {
                                                return (
                                                    <tr key={tag.id} className="hover:bg-[#f9f9f9]" role={'row'} data-id={tag.id}  >
                                                        <TBodyItem>
                                                            <Checkbox checked={ (checkboxes.checkboxes.length) && checkboxes.checkboxes[tagIndex]?.checked } onChange={(e) => handleRowCheckbox((checkboxes.checkboxes) && checkboxes.checkboxes[tagIndex].dataId)} />
                                                        </TBodyItem>
                                                        <TBodyItem>{tag.name}</TBodyItem>
                                                        <TBodyItem>{ summarizeText(tag.description, 50) }</TBodyItem>
                                                        <TBodyItem>{tag.slug}</TBodyItem>
                                                        <TBodyItem>
                                                            <div className="mb-2">
                                                                {formatTimeStamp(tag.created_at)}
                                                            </div>
                                                            <div className="mb-2">
                                                                ({getDatetimeDifference(tag.created_at).auto})
                                                            </div>
                                                            <div className="flex">
                                                                <div className="">
                                                                    <Link to={UserDashboardRoutes().TAGS.UPDATE.nav(tag.id)} className="font-medium text-sm text-sky-800 hover:text-sky-600 hover:underline">
                                                                        Update
                                                                    </Link>
                                                                </div>
                                                                <div className="w-2"></div>
                                                                <div className="">
                                                                    <a href="#" onClick={(e) => handleDelete(e, tag.id)} className="font-medium text-sm text-red-500 hover:text-red-600 hover:underline">Delete</a>
                                                                </div>
                                                            </div>
                                                        </TBodyItem>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </TBody>
                                </Table>

                            </div>

                        </div>

                    </main>

                    <div className="md:block hidden border-l border-[#f2f2f2] pl-8 max-w-[368px]">
                        <div className="sticky top-0 h-[100vh] max-h-[100vh]">
                            <Sidebar />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default TagsIndex
