import { useEffect, useState } from "react"
import useFetch from "../../../hooks/useFetch"
import Placeholder from '../../../components/placeholders/placeholder1/Placeholder'
import { formatReadTime, getDatetimeDifference, summarizeText } from "../../../helpers/helpers"
import CopyUrlToClipboard from '../../../components/content/CopyUrlToClipboard'
const SubscribersLatestList = ({
    total,
}) => {

    const {response, error, isLoading, fetch} = useFetch()
    const [subscribers, setSubscribers] = useState(null)

    useEffect(() => {

        // if ( error )
        // {
        //     console.log(error)
        // }

        if ( response )
        {
            setSubscribers(response.data)
        }

    }, [response, error])

    useEffect(() => {

        fetch(`/dashboard/users/latest/${total}`)

    }, [])
    
    return (
        <div>

            {
                isLoading && Array.from([1,2,3,4]).map((v,k) => {
                    return (
                        <div className="mb-4" key={k}>

                            <div className="mb-3">
                                <Placeholder className={'!block h-3 !rounded-sm'} />
                            </div>

                            <div className="flex items-center">
                                
                                <div className="">
                                    <Placeholder className={'w-10 h-1 !rounded-sm'} />
                                </div>

                            </div>

                        </div>
                    )
                })
            }

            {
                subscribers && subscribers.map((subscriber, subscriberIndex) => {
                    return (
                        <div className="mb-3" key={subscriber.id}>

                            <div className="flex items-center">
                                <h2 className="text-primary text-md font-bold mr-1">
                                    { summarizeText(subscriber.name, 50) }
                                </h2>
                                <div className="text-sm font-light text-secondary">
                                    <CopyUrlToClipboard url={subscriber.email} />
                                </div>
                            </div>

                            <div className="flex items-center text-secondary text-[13px]">

                                <div className="">
                                    { getDatetimeDifference(subscriber.created_at).auto }
                                </div>

                            </div>

                        </div>
                    )
                })
            }

        </div>
    )
}

export default SubscribersLatestList
