import React from 'react'
import Checkbox from '../../checkboxes/checkbox1/Checkbox'

const THead = ({ columns, isSelectAll = true, isCheckboxChecked, onCheckboxChange }) => {
    return (
        <thead>
            <tr>
                {
                    isSelectAll && (
                        <th className="font-light p-4 text-left border-b border-[#e0e0e0]">
                            <Checkbox checked={isCheckboxChecked} onChange={onCheckboxChange} />
                        </th>
                    )
                }
                {
                    columns && columns.map((column, columnIndex) => {
                        return (
                            <th className="font-light p-4 text-left border-b border-[#e0e0e0]" key={columnIndex}>{column.heading}</th>
                        )
                    })
                }
            </tr>
        </thead>
    )
}

export default THead
