import React, { useEffect, useRef, useState } from 'react'

const SeoTextarea = ({ 
    value = '', 
    onChange, 
    placeholder, 
    rows, 
    cols, 
    max = 50, 
    autofocus = false,
    hint = '',
    classes = '',
    getValue,
    setValue,
}) => {

    const ref = useRef()
    const [totalCharacters, setTotalCharacters] = useState(0)
    const [isTyping, setIsTyping] = useState(false)

    const normalClasses = 'text-[#000000d6] border-forth'
    const dangerClasses = 'text-fifth border-fifth'

    const charactersSuccessClasses = 'border-third text-third bg-green-50'
    const charactersDangerClasses = 'border-fifth text-fifth bg-red-50'

    useEffect(() =>
    {
        
        const countCharacters = () =>
        {
            if ( !value )
            {
                setTotalCharacters(0)
                return
            }
            
            setTotalCharacters(value.length)
        }

        countCharacters()

    }, [value])

    useEffect(() =>
    {
        const typingTimer = setTimeout(() =>
        {
            setIsTyping(false)
            if ( typeof getValue == 'function' ) getValue(value)
                
        }, 1*1000)

        return () => {
            clearTimeout(typingTimer)
        }

    }, [value])

    const handleKeyUp = (e) =>
    {
        setIsTyping(true)
        setValue(e.target.value)
    }

    return (
        <>
            <div className="flex relative">
                <div className="w-full">
                    <textarea
                        ref={ref}
                        autoFocus={autofocus}
                        value={value}
                        onChange={onChange}
                        onKeyUp={handleKeyUp}
                        className={`text-[35px] font-semibold px-3 py-3 outline-none resize-none inline-block w-full border-l placeholder:font-extralight ${ value.length > max ? dangerClasses : normalClasses } ${classes}`}
                        placeholder={placeholder}
                        rows={rows}
                        cols={cols}
                    >

                    </textarea>
                </div>
                <div className={`absolute right-1 top-[50%] translate-y-[-50%] w-[25px] h-[25px] rounded-full border text-xs flex items-center justify-center opacity-[.5] ${ value.length > max ? charactersDangerClasses : charactersSuccessClasses }`}>
                    {totalCharacters}
                </div>
            </div>
        </>
    )
}

export default SeoTextarea
