import React, { useEffect, useState } from 'react'
import usePost from '../../hooks/usePost'
import useDelete from '../../hooks/useDelete'
import CheckableBox from '../boxes/CheckableBox'
import Placeholder from '../placeholders/placeholder1/Placeholder'
import SearchBox from '../searchbox/searchbox1/SearchBox'
import Modal from './Modal'
import SaveButton from '../buttons/ButtonSuccess'
import Overlay from '../overlays/overlay1/Overlay'
import { extractPathFromUrl, formatBytes, uniqid } from '../../helpers/helpers'
import ApiPagination from '../paginations/ApiPagination'
import { Pagination as ResizedImagesPagination } from '@mui/material'
import CopyUrlToClipboard from '../content/CopyUrlToClipboard'
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog'
import ConfirmDeleteModal from './ConfirmDeleteModal'
const SelectMediaModal = ({
    classes = '',
    contentClasses = '',
    visible = false,
    onHide,
    onMedia,
}) => {

    const { response, error, isLoading, post } = usePost()
    const { response: delResponse, error: delError, isLoading: isDeleting, _delete } = useDelete()
    const [images, setImages] = useState(null)
    const [resizedImages, setResizedImages] = useState(null)
    const [image, setImage] = useState(null)
    const [pagination, setPagination] = useState({
        per_page: 10,
        current_page: 1,
        last_page: 0,
    })
    const [searchBoxValue, setSearchBoxValue] = useState('')
    const [checkableBoxes, setCheckableBoxes] = useState([])
    const [rImagesCheckableBoxes, setRImagesCheckableBoxes] = useState([])
    const [rImagesPagination, setRImagesPagination] = useState({
        per_page: 4,
        current_page: 1,
        last_page: 0,
        currentResults: [],
    })
    const { title: cdmTitle, message: cdmMessage, isOpen: cdmIdOpen, show: cdmShow, hide: cdmHide } = useConfirmDeleteDialog()
    const [deleteError, setDeleteError] = useState(null)

    useEffect(() => {

        if (error) {
            setImages(null)
        }

        if (response) {
            setImages(response.data.data)

            const rImages = extractResizedImages(response.data.data)
            setResizedImages(rImages)

            setPagination({
                ...pagination,
                last_page: response.data.last_page,
            })

            const boxes = response.data.data.map(item => {
                return {
                    dataId: item.id,
                    checked: false,
                }
            })

            setCheckableBoxes(boxes)

            const riboxes = rImages.map(item => {
                return {
                    dataId: item.id,
                    checked: false,
                }
            })

            setRImagesCheckableBoxes(riboxes)
        }

    }, [response, error])

    useEffect(() => {

        searchImages()

    }, [pagination.current_page])

    useEffect(() => {

        if (delError) {
            // console.log(delError)
        }

        if (delResponse) {
            searchImages()
            setDeleteError(null)
            cdmHide()
        }

    }, [delResponse, delError])

    const searchImages = () => {
        post('/media/search', {
            searchTerm: searchBoxValue,
            extensions: ['webp', 'png', 'jpg', 'jpeg', 'gif'],
            orderBy: 'id',
            order: 'desc',
            pagination: pagination,
        })
    }

    const onSearchBoxTypingEnd = (e) => {
        searchImages()
    }

    const handleCheckboxClick = (checkbox, image) => {

        const filtered = checkableBoxes.filter(check => {
            check.checked = false

            if (check.dataId == checkbox.dataId) {
                check.checked = !check.checked
            }

            return check
        })

        setCheckableBoxes(filtered)
        setRImagesCheckableBoxes(rImagesCheckableBoxes.filter(check => { check.checked = false; return check }))
        setImage(image)
    }

    const handleSave = () => {
        onMedia(image)
        onHide()
    }

    const handlePaginationFirst = () => {

        if (pagination.current_page == 1) return

        setPagination({
            ...pagination,
            current_page: 1
        })
    }

    const handlePaginationLast = () => {

        if (pagination.current_page == pagination.last_page) return

        setPagination({
            ...pagination,
            current_page: pagination.last_page
        })

    }

    const handlePaginationPrevious = () => {

        if (pagination.current_page == 1) return

        setPagination({
            ...pagination,
            current_page: pagination.current_page - 1
        })

    }

    const handlePaginationNext = () => {

        if (pagination.current_page == pagination.last_page) return

        setPagination({
            ...pagination,
            current_page: pagination.current_page + 1
        })

    }

    // Resized images
    const extractResizedImages = (data) => {
        var rImages = []

        response.data.data.forEach(v => {

            if (v.paths) {

                for (const key in v.urls.sizes) {

                    rImages.push({
                        media_id: v.id,
                        id: uniqid(),
                        size: key,
                        path: extractPathFromUrl(v.urls.sizes[key]),
                        url: v.urls.sizes[key],
                    })

                }
            }

        })

        const lastIndex = rImagesPagination.current_page * rImagesPagination.per_page
        const firstIndex = lastIndex - rImagesPagination.per_page
        const currentResults = rImages.slice(firstIndex, lastIndex)

        setRImagesPagination({
            ...rImagesPagination,
            last_page: Math.ceil(rImages.length / rImagesPagination.per_page),
            currentResults: currentResults,
        })

        return rImages
    }

    const handleRImagesPaginationPage = (e, page) => {

        const lastIndex = page * rImagesPagination.per_page
        const firstIndex = lastIndex - rImagesPagination.per_page
        const currentResults = resizedImages.slice(firstIndex, lastIndex)

        setRImagesPagination({
            ...rImagesPagination,
            current_page: page,
            currentResults: currentResults,
        })
    }

    const handleRImagesCheckboxClick = (checkbox, image) => {

        const filtered = rImagesCheckableBoxes.filter(check => {
            check.checked = false

            if (check.dataId == checkbox.dataId) {
                check.checked = !check.checked
            }

            return check
        })

        setRImagesCheckableBoxes(filtered)

        setCheckableBoxes(checkableBoxes.filter(check => { check.checked = false; return check }))
        setImage(image)
    }

    const handleDelete = () => {

        if (!image) return

        if (image.media_id) 
        {
            setDeleteError('You cannot delete custom media sizes without parent!')
            return
        }

        cdmShow('Delete media', "Deletion is not reversible, and the media file will be completely deleted. If you do not want to delete click cancel.")
    }

    const onConfirmDelete = () => {
        _delete(`/dashboard/media/${image.id}/delete`)
    }

    return (
        <div>

            <ConfirmDeleteModal
                title={cdmTitle}
                message={cdmMessage}
                isOpen={cdmIdOpen}
                onConfirm={onConfirmDelete}
                onCancel={cdmHide}
                isLoading={isDeleting}
                className="!z-[1500]"
            />

            <Modal classes={classes} contentClasses={contentClasses} title='Select media' visible={visible} onHide={onHide}>

                <div className="mb-4 mt-10">

                    <div className="mb-4 py-1 border-b border-[#e5e5e5]">
                        <SearchBox value={searchBoxValue} setValue={setSearchBoxValue} getValue={onSearchBoxTypingEnd} />
                    </div>

                    <div className="max-h-[calc(100vh-30vh)] overflow-x-hidden overflow-y-auto pl-3 pb-3">

                        {
                            image && (
                                <div className="py-5">

                                    <div className="">

                                        <ul className="mb-5">
                                            <li className="text-secondary py-2 border-b border-forth">
                                                <span className="font-medium text-primary">Size: </span>
                                                <span className='text-sm font-light'>
                                                    {(typeof image.size == 'string') ? image.size + 'px' : formatBytes(image.size)}
                                                </span>
                                            </li>
                                            <li className="text-secondary py-2 border-b border-forth flex flex-col">
                                                <div className="font-medium text-primary">Directory: </div>
                                                <div className='text-sm font-light'>
                                                    {image.path}
                                                </div>
                                            </li>
                                            <li className="text-secondary py-2 border-b border-forth flex flex-col">
                                                <div className="font-medium text-primary">Url: </div>
                                                <div className=''>
                                                    <CopyUrlToClipboard url={image.url} />
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="flex items-center">

                                            <div className="">
                                                <button className='text-fifth text-sm font-medium' onClick={handleDelete}>
                                                    Delete
                                                </button>
                                            </div>

                                            {
                                                deleteError && (
                                                    <div className="ml-2 text-base font-light text-fifth">
                                                        { deleteError }
                                                    </div>
                                                )
                                            }

                                        </div>

                                    </div>

                                </div>
                            )
                        }

                        <div className="mb-7">

                            <div className="my-4">
                                <ApiPagination onFirst={handlePaginationFirst} onLast={handlePaginationLast} onPrevious={handlePaginationPrevious} onNext={handlePaginationNext} />
                            </div>

                            {
                                isLoading && (
                                    <div className="grid md:grid-cols-3 gap-x-1">
                                        <CheckableBox classes="w-[150px] h-[150px] relative">
                                            <Placeholder className={'w-full h-full absolute top-0 left-0 !rounded-none'} />
                                        </CheckableBox>
                                        <CheckableBox classes="w-[150px] h-[150px] relative">
                                            <Placeholder className={'w-full h-full absolute top-0 left-0 !rounded-none'} />
                                        </CheckableBox>
                                        <CheckableBox classes="w-[150px] h-[150px] relative">
                                            <Placeholder className={'w-full h-full absolute top-0 left-0 !rounded-none'} />
                                        </CheckableBox>
                                    </div>
                                )
                            }

                            <div className="grid md:grid-cols-5 gap-y-4">
                                {
                                    images && images.map((image, imageIndex) => {

                                        return (

                                            <div key={image.id}>

                                                <CheckableBox isChecked={(checkableBoxes.length) && checkableBoxes[imageIndex].checked} dataId={(checkableBoxes.length) && checkableBoxes[imageIndex].dataId} classes="w-[150px] h-[150px]" onClick={(e) => handleCheckboxClick(checkableBoxes[imageIndex], image)}>
                                                    <div className="h-full relative">
                                                        <img src={image.url} alt={image.alt ?? ''} title={image.title} className="w-full h-full" />
                                                        <Overlay classes='!h-[30px] !bottom-0 !top-[unset] flex items-center justify-center !bg-[rgba(0,0,0,1)]'>
                                                            <p className="text-white text-sm">{formatBytes(image.size)}</p>
                                                        </Overlay>
                                                    </div>
                                                </CheckableBox>

                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {
                                (!isLoading && !images) && (
                                    <div className="text-sm text-secondary">
                                        There are no media files to show.
                                    </div>
                                )
                            }

                        </div>

                        <div className="">

                            <div className="py-3 border-y border-forth text-secondary uppercase">Resized Images</div>

                            <div className="my-4 flex justify-center">
                                <ResizedImagesPagination count={rImagesPagination.last_page} onChange={handleRImagesPaginationPage} />
                            </div>

                            {
                                isLoading && (
                                    <div className="grid md:grid-cols-3 gap-x-1">
                                        <CheckableBox classes="w-[150px] h-[150px] relative">
                                            <Placeholder className={'w-full h-full absolute top-0 left-0 !rounded-none'} />
                                        </CheckableBox>
                                        <CheckableBox classes="w-[150px] h-[150px] relative">
                                            <Placeholder className={'w-full h-full absolute top-0 left-0 !rounded-none'} />
                                        </CheckableBox>
                                        <CheckableBox classes="w-[150px] h-[150px] relative">
                                            <Placeholder className={'w-full h-full absolute top-0 left-0 !rounded-none'} />
                                        </CheckableBox>
                                    </div>
                                )
                            }

                            <div className="grid md:grid-cols-5 gap-y-4">
                                {
                                    rImagesPagination.currentResults && rImagesPagination.currentResults.map((image, imageIndex) => {

                                        return (

                                            <div key={image.id}>

                                                <CheckableBox isChecked={(rImagesCheckableBoxes.length) && rImagesCheckableBoxes[imageIndex].checked} dataId={(rImagesCheckableBoxes.length) && rImagesCheckableBoxes[imageIndex].dataId} classes="w-[150px] h-[150px]" onClick={(e) => handleRImagesCheckboxClick(rImagesCheckableBoxes[imageIndex], image)}>
                                                    <div className="h-full relative">
                                                        <img src={image.url} alt={image.alt ?? ''} title={image.title} className="w-full h-full" />
                                                        <Overlay classes='!h-[30px] !bottom-0 !top-[unset] flex items-center justify-center !bg-[rgba(0,0,0,1)]'>
                                                            <p className="text-white text-sm">{image.size}</p>
                                                        </Overlay>
                                                    </div>
                                                </CheckableBox>

                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {
                                (!isLoading && !images) && (
                                    <div className="text-sm text-secondary">
                                        There are no media files to show.
                                    </div>
                                )
                            }

                        </div>

                    </div>

                </div>

                <div className="flex justify-between border-t border-[#e5e5e5] pt-1">
                    <div className="">

                    </div>
                    <div className="w-4"></div>
                    <div className="">
                        <SaveButton text={'Select media'} classes='!rounded-md' onClick={handleSave} />
                    </div>
                </div>

            </Modal>

        </div>
    )
}

export default SelectMediaModal
