import { Link } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import { AuthRoutes, UserDashboardRoutes } from '../../../routes/RouteNames'
import Links from '../../../components/links/footer-links/links1/Links'
import RecommendedTags from '../../../components/tags/recommended/RecommendedTags'
import ProfileImage from '../../../components/profile/images/image1/ProfileImage'
const Sidebar = () => {

    const { Auth } = useAuth()

    return (
        <div className='flex flex-col justify-between h-full'>

            <div className="mt-[40px]">

                <div className="mb-4">
                    <Link to={AuthRoutes().USER.PROFILE}>
                        <ProfileImage url={ (Auth().user?.avatar_urls?.sizes) ? Auth().user?.avatar_urls?.sizes['88x88']: null } />
                    </Link>
                </div>

                <div className="mb-4">
                    <Link to={AuthRoutes().USER.PROFILE}>
                        <h2 className='text-primary font-semibold'>{Auth().user.name}</h2>
                    </Link>
                </div>

                <div className="my-10">

                    <h3 className="text-2xl font-normal mb-5">Available tags</h3>
                    
                    <RecommendedTags/>

                </div>

                <div className="">
                    <Link to={UserDashboardRoutes().TAGS.INDEX.nav()} className="text-third hover:text-[#191919] text-xs">
                        See all tags
                    </Link>
                </div>

                <div className="">
                    <Link to={UserDashboardRoutes().TAGS.CREATE.nav()} className="text-third hover:text-[#191919] text-xs">
                        Create new tags
                    </Link>
                </div>

            </div>

            <div className="mt-[40px]"></div>

            <div className="py-[24px]">

                <Links />

            </div>

        </div>
    )
}

export default Sidebar
